import React from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { showEditOffcanvas, showDeleteModal, showUpdateDataOffcanvas, setNewBlockType } from '../../features/modal/offcanvasSlice'
import MDEditor from '@uiw/react-md-editor'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const CN_LIST_SLDR = ({
    items = [],
    blockID,
    showEditOffcanvas,
    properties,
    seq,
    websiteDataBlocks,
    showDeleteModal, 
    showUpdateDataOffcanvas, 
    setNewBlockType
}) => {
    let navigate = useNavigate()
    let contentID = null
    const handleBlockItemUpdate = (itemID) => {
        contentID = itemID
        showEditOffcanvas({ isEditVisible:true, itemID:itemID, items:items, blockID: blockID, properties:properties,seq:seq })
    }
    const tempItems = items ? items : []

    const handleDetailInfo = (e, itemID) => {
        e.stopPropagation()
        console.log(itemID)
        showEditOffcanvas({ itemID:itemID, items:items, blockID: blockID, properties:properties, seq:seq })
        navigate('/detailPage')
    }

    const handleNewEditItem = (itemID) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID });
        // console.log("whatIsKey",itemID, blockID);
        showUpdateDataOffcanvas({
            isEditVisible: true,
            itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties
        })
    }

    const handleDeleteContent = (blockName, deleteItemID) => {
        const contentName = websiteDataBlocks[blockName].items.contents.split(
          '.'
        )[1]
        showDeleteModal({
          isDeleteVisible: true,
          deleteMessage: 'Are your sure? You want to delete this content?',
          triggerMessage: 'content',
          deleteID: contentName,
          deleteItemID: deleteItemID
        })
    }

    let cardWidth = 4;
    if (properties.countInRow === 2) {
        cardWidth = 6
    } else if (properties.countInRow === 3) {
        cardWidth = 4
    } else if (properties.countInRow === 4) {
        cardWidth = 3
    } else if (properties.countInRow === 6) {
        cardWidth = 2
    } else if (properties.countInRow === 1) {
        cardWidth = 12
    }
    return (
        <div className="pt-2 pb-2" style={{backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: (properties && properties.background && properties.background.color && properties.background.color !== undefined) ? `${properties.background.color}` : properties.backgroundValue , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            <div class={(properties && properties.container === 'true') || (properties.container === undefined || properties.container === '') ? 'container' : 'container-fluid'}>
                {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                    <div className="row">
                        {properties.blockHeading !== '' &&
                            <div className="col-12">
                                <h3 className="text-center mb-0" style={cssConverter(properties.blockHeadingCss)} dangerouslySetInnerHTML={{ __html: properties.blockHeading }} />
                            </div>
                        }
                        {properties.blockDescription !== '' &&
                            <div className="col-12">
                            <p className="text-center" style={cssConverter(properties.blockDescriptionCss)} dangerouslySetInnerHTML={{ __html: properties.blockDescription }} />
                            </div>
                        }
                    </div>
                }
                {
                    properties && properties.countInRow !== undefined &&
                    <Splide
                    options={{
                        perPage: properties.countInRow !== '' ? Number(properties.countInRow) : 3,
                        rewind: true,
                        type: "loop",
                        gap: 0,
                        arrows: true,
                        pagination: true,
                        breakpoints: {
                            640: {
                                perPage: 1
                            }
                        }
                    }}
                    >
                        {items.map((item, key) => {
                            const firstImage = (item.image && item.image.length > 0) ? item.image[0].src : ''
                            return(<SplideSlide key={key}>
                                <div className="card m-2 p-1 position-relative"  style={cssConverter(item.itemCss, {backgroundImage: item && item.itemBackground && item.itemBackground.image && `url${item.itemBackground.image}`, backgroundColor: item && item.itemBackground && item.itemBackground.color && `${item.itemBackground.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'})}>
                                    <div className="d-flex justify-content-center">
                                        <div onClick={() => {handleNewEditItem(key)}} style={{backgroundImage: 'url("' + firstImage + '")', height: '50px', width: '50px', borderRadius: '100px',backgroundPosition: 'center', backgroundSize: 'cover', cursor: 'pointer'}}>.</div>
                                    </div>
                                    <div className="card-body p-2">
                                        <span onClick={() => {handleNewEditItem(key)}} className='cursor-pointer'><MDEditor.Markdown source={item.subtitle} linkTarget="_blank" className="card-text text-center bg-transparent" style={cssConverter(item.subtitleCss)} /></span>
                                        <span onClick={() => {handleNewEditItem(key)}} className='cursor-pointer'><MDEditor.Markdown source={item.description} linkTarget="_blank" className="card-text text-center bg-transparent" style={cssConverter(item.descriptionCss)} /></span>
                                        <h5 className="card-title text-center cursor-pointer pt-2" onClick={() => {handleNewEditItem(key)}}  style={cssConverter(item.titleCss)}>{item.title}</h5>
                                    </div>
                                    <div className='d-flex pos-absglr'>
                                        {/* <div className="col-4 col-md-4 text-end">
                                        <span className="badge bg-secondary p-1" style={{width: '55px', cursor: 'pointer'}} onClick={() => {handleBlockItemUpdate(key)}}>Edit</span>
                                        </div> */}
                                        <div className="col-6 col-md-6 text-end">
                                            <span className="badge bg-success p-1 editBtnCss" onClick={() => { handleNewEditItem(key) }}>Edit</span>
                                        </div>
                                        <div className="col-6 col-md-6">
                                        <span className="badge bg-danger p-1 cursor-pointer w-55px" onClick={() => {handleDeleteContent(blockID, key)}}>Delete</span>
                                        </div>
                                    </div>
                                    {/* <div className="position-absolute end-0" style={{top: '5px'}}>
                                        <span className="badge bg-secondary" style={{cursor: 'pointer'}} onClick={() => {handleBlockItemUpdate(key)}}>Edit</span>
                                        <span className="badge bg-danger position-absolute" style={{cursor: 'pointer'}} onClick={() => {handleDeleteContent(blockID, key)}}>Delete</span>
                                    </div> */}
                                </div>

                                {/* <div className="card bg-dark text-white">
                                <img
                                    src={firstImage}
                                    className="card-img overlay-img rounded-0"
                                    alt="Slider Img"
                                />
                                <div className="card-img-overlay d-flex align-items-center rounded-0">
                                    <div>
                                    <h3 className="overflow-auto text-light">{item.title}</h3>
                                    <div>
                                        {item.button.map(value => {
                                        return <button className="btn btn-primary btn-sm me-1" disabled>{value.title}</button>
                                        })}
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="card-body pt-2 position-absolute top-0 end-0">
                                    <div className="row">
                                    <div className="col-6 col-md-6 text-end">
                                        <span className="badge bg-secondary p-1 editBtnCss" onClick={() => {handleBlockItemUpdate(key)}}>Edit</span>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <span className="badge bg-danger p-1 editBtnCss">Delete</span>
                                    </div>
                                    </div>
                                </div> */}
                            </SplideSlide>)
                        })}
                    </Splide>
                }
            </div>
            {/* <div>
                <div className="section full">

                    <div className="row p-2 pt-3 pb-3">
                        {tempItems && tempItems.map((item, key) => {
                            const firstImage = (item && item.image && item.image.length > 0) ? item.image[0].src : '' 
                            return(<div className="col-md-3 col-6">
                                <div className="card" onClick={() => {handleBlockItemUpdate(key)}} style={{zIndex: '-5'}}>
                                    <img src={firstImage} className="card-img-top" alt="image" onClick={(e) => {handleDetailInfo(e, key)}} />
                                    <div className="card-body">
                                        <h5 className="card-title">{item.title}</h5>
                                        <p className="card-text">{item.subtitle}</p>
                                        <p className="card-text"><small>{item.description}</small></p>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>

                </div>
            </div> */}
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
      isVisible
    } = offcanvas
    const { 
        blocks: websiteDataBlocks 
    } = websiteData
    return {
      offcanvasVisible: isVisible,
      websiteDataBlocks
    }
}
  
const mapDispatchToProps = {
    showEditOffcanvas,
    showDeleteModal, 
    showUpdateDataOffcanvas, 
    setNewBlockType
}

export default connect(mapStateToProps, mapDispatchToProps)(CN_LIST_SLDR)
