import React from 'react'

function SM_RGTR_DFL1(props) {
    return (
        <div>
            Registartion Form
        </div>
    )
}

export default SM_RGTR_DFL1
