import React from 'react';

const RadioButtonsSelect = ({ uniqueID, title, buttonoptionsList, defaultValue, onChangeValue }) => {

    return (
        <div class="input-wrapper">
            {title !== undefined && title !== false &&
                <label class="form-label mb-1">{title}</label>
            }
            {
                buttonoptionsList !== undefined && buttonoptionsList !== null && buttonoptionsList.length !== 0 &&
                <div class="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
                    {buttonoptionsList.map((item, i) => {
                        return <>
                            <input key={i} type="radio" class="btn-check" name={`${uniqueID}${title.replace(' ','')}`} value={item.value} id={`${item.value}${i}${uniqueID}`} checked={item.value === defaultValue} onChange={(e) => onChangeValue(e.target.value)} />
                            <label class="btn btn-outline-primary btn-sm" htmlFor={`${item.value}${i}${uniqueID}`}>{item.label}</label>
                        </>
                    })}
                </div>
            }
        </div>
    )
}

export default RadioButtonsSelect