import React, { useState, useEffect } from 'react';
import WebwebColorPicker from './WebwebColorPicker';
import { connect } from "react-redux";
import { getImageType, setWhereToUpdateImage, showImageModal, newImageData } from '../../../features/modal/offcanvasSlice';

const MediaUpdate = ({
    title,
    id,
    NewBlockType,
    propsObject,
    optionsList,
    isEditVisible,
    onChangeProps,
    WhereToUpdateImage,
    setWhereToUpdateImage,
    showImageModal,
    newImageData,
    imageData,
    isImageModalVisible
}) => {
    const [propsState, setPropsState] = useState(null);
    const [Color, setColor] = useState('#ffffff');
    const [Image, setImage] = useState('');

    const getpropsState = () => {
        if (typeof (propsState) === 'string') {
            return JSON.parse(propsState);
        } else {
            return JSON.parse(JSON.stringify(propsState))
        }
    };

    useEffect(() => {
        if (isEditVisible && propsObject !== undefined && propsObject !== false && propsObject !== null) {
            const local = JSON.parse(JSON.stringify(propsObject));
            // console.log({local});
            setPropsState(local);
        } else {
            setPropsState(null)
        }
    }, [isEditVisible, propsObject])


    useEffect(() => {
        // console.log({ title, propsState });
        if (propsState !== null) {
            const propsStateLocal = typeof (propsState) === 'string' ? JSON.parse(propsState) : JSON.parse(JSON.stringify(propsState));
            if (propsStateLocal['image'] !== undefined) setImage(propsStateLocal['image']);
            if (propsStateLocal['color'] !== undefined) setColor(propsStateLocal['color']);

            onChangeProps(propsStateLocal);
        }
    }, [propsState]);

    function handleShowImageModal(imgItem, blockType, ItemOrBg) {
        // console.log(JSON.stringify(imageData), ID);
        const imageType = getImageType(blockType);
        setWhereToUpdateImage({
            blockType: NewBlockType.BlockType,
            ItemOrBg: ItemOrBg,
            ItemPosition: 0
        })
        showImageModal({
            isImageModalVisible: true,
            imageData: imgItem,
            imageID: 0,
            imageType: imageType
        });
        newImageData({ imageDataObj: imgItem });
    }
    // console.log(NewBlockType);
    const updateValue = (key, value, key2, value2) => {
        const local = getpropsState();
        local[key] = value;
        if (key2 !== undefined) local[key2] = value2;
        setPropsState(local);
    }

    const deleteValue = (key, key2, key3) => {
        const local = getpropsState();
        delete local[key];
        if (key2 !== undefined) delete local[key2];
        if (key3 !== undefined) delete local[key3];
        setPropsState(local);
    }

    const changedBGColor = (colorCode) => { updateValue('color', colorCode, 'image', Image) };

    const ChangeBGImage = (ImageLink) => { updateValue('color', Color, 'image', ImageLink) }

    useEffect(() => {
        if (NewBlockType !== null && NewBlockType.BlockType !== undefined && NewBlockType.BlockType !== null && (NewBlockType.BlockType === 'CN' || NewBlockType.BlockType === 'SM')) {
            if (!isImageModalVisible && imageData !== undefined && imageData !== null && imageData.length !== 0 && WhereToUpdateImage !== null && WhereToUpdateImage !== undefined) {

                if (WhereToUpdateImage.ItemOrBg === title) {
                    if(imageData[0].videoUrl && imageData[0].videoUrl.length > 0) {
                        ChangeBGImage(imageData[0].videoUrl);
                    } else {
                        ChangeBGImage(imageData[0].src);
                    }
                    setWhereToUpdateImage(null)
                }
                newImageData({ imageDataObj: [] });
            }
        }
    }, [imageData, isImageModalVisible])

    return (
        <>
            <h3>{title}</h3>
            {(optionsList !== undefined && typeof (optionsList) === 'object' && optionsList.length !== 0) ?
                <>
                    {
                        optionsList.includes('image') &&
                        <div class="row">
                            <div class="col-md-12 form-group boxed ps-1">
                                <div class="input-wrapper">
                                    <label class="form-label" htmlFor="imageVideo">Image / Video</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-12">
                                <div class="w-100">
                                    {Image.length === 0 ?
                                        <>
                                            <div class="custom-file-upload" style={{ height: 'auto', aspectRatio: '16 / 9' }} onClick={() =>
                                                handleShowImageModal(
                                                    [],
                                                    NewBlockType.BlockName,
                                                    title
                                                )
                                            }>
                                                <label>
                                                    <span>
                                                        <strong className='d-flex align-items-center justify-content-center'>
                                                            <ion-icon name="cloud-upload-outline" class="fs-4"></ion-icon>
                                                            <i className='ms-1'>Tap to Upload</i>
                                                        </strong>
                                                    </span>
                                                </label>
                                            </div>
                                        </>
                                        :
                                        <div className='row'>
                                            <div class="col-6 col-md-6">
                                                <img
                                                    src={Image}
                                                    alt="Avatar"
                                                    class="imaged w-100 border border-primary border-2"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        handleShowImageModal(
                                                            [{ "src": Image, "alt": "", "style": "" }],
                                                            NewBlockType.BlockName,
                                                            title
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div class="col-3 col-md-3">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <button type="button" class="btn btn-text-secondary me-1 mb-1" style={{ display: 'flex', flexFlow: 'wrap' }} onClick={() =>
                                                        handleShowImageModal(
                                                            [],
                                                            NewBlockType.BlockName,
                                                            title
                                                        )
                                                    }><ion-icon name="sync-outline" class="me-0"></ion-icon><span class="fs-10">Replace</span></button>
                                                </div>
                                            </div>
                                            <div class="col-3 col-md-3">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <button type="button" class="btn btn-text-danger me-1 mb-1" style={{ display: 'flex', flexFlow: 'wrap' }} onClick={() => ChangeBGImage('')}><ion-icon name="trash-outline" class="me-0"></ion-icon><span class="fs-10">Delete</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        optionsList.includes('color') &&
                        <div className='row'>
                            <div class="col-12 col-md-12 form-group basic ps-1">
                                <div class="input-wrapper">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label class="form-label" htmlFor="colorPicker">Color</label>
                                        <div className='badge bg-warning cursor-pointer' onClick={(e) => { deleteValue('color'); setColor('#111111') }}>
                                            <ion-icon name="refresh-outline" className="md trash" aria-label="Delete"></ion-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-12">
                                <WebwebColorPicker
                                    color={Color}
                                    onChangedColor={changedBGColor}
                                    size='xl' />
                            </div>
                        </div>
                    }
                </>
                :
                null
            }
        </>
    );
};

const mapStateToProps = ({ websiteData, offcanvas }) => {
    const {
        blocks: websiteDataBlocks,
        blockRules,
    } = websiteData
    const {
        items,
        itemID,
        properties,
        blockID,
        blockType,
        ContentBlockItem,
        MainBlockProps,
        NewBlockType,
        imageData,
        WhereToUpdateImage,
        isImageModalVisible,
        isEditVisible,
    } = offcanvas
    return {
        websiteDataBlocks,
        items,
        itemID,
        properties,
        blockID,
        blockType,
        blockRules,
        MainBlockProps,
        NewBlockType,
        imageData,
        WhereToUpdateImage,
        isImageModalVisible,
        isEditVisible,
    }
}

const mapDispatchToProps = {
    setWhereToUpdateImage,
    showImageModal,
    newImageData
}


export default connect(mapStateToProps, mapDispatchToProps)(MediaUpdate);