import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'
import { showOffcanvas, showButtonModal, newButtonData, showDeleteModal } from '../../features/modal/offcanvasSlice'

const SM_QLNK_DFL1 = ({
    properties,
    items,
    blockType,
    blockID,
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal
}) => {
    console.log(items)
    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi
    const regexExp1 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi
    let isnum = /^\d+$/
    return (
        <div className="section full" style={{marginTop: '12px'}}>
            <div className="appHeader position-relative" style={{zIndex:0}}>
            {/* height: '100px' */}
                <div className={'left'}>
                    {items[0]['button'].map((value, key) => {
                        return <>
                            {key < 3 &&
                                <>
                                    {value.url.includes('facebook') && 
                                        <ion-icon name="logo-facebook" className="fs-5 me-1 text-info"></ion-icon>
                                    }
                                    {value.url.includes('twitter') && 
                                        <ion-icon name="logo-twitter" className="fs-5 me-1 text-info"></ion-icon>
                                    }
                                    {value.url.includes('linkedin') && 
                                        <ion-icon name="logo-linkedin" className="fs-5 me-1 text-info"></ion-icon>
                                    }
                                    {value.url.includes('youtube') && 
                                        <ion-icon name="logo-youtube" className="fs-5 me-1 text-danger"></ion-icon>
                                    }
                                    {value.url.includes('instagram') && 
                                        <ion-icon name="logo-instagram" className="fs-5 me-1 text-warning"></ion-icon>
                                    }
                                    {value.url.includes('wa.me') && 
                                        <ion-icon name="logo-whatsapp" className="fs-5 me-1 text-success"></ion-icon>
                                    }
                                    {value.type === 'call' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="call-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'page' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="document-text-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'image' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="image-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'external' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="open-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'document' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="document-text-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'email' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="mail-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'payment' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="card-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                </>
                            }
                        </>
                        
                    })}
                </div>
                <div className={'right'}>
                    {items[0]['button'].map((value, key) => {
                        return <>
                            {(key > 2 && key < 6) &&
                                <>
                                    {value.type === 'socialMedia' &&
                                        <>
                                            {value.url.includes('facebook') && 
                                                <ion-icon name="logo-facebook" className="fs-5 me-1 text-info"></ion-icon>
                                            }
                                            {value.url.includes('twitter') && 
                                                <ion-icon name="logo-twitter" className="fs-5 me-1 text-info"></ion-icon>
                                            }
                                            {value.url.includes('linkedin') && 
                                                <ion-icon name="logo-linkedin" className="fs-5 me-1 text-info"></ion-icon>
                                            }
                                            {value.url.includes('youtube') && 
                                                <ion-icon name="logo-youtube" className="fs-5 me-1 text-danger"></ion-icon>
                                            }
                                            {value.url.includes('instagram') && 
                                                <ion-icon name="logo-instagram" className="fs-5 me-1 text-warning"></ion-icon>
                                            }
                                            {value.url.includes('wa.me') && 
                                                <ion-icon name="logo-whatsapp" className="fs-5 me-1 text-success"></ion-icon>
                                            }
                                        </>
                                    }
                                    {value.type === 'call' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="call-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'page' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="document-text-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'image' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="image-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'external' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="open-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'document' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="document-text-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'email' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="mail-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                    {value.type === 'payment' &&
                                        <span className="me-1 text-muted d-flex">
                                            <ion-icon name="card-outline" className="fs-5"></ion-icon>
                                            {value.title}
                                        </span>
                                    }
                                </>
                            }
                        </>
                        
                    })}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}
  
const mapDispatchToProps = {
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal
}

export default connect(mapStateToProps, mapDispatchToProps)(SM_QLNK_DFL1)
