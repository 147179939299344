import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hidePropertyOffcanvas } from '../../../features/modal/offcanvasSlice'

const Templates = ({
    show,
    hidePropertyOffcanvas
}) => {
    const collectData = () => {
        console.log('ENTERED TEMPLATE OFFCANVAS')
    }

    return (
        <Offcanvas show={show} placement={'end'} backdrop={true} scroll={true} onEntered={collectData} id="appCapsule">
            <Offcanvas.Body className="offcanvas-body ps-0 pe-0">
                <div className="profileBox webweb-profileBox">
                    <div className="in">
                        <strong>Properties</strong>
                    </div>
                    <span className="close-sidebar-button" style={{cursor:'pointer'}} onClick={hidePropertyOffcanvas}>
                        <ion-icon name="close"></ion-icon>
                    </span>
                </div>
                <div className="section full mb-2">
                    <div className="listview-title mb-0">
                        <span>Change Background Image</span>
                    </div>
                    <div className="row p-1">
                        <div className="col-4 col-md-4 mb-2">
                            <div className="d-flex justify-content-center align-items-center">
                                <ion-icon name="create-outline" className="position-absolute"></ion-icon>
                                <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w-100 border" />
                            </div>
                        </div>
                        <div className="col-4 col-md-4 mb-2">
                            <div className="d-flex justify-content-center align-items-center">
                                <ion-icon name="create-outline" className="position-absolute"></ion-icon>
                                <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w-100 border border-primary border-2" />
                            </div>
                        </div>
                        <div className="col-4 col-md-4 mb-2">
                            <div className="d-flex justify-content-center align-items-center">
                                <ion-icon name="create-outline" className="position-absolute"></ion-icon>
                                <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w-100 border" />
                            </div>
                        </div>
                        <div className="col-4 col-md-4 mb-2">
                            <div className="d-flex justify-content-center align-items-center">
                                <ion-icon name="create-outline" className="position-absolute"></ion-icon>
                                <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w-100 border" />
                            </div>
                        </div>
                        <div className="col-4 col-md-4 mb-2">
                            <div className="d-flex justify-content-center align-items-center">
                                <ion-icon name="create-outline" className="position-absolute"></ion-icon>
                                <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w-100 border" />
                            </div>
                        </div>
                        <div className="col-4 col-md-4 mb-2">
                            <div className="d-flex justify-content-center align-items-center">
                                <ion-icon name="create-outline" className="position-absolute"></ion-icon>
                                <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w-100 border" />
                            </div>
                        </div>
                        <div className="col-4 col-md-4 mb-2">
                            <div className="d-flex justify-content-center align-items-center">
                                <ion-icon name="create-outline" className="position-absolute"></ion-icon>
                                <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w-100 border" />
                            </div>
                        </div>
                        <div className="col-4 col-md-4 mb-2 passive">
                            <div className="d-flex justify-content-center align-items-center">
                                <ion-icon name="create-outline" className="position-absolute"></ion-icon>
                                <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w-100 border" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar-buttons webweb-sidebar-buttons">
                    <a href="#!" className="btn btn-block btn-success" style={{borderRadius: "initial"}}>
                        <ion-icon name="cloud-upload-outline"></ion-icon> Update
                    </a>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = () => {
    return {}
  }
  
  const mapDispatchToProps = {
    hidePropertyOffcanvas
  }

export default connect(mapStateToProps, mapDispatchToProps)(Templates)
