import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from '../../../AxiosConfig'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { setWebsiteData, setLoading } from '../../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
// import { useSelector, useDispatch } from 'react-redux';
import ReactJson from 'react-json-view';
import { newImageData, setShowJSONModal, hideOffcanvas } from '../../../features/modal/offcanvasSlice';

const JSONModal = ({ CompleteJSON,
    JSONModal,
    setShowJSONModal,
    setWebsiteData,
    showAppToast,
    hideOffcanvas,
    newImageData,
    setLoading
}) => {
    // const { CompleteJSON } = useSelector((state) => state.websiteData);
    // const { showJSONModal } = useSelector((state) => state.offcanvas);
    const token = localStorage.getItem('token')
    const tokenData = token ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain
    const [jsonvalue, setJSONValue] = useState(null);

    useEffect(() => {
        setJSONValue(CompleteJSON);
        // setJSONValue(JSON.stringify(CompleteJSON));
        // console.log({CompleteJSON});
    }, [CompleteJSON, JSONModal])
    const hideJSONModal = () => {
        setShowJSONModal({ JSONModal: false });
    }

    const updateWebsiteJSON = () => {
        if (tokenData.userInfo.RoleID != 'Moderator' || tokenData.userInfo.RoleID == 'Moderator') {
            setLoading(true)
          axios.put('/website/updateWebsite/' + selectedDomain, {'JSON' : jsonvalue} )
            .then(res => {
                setLoading(false);
              if(res.data.message === 'Success') {
                setWebsiteData(res.data.data)
                showAppToast({
                  toastMessage: 'JSON UPDATED ',
                  background: 'success',
                  timeout: '1000',
                  icon: 'checkmark-circle-outline',
                  position: 'toast-center'
                })
                newImageData({ imageDataObj: [] })
                hideOffcanvas()
              } else {
                showAppToast({
                  toastMessage: 'FAIL',
                  background: 'danger',
                  timeout: '1000',
                  icon: 'close-circle-outline',
                  position: 'toast-center'
                })
                newImageData({ imageDataObj: [] })
                hideOffcanvas()
              }
            }).catch((error) => {
                setLoading(false);
                showAppToast({
                    toastMessage: 'FAIL ',
                    background: 'danger',
                    timeout: '1000',
                    icon: 'close-circle-outline',
                    position: 'toast-center'
                })
            })
        //   setWebsiteData(JSON.parse(jsonvalue));

          newImageData({ imageDataObj: [] })
          hideOffcanvas()
          console.log("jsonvalue", jsonvalue);
        }
        hideJSONModal();
      }

    const updateJSON = () => {
        console.log(jsonvalue);
        setWebsiteData(jsonvalue);
    }


    const oneditJson = (json) => {
        console.log(json);
        setJSONValue(json.updated_src)
    }

    const [disableBtn, setDisableBtn] = useState(false);

    const [collapseJson, setCollapseJson] = useState(true);

    const collapseAll = () => {
        setDisableBtn(true);
        setCollapseJson(!collapseJson);
        setTimeout(() => {
            setDisableBtn(false);
        }, 15000);
    }
    return (
        <>
            <Modal class="modal fade modalbox" id="addpageModal" data-bs-backdrop="false" tabindex="-1" role="dialog" show={JSONModal} fullscreen={true}>
                <div class="modal-content">
                    <Modal.Header class="modal-header">
                        <h5 class="modal-title">JSON</h5>
                        <div>
                            <button className='btn btn-secondary me-2' disabled={disableBtn} onClick={() => collapseAll()}>
                                Collapse
                                { disableBtn &&
                                    <ion-icon name="hourglass-outline" class="ms-1"></ion-icon>
                                }
                            </button>
                            <button className='btn btn-primary me-2' onClick={() => updateWebsiteJSON()}>
                                Update JSON
                            </button>
                            <button class="text-primary btn-link bg-transparent border-0" style={{ cursor: 'pointer', textDecoration: 'none' }} onClick={() => hideJSONModal()}>
                                Close
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body class="modalBbody p-0">
                        <div class="pe-2 ps-2">
                            <div class="row">
                                <div className='col-md-12' style={{'width': '90vw','max-height':'90vh','overflow': 'scroll'}}>
                                    <ReactJson src={jsonvalue} theme="rjv-default" collapsed={collapseJson} indentWidth="8" onEdit={(add) => oneditJson(add)} onAdd={(add) => oneditJson(add)} onDelete={(add) => oneditJson(add)} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
                {/* </div> */}
            </Modal>
        </>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        JSONModal
    } = offcanvas
    const {
        CompleteJSON
    } = websiteData
    return {
        JSONModal,
        CompleteJSON
    }
}
  
const mapDispatchToProps = {
    setShowJSONModal,
    setWebsiteData,
    showAppToast,
    newImageData,
    hideOffcanvas,
    setLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(JSONModal)