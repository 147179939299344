import React from 'react'

function Story(props) {
    return (
        <div class="modal fade stories" id="ImagePreview" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="story-image">
                        <img src="assets/img/sample/photo/vertical1.jpg" alt="Story" />
                    </div>
                    <div class="modal-header">
                        <a href="#!" class="profile-detail">
                            <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar"
                                class="imaged w36 rounded me-1" />
                            <div>
                                <h4>Alberto Raya</h4>
                                <span class="text-muted">5 minutes ago</span>
                            </div>
                        </a>
                        <span data-bs-dismiss="modal" class="close-stories">
                            <ion-icon name="close"></ion-icon>
                        </span>
                    </div>
                    <div class="modal-body"></div>
                </div>
            </div>
        </div>
    )
}

export default Story
