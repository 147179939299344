import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { showOffcanvas, newImageData, hideImageModal } from '../../../features/modal/offcanvasSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import { updateImageData } from '../../../features/websiteData/websiteDataSlice'
import Cropper from 'react-cropper'
import "cropperjs/dist/cropper.css"
import { createApi } from 'unsplash-js'
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'

const unsplash = new createApi({
    accessKey: "12f9c8cc54415233d89891f87ec25257937412c040172c35f3a0da404b69d074",
});

const SearchPhotos = ({
    activeImageData,
    id,
    newImageData,
    imageDataObj,
    showAppToast,
    imageType,
    contentBlockID,
    hideImageModal
}) => {
    // console.log(contentBlockID)
    const [query, setQuery] = useState("")
    const [loadMore, setLoadMore] = useState(false)
    const [pics, setPics] = useState([])
    const [selectImage, selectedImage] = useState([])
    const [showSearchResults, toggleImageResults] = useState(true);
    const [cropper, setCropper] = useState()
    const [preloader, setPreloader] = useState(false)
    const [cropperConfigList, setCropperConfigList] = useState(null)
    const [cropperConfig, setCropperConfig] = useState(null)
    const [imageExtention, setImageExtention] = useState('image/png')

    const searchPhotos = async (e) => {
        e.preventDefault()
        setLoadMore(!loadMore)
        unsplash.search.getPhotos({
            query: query,
            page: 1,
            perPage: 12
        }).then(result => {
            setPics(result.response.results)
        });
    };

    const handleImageSelect = (url) => {
        selectedImage(url)
        toggleImageResults(!showSearchResults)
    }

    useEffect(() => {
        if (localStorage.getItem('cropperConfig') === undefined || localStorage.getItem('cropperConfig') === null) {
            axios.get('/info/style/cropperConfig').then(res=>{
                if (res.data.message === 'Success') {
                    setCropperConfigList(res.data.data)
                    localStorage.setItem('cropperConfig', JSON.stringify(res.data.data))
                } else {
                    console.log('SOMETHING WENT WRONG')
                }
            })
            console.log(cropperConfigList)
        } else {
            setCropperConfigList(JSON.parse(localStorage.getItem('cropperConfig')))
            // console.log(cropperConfigList)
        }
        if (imageType === 'logo') {
            setImageExtention('image/png')
        } else {
            setImageExtention('image/jpeg')
        }
    }, [imageType])
    
    useEffect(() => {
        if (imageType !== null && cropperConfigList !== null) {
            setCropperConfig(cropperConfigList[imageType])            
        }
    }, [cropperConfigList])

    const handleBack = () => {
        toggleImageResults(!showSearchResults)
    }

    if (imageDataObj !== '' && imageDataObj !== undefined) {
        activeImageData = imageDataObj
    } else {
        activeImageData = activeImageData
    }
    

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    let selectedDomain = ""

    if(tokenData.userInfo.RoleID != 'Moderator') {
        selectedDomain = tokenData.SelectedDomain.Domain
    } else {
        selectedDomain = tokenData.SelectedDomain.CategoryName
        // selectedCategoryID = tokenData.SelectedDomain.ID
    }

    const handleCroppedImage = async () => {
        if (typeof cropper !== "undefined") {
            cropper.getCroppedCanvas({
                maxHeight: cropperConfig.crop.height,
                maxWidth: cropperConfig.crop.width,
                fillColor: 'transparent'
            }).toBlob((blob) => {
                const formData = new FormData()
                formData.append('inputFile', blob)
                
                setPreloader(true)
                let cropperKey = ''
                if (contentBlockID.includes('CRSL')) {
                    cropperKey = 'slider'
                } else if (contentBlockID.includes('BRDC')) {
                    cropperKey = 'pageImage'
                } else if (contentBlockID.includes('GLRY')) {
                    cropperKey = 'gallery'
                } else if (contentBlockID.includes('HEAD') || contentBlockID.includes('FOOT')) {
                    cropperKey = 'logo'
                } else {
                    cropperKey = 'other'
                }

                axios.post('/website/upload/' + selectedDomain + '/' + cropperKey, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(res=>{
                    if (res.data.message === 'Success') {
                        if (id === -1) {
                            
                            obj = {'src' : res.data.data, 'alt' : 'Avatar'}
                            var imageDataArr = []
                            imageDataArr.push(obj)
                            newImageData({imageDataObj: imageDataArr})
                            showAppToast({ toastMessage: 'IMAGE UPLOADED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                            setPreloader(false)
                            toggleImageResults(!showSearchResults)
                            hideImageModal()
                        } else {
                            // var obj = Object.freeze(activeImageData[id])
                            // console.log(obj)
                            var obj = {'src' : res.data.data, 'alt' : 'Avatar'}
                            var imageDataArr = []
                            activeImageData.map((value, key) => {
                                if (key === id) {
                                    imageDataArr[id] = obj
                                } else {
                                    imageDataArr[key] = value
                                }
                            })
                            if (typeof imageDataArr[id] === 'undefined') {
                                imageDataArr.push(obj)
                            }
                            newImageData({imageDataObj: imageDataArr})
    
                            showAppToast({ toastMessage: 'IMAGE UPLOADED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                            setPreloader(false)
                            toggleImageResults(!showSearchResults)
                            hideImageModal()
                        }
                    } else {
                        showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                        toggleImageResults(!showSearchResults)
                        hideImageModal()
                    }
                })
              }, imageExtention, 0.70);
        } else {
            console.log('ELSE')
        }
    };

    return (
        <div>
            {showSearchResults && 
                <div>
                    <div class="section full mt-1">
                        <div class="wide-block border-0 pb-2">
                            <form class="search-form" onSubmit={searchPhotos}>
                                <div class="input-group form-group boxed p-0">
                                    <input type="text" class="form-control pe-2" placeholder="Search Image" aria-label="Recipient's username" aria-describedby="button-addon2" value={query} onChange={(e) => setQuery(e.target.value)} />
                                    <button class="btn h-custom btn-secondary ps-1 pe-0" type="submit" id="button-addon2" style={{height:'auto !important'}}>
                                        <ion-icon name="search-sharp"></ion-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="section">
                        <div class="row mb-2">
                            {pics.map(pic => (
                                <div class="col-6 col-md-6 mb-1">
                                    <div class="rounded" style={{backgroundSize: 'cover', height: '130px', width: '130px', backgroundImage: 'url(' + pic.urls.regular +')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} onClick={() => handleImageSelect(pic.urls.regular)}>
                                        .
                                    </div>
                                </div>
                            ))}
                            {loadMore &&
                                <div class="col-12 col-md-12 text-center mb-1">
                                    <button class="btn btn-secondary">Load More</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {!showSearchResults && 
                <div class="section mb-2">
                    <div class="text-start mb-1 mt-1">
                        <div class="row">
                            <div class="col-6 col-md-6">
                                <button class="btn btn-text-secondary btn-sm" onClick={handleBack}> <ion-icon name="arrow-back-outline"></ion-icon> Back</button>
                            </div>
                            <div class="col-6 col-md-6 text-end">
                                {!preloader &&
                                    <button class="btn btn-success btn-sm" onClick={handleCroppedImage}><ion-icon name="checkmark-outline" class="me-0"></ion-icon></button>
                                }
                                {preloader &&
                                    <button class="btn btn-success btn-sm" type="button" disabled>
                                        <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                        Uploading...
                                    </button>
                                }
                            </div>
                        </div>                        
                    </div>
                    <div class="d-flex justify-content-center flex-column align-items-center overflow-hidden">
                        {/* <Cropper
                            src={selectImage}
                            initialAspectRatio={crop}
                            guides={false}
                            style={{ width:'350px' ,marginBottom:'12px',display:'flex',justifyContent:'center'}}
                            responsive={true}
                            onChange={setCrop}
                            zoomable={false}
                            ref={cropperRef}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        /> */}
                        <Cropper
                            aspectRatio={cropperConfig.crop.aspectRatio}
                            style={{ height: "100%", width: "100%" }}
                            // zoomTo={0.5}
                            // initialAspectRatio={1.77}
                            zoomable={false}
                            preview=".img-preview"
                            src={selectImage}
                            viewMode={1}
                            minCropBoxHeight={50}
                            minCropBoxWidth={50}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />
                        {/* <img src={cropData} width="100px" /> */}
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        imageDataObj
    } = offcanvas
    return {
        imageDataObj
    }
  }

const mapDispatchToProps = {
    showOffcanvas,
    showAppToast,
    newImageData,
    updateImageData,
    hideImageModal
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPhotos)
