import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    editOffcanvasVisible : '',
    itemID: -1,
    items: [],
    blockID: '',
    properties:{}
}

export const updateDataSlice = createSlice({
  name: 'updateData',
  initialState,
  reducers: {
    showUpdateDataOffcanvas: (state, action) => {
      console.log(state, action)
      state.editOffcanvasVisible = true
    },
    hideUpdateDataOffcanvas: (state, action) => {
      state.editOffcanvasVisible = false
    }
  }
})

export const { showUpdateDataOffcanvas, hideUpdateDataOffcanvas } = updateDataSlice.actions;


export default updateDataSlice.reducer
