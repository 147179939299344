import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { connect } from "react-redux"
import axios from '../../AxiosConfig';
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { showSwitchModal, hideSwitchModal } from '../../features/modal/offcanvasSlice'

const Overview = ({
    websiteDataAppearance = { properties: {} },
    showSwitchModal,
    hideSwitchModal,
    // websites,
    ID
}) => {
    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    let selectedDomain = '';
    let domains = "";
    let type = "";
    let category = "";
    if (tokenData.userInfo.RoleID != 'Moderator') {
        selectedDomain = tokenData.SelectedDomain.Domain
        domains = tokenData.Domains
        type = tokenData.SelectedDomain.Type
        category = ""
    } else {
        selectedDomain = tokenData.SelectedDomain.CategoryName
        domains = ""
        type = "category"
        category = tokenData.Categories
    }

    let navigate = useNavigate()
    const handleManageSite = () => {
        console.log(websiteDataAppearance)
        navigate("/build?url=" + websiteDataAppearance.properties.defaultPage)
    }

    const switchWebsiteDomain = (switchTo, type) => {
        navigate('/load?domain=' + switchTo + '&type=' + type)
        hideSwitchModal()
    }

    const openSwitchModal = () => {
        showSwitchModal({ isSwitchModalVisible: true, switchModalMessage: 'switchWebsite', blockID: 'SM_HEAD' })
    }

    const [websites, setWebsites] = useState([])
    const [backupWebsite, setBackupWebsite] = useState([])
    const [searchIn, setSearchIn] = useState('');

    useEffect(() => {
        if (backupWebsite.length === 0) {
            axios.get(`/website/getWebsitesByUser/${ID}`).then((response) => {
                setWebsites(response.data.data);
                setBackupWebsite(response.data.data);
            }).catch((error) => {
                console.error(error)
            });
        }
    }, [])

    const [activeTab, setActiveTab] = useState('All')

    useEffect(() => {
        if (searchIn.trim().length === 0) {
            setWebsites([...backupWebsite]);
        } else {
            // console.log(backupWebsite);
            const filteredArray = backupWebsite.filter((item) => 
                item.Domain?.toLowerCase().includes(searchIn.trim().toLowerCase()) || 
                item.AliasDomain?.toLowerCase().includes(searchIn.trim().toLowerCase())
            );
            setWebsites(filteredArray);
        }
    }, [searchIn])


    const pageUrl = window.location.href
    return (
        <>
        <div id="appCapsule">
            {/* <div className="section-title justify-content-center border-bottom">{selectedDomain}</div> */}
            <div className="section mb-2 mt-2">
                <h3 className='text-primary'>Manage Websites</h3>
                <div className='d-flex mb-3 justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                        <button className={activeTab === 'All' ? 'btn btn-primary me-1' : 'btn btn-outline-primary me-1'} onClick={() => setActiveTab('All')}>All</button>
                        <button className={activeTab === 'Free' ? 'btn btn-primary me-1' : 'btn btn-outline-primary me-1'} onClick={() => setActiveTab('Free')}>Free</button>
                        <button className={activeTab === 'Paid' ? 'btn btn-primary me-1' : 'btn btn-outline-primary me-1'} onClick={() => setActiveTab('Paid')}>Paid</button>
                    </div>
                    <div>
                        <div className="form-group boxed">
                            <div className="input-wrapper">
                                <input type="text" className="form-control ps-5" id="name5" value={searchIn} placeholder="Search" onChange={(e) => setSearchIn(e.target.value)} autocomplete="off" />
                                <i className="input-icon1 position-absolute t-0 mt-1 ms-1 fs-25">
                                    <ion-icon name="search-outline"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    websites !== '' &&
                    <>
                        <div className='row'>
                            {
                                websites.map((websiteName, i) => {
                                    return (
                                        <div className={(websiteName.Plan === activeTab || 'All' === activeTab) ? "col-md-3 mb-1" : "col-md-3 mb-1 d-none"}>
                                            <div className={selectedDomain === websiteName.Domain ? "card bg-info" : "card"}>
                                                <div className="card-body d-flex flex-column justify-content-center">
                                                    <div className="card-img-top mb-2 d-flex justify-content-center">
                                                        <img src="https://webweb.ams3.cdn.digitaloceanspaces.com/webweb.svg" class="img-fluid" alt="Brand" width="65x65" />
                                                    </div>
                                                    <h5 className='text-center text-truncate' title={websiteName.Domain}>
                                                        {(websiteName.AliasDomain === '' || websiteName.AliasDomain === null) ? websiteName.Domain : websiteName.AliasDomain}
                                                    </h5>
                                                    <div className=' d-flex justify-content-center'>
                                                        <button className="btn btn-sm btn-primary rounded" disabled={selectedDomain === websiteName.Domain} onClick={() => switchWebsiteDomain(websiteName.Domain, type)}>
                                                            Manage
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* <Splide
                                options={{
                                    perPage: 6,
                                    rewind: true,
                                    type: "loop",
                                    gap: 0,
                                    arrows: true,
                                    pagination: true,
                                    breakpoints: {
                                        640: {
                                            perPage: 2,
                                        },
                                        1280: {
                                            perPage: 4,
                                        }
                                    }
                                }}
                            >
                            </Splide>
                            {
                                websites.map((websiteName, i) => {
                                    return (
                                        <>
                                            <SplideSlide key={i}>
                                                
                                            </SplideSlide>
                                        </>
                                    )
                                })
                            } */}
                    </>
                }
            </div>
        </div>
            {/* <div className="section mb-2 mt-2">
                <div className="row">
                    <div className="col-md-3 mb-1">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-subtitle">Subscription Plan1</h4>
                                <h2 className="mb-0">Premium</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-1">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-subtitle">Email Accounts</h4>
                                <h2 className="mb-0">3</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-1">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-subtitle">Hits</h4>
                                <h2 className="mb-0">1953</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-1">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-subtitle">Visitors</h4>
                                <h2 className="mb-0">109530</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-1">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-subtitle">Edit DNS</h4>
                                <h2 className="mb-0">6</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-1">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-subtitle">Edit Website</h4>
                                <h2 className="mb-0">13</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-1">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-subtitle">Form Submissions</h4>
                                <h2 className="mb-0">1953</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

const mapStateToProps = ({ utilities, websiteData, auth }) => {
    const {
        // websites
    } = utilities
    const {
        appearance: websiteDataAppearance
    } = websiteData
    const {
        ID
    } = auth
    return {
        websiteDataAppearance,
        // websites,
        ID
    }
}

const mapDispatchToProps = {
    showSwitchModal,
    hideSwitchModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
