import React from 'react'
import MDEditor from '@uiw/react-md-editor'
import { connect } from 'react-redux'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'
import { showOffcanvas, showButtonModal, newButtonData, showDeleteModal, setNewBlockType, showUpdateDataOffcanvas } from '../../features/modal/offcanvasSlice'

const SM_INFO_2CRG = ({
    properties,
    items,
    blockType,
    blockID,
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal,
    setNewBlockType,
    showUpdateDataOffcanvas
}) => {
    const handleBlockUpdate = (msg) => {
        // console.log('CLICKED')
        showOffcanvas({ isVisible: true, items: items, itemCount: 'null', properties: properties, blockType: blockType, blockID: blockID, msg: msg })
    }

    function handleShowButtonModal(buttonData, ID) {
        console.log(buttonData)
        handleBlockUpdate(ID)
        setTimeout(() => {
            showButtonModal({
                isButtonModalVisible: true,
                buttonData: buttonData,
                buttonID: ID
            })
        }, [1000])
        newButtonData({ buttonDataObj: buttonData })
    }

    const handleNewEditItem = (msg) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': 0 });
        showUpdateDataOffcanvas({
            isEditVisible: true,
            //   itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties,
            blockType: blockType,
            blockID: blockID,
            msg: msg
        })
    }

    const handleImageUpdate = () => {
        showOffcanvas({ isVisible: true, items: items, properties: properties, blockType: 'SM', blockID: blockID, msg: '' })
    }

    const handleDeleteBlock = (blockName) => {
        showDeleteModal({ isDeleteVisible: true, deleteMessage: 'Are your sure? You want to delete this block?', triggerMessage: 'block', deleteID: blockName })
    }
    return (
        <div>
            <div className="section pt-4 pb-2 position-relative" style={{backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: (properties && properties.background && properties.background.color && properties.background.color !== undefined) ? `${properties.background.color}` : (properties && properties.backgroundValue !== undefined) ? properties.backgroundValue : '' , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                    <div className="row">
                        {properties.blockHeading !== '' &&
                            <div className="col-12">
                                <h3 className="text-center" style={cssConverter(properties.blockHeadingCss)} dangerouslySetInnerHTML={{ __html: properties.blockHeading }} />
                            </div>
                        }
                        {properties.blockDescription !== '' &&
                            <div className="col-12">
                                <p className="text-center" style={cssConverter(properties.blockDescriptionCss)} dangerouslySetInnerHTML={{__html: properties.blockDescription}} />
                            </div>
                        }
                    </div>
                }
                    <div class={(properties && properties.container === 'true') || (properties.container === undefined || properties.container === '') ? 'container' : 'container-fluid'}>
                        <div className="row p-1">
                            <div className="col-9 col-md-9">
                                <h2 onClick={() => { handleNewEditItem() }} className='cursor-pointer' style={cssConverter(properties.titleCss)} dangerouslySetInnerHTML={{__html: items[0].title}} />
                                <div onClick={() => { handleNewEditItem() }} className='cursor-pointer'>
                                    {/* {items[0].subtitle} */}
                                    <MDEditor.Markdown source={items[0].subtitle} linkTarget="_blank" className='bg-transparent'  style={cssConverter(properties.subtitleCss)} />
                                </div>
                                <div onClick={() => { handleNewEditItem() }} className='cursor-pointer'>
                                    {/* {items[0].description} */}
                                    <MDEditor.Markdown source={items[0].description} linkTarget="_blank" className='bg-transparent' style={cssConverter(properties.descriptionCss)} />
                                </div>
                                <p>
                                    {items[0].button !== null && items[0].button.map((item, key) => {
                                        return <button className={`btn me-1 ${item.style && item.style !== '' ? item.style : 'btn-primary'}`} key={key} onClick={() => handleNewEditItem()} style={cssConverter(properties.buttonCss)}>{item.title}</button>
                                    })}
                                </p>
                            </div>
                            {items[0].image !== null && items[0].image.length > 0 &&
                                <div className="col-3 col-md-3 d-flex align-items-center">
                                    <img src={items[0].image[0].src} alt={items[0].image[0].alt} className="imaged w-100" onClick={handleNewEditItem}  style={cssConverter(properties.imageCss)} />
                                </div>
                            }
                        </div>
                    </div>
                {/* <div className='d-flex pos-absglr'>
                    <div className="col-6 col-md-6 text-end">
                        <span className="badge bg-success p-1 editBtnCss" onClick={() => { handleNewEditItem() }}>Edit</span>
                    </div>
                    <div className="col-6 col-md-6">
                        <span className="badge bg-danger p-1 editBtnCss" onClick={() => { handleDeleteBlock(blockID) }}>Delete</span>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal,
    setNewBlockType,
    showUpdateDataOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(SM_INFO_2CRG)
