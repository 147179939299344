import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import SampleImage from '../../components/images/avatar3.jpg'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

function CardImgSlider({ item, className }) {
    return (
        <>
            <Splide
                className={className}
                options={{
                    perPage: 1,
                    rewind: item.image.length > 1 ? true : false,
                    type: "loop",
                    gap: 0,
                    arrows: false,
                    pagination: true
                }}
            >
                {item.image.map((item, key) => {
                    return (
                        <SplideSlide key={key} className={className}>
                            <img
                                src={item.src.length > 0 ? item.src : SampleImage}
                                className={`card-img overlay-img rounded-0 ${className}`}
                                alt="Slider Img"
                                style={cssConverter(item.imageCss)}
                            />
                        </SplideSlide>
                    )
                })}
            </Splide>
        </>
    )
}

export default CardImgSlider