import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from '../../AxiosConfig'
import moment from 'moment'
import { format } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';


const FormDetail = ({
    
}) => {


    const [detailFormEnquiries, setDetailFormEnquiries] = useState([])
    const [tableKey, setTableKey] = useState([])
    const [dataStructure, setDataStructure] = useState(true)

    const search = useLocation().search
    const formType = new URLSearchParams(search).get('formType')

    const [keysToTake, setKeysToTake] = useState([]);

    useEffect(() => {
        axios.get('/analytics/getFormsSubmissions/' + formType).then(res=>{
            if (res.data.message === 'Success') {
                const detailTemp = res.data.data.map((itm) => {
                    const tempDataJSON =  JSON.parse(JSON.stringify(itm.DataJson));
                    tempDataJSON.CreatedOn = itm.CreatedOn ? format(new Date(itm.CreatedOn), 'dd-MMM-yyyy HH:mm') : '';
                    itm.DataJson = tempDataJSON;
                    return itm;
                })
                setDetailFormEnquiries(detailTemp)
                let temKey = []
                detailTemp.map((value,key) => {
                    Object.keys(value['DataJson']).map((v,k) => {
                        if(!temKey.includes(v)) {
                            temKey.push(v);
                        }
                    })
                })
                setTableKey(temKey)
            } else {
                console.log('SOMETHING WENT WRONG')
            }
        })
    }, [formType])
    
    function downloadImage(imageUrl, imageName) {
        const xhr = new XMLHttpRequest();
        
        xhr.responseType = 'blob';
        
        xhr.onload = () => {
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(xhr.response);
          downloadLink.download = imageName;
          downloadLink.click();
        };
        xhr.open('GET', imageUrl);
        xhr.send();
    }

    const handleDataStructure = (structure) => {
        setDataStructure(structure)
    }

    function createJsonFromTable(tableId) {
        // Get the table element by ID
        const table = document.getElementById(tableId);
      
        // Get all table rows
        const rows = table.querySelectorAll("tr");
      
        // Create an empty array to store the JSON data
        const jsonData = [];
      
        // Loop through each row
        for (let i = 1; i < rows.length; i++) {
          const row = rows[i];
      
          // Get all cells in the row
          const cells = row.querySelectorAll("td");
      
          // Create an object to store the cell values
          const rowData = {};
      
          // Loop through each cell and extract its contents
          for (let j = 0; j < cells.length; j++) {
            const cell = cells[j];
            const header = table.querySelectorAll("th")[j].textContent;
            rowData[header] = cell.textContent;
          }
      
          // Add the row data to the JSON array
          jsonData.push(rowData);
        }
      
        // Return the JSON data
        return jsonData;
      }
      
    
    const consoleData = () => {
        const pagedata = createJsonFromTable('TableFormData')
        
        const options = { 
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          showTitle: true,
          title: 'Form Data',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          filename: 'Form Data',
        };

    
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(pagedata);
        // console.log(pagedata);
    }

    // useEffect(() => {
    //     const newData = detailFormEnquiries.map((item) => {
    //         return item.DataJson
    //     });

    //     const NewKeys = Object.keys(newData).map((key) => key)
    //     console.log(NewKeys, newData);
    // }, [detailFormEnquiries])
    
    return (
        <div>
            <div id="appCapsule" style={{paddingBottom: '112px'}}>
                <div className="message-divider">
                    <div class="row px-5">
                        <div class="col-6 col-md-6 d-flex justify-content-start">
                            <Link to="/forms" className="d-flex justify-content-center align-items-center text-secondary"><ion-icon name="arrow-back-outline"></ion-icon>&nbsp;Back</Link>
                        </div>
                        <div class="col-6 col-md-6 d-flex flex-row justify-content-end">
                            <div class="form-check form-switch webweb-custom-switch-sm">
                                <label htmlFor="SwitchCheckDefaultContainer" style={{ paddingRight: '3px', fontSize: '11px' }}>List</label>
                                <input class="form-check-input" checked={dataStructure} type="checkbox" id="SwitchCheckDefaultContainer" onChange={(e) => {handleDataStructure(e.target.checked)}} />
                                <label class="form-check-label w-50" htmlFor="SwitchCheckDefaultContainer" style={{ paddingLeft: '48px', fontSize: '11px' }}>Table</label>
                            </div>
                        </div>
                    </div>       
                </div>
                {!dataStructure &&
                    <>
                        {detailFormEnquiries.map(value => {
                            return (<div>
                                <div className="message-item" style={{overflow: 'auto'}}>
                                    <img src="assets/img/sample/avatar/avatar2.jpg" alt="avatar" className="avatar" />
                                    <div className="content">
                                        <div className={(value.ReadStatus === 't') ? 'bubble bg-primary text-light' : 'bubble bg-secondary text-light'}>
                                            {Object.keys(value.DataJson).map(key => {
                                                return ((key === 'PaymentImage') ? <p className="mb-0 d-flex flex-column"><b style={{fontWeight: 'bolder'}}>{key}:</b> <div class="position-relative"><img src={value.DataJson[key]} width="25%" /><span href={value.DataJson[key]} onClick={() => {downloadImage(value.DataJson[key], 'payment_receipt.jpg')}} class="text-white fs-6 position-absolute" style={{background: 'rgba(0,0,0,0.6)', borderRadius: '5px', padding: '2px', cursor: 'pointer'}}><ion-icon name="download-outline"></ion-icon></span></div></p> : <p className="mb-0"><b style={{fontWeight: 'bolder'}}>{key}:</b> {value.DataJson[key]}</p> )
                                            })}
                                        </div>

                                        <div className="footer">{moment(value.CreatedOn).startOf('hour').fromNow()}</div>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </>
                }
                 
                {dataStructure &&
                    <div>
                        <div className="message-item me-0">
                            <img src="assets/img/sample/avatar/avatar2.jpg" alt="avatar" className="avatar" />
                            <div class="content pt-4" style={{'overflow': 'hidden', 'border-radius': '25px'}}>
                                <div class="mb-1 text-start">
                                    <button class="btn btn-secondary btn-sm" onClick={() => consoleData()}>
                                        Export CSV &nbsp;
                                        <ion-icon name="download-outline"></ion-icon>
                                    </button>
                                </div>
                                <div  style={{'overflowX': 'auto'}}>
                                    <div className={'bubble text-light'}>
                                        <div class="table-responsive">
                                            <table class="table table-responsive text-dark" id="TableFormData">
                                                <tbody>
                                                    <tr>
                                                        {tableKey.map((value, key) => {
                                                            return <th scope="row" class="border">{value}</th>
                                                        })}
                                                    </tr>
                                                    {detailFormEnquiries.map((value, key) => {
                                                        return <tr>
                                                        <>
                                                            {tableKey.map((v1, k1) => {
                                                                return <>
                                                                    <td class="border">
                                                                        {Object.keys(detailFormEnquiries[key]['DataJson']).map((v, k) => {
                                                                    
                                                                        return <>
                                                                        {v1 === v &&
                                                                            <>
                                                                                {(v1 !== 'PaymentImage') &&
                                                                                    <>
                                                                                        {detailFormEnquiries[key]['DataJson'][v1]}
                                                                                    </>
                                                                                }
                                                                                {(v1 === 'PaymentImage') &&
                                                                                    <>
                                                                                        <img src={detailFormEnquiries[key]['DataJson'][v1]} width="25%" /><span href={detailFormEnquiries[key]['DataJson'][v1]} onClick={() => {downloadImage(detailFormEnquiries[key]['DataJson'][v1], 'payment_receipt.jpg')}} class="text-white fs-6 position-absolute" style={{background: 'rgba(0,0,0,0.6)', borderRadius: '5px', padding: '2px', cursor: 'pointer'}}><ion-icon name="download-outline"></ion-icon></span>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                        </>
                                                                        })}
                                                                    </td>
                                                                </>
                                                            })}

                                                        </>
                                                        </tr>
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
            </div>
            {/* <div className="chatFooter position-sticky">
                <form>
                    <a href="#" className="btn btn-icon btn-secondary rounded" data-bs-toggle="offcanvas"
                        data-bs-target="#actionSheetAdd">
                        <ion-icon name="add"></ion-icon>
                    </a>
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <input type="text" className="form-control" placeholder="Type a message..." />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                    <button type="button" className="btn btn-icon btn-primary rounded">
                        <ion-icon name="send"></ion-icon>
                    </button>
                </form>
            </div> */}
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}
  
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(FormDetail)
