import React from 'react'

function YeccAppPage() {
  return (
    <div id="appCapsule">
        <div className="section d-flex align-items-center justify-content-center px-0" style={{'height': 'calc(100vh - 56px)'}}>
            <iframe src='https://beta.yecc.tech/loading?SessionToken=d74d3bd4-93a4-4046-ad96-98cff000e5b2' allowfullscreen allow="fullscreen https://beta.yecc.tech" style={{'width': '100%', 'height': '100%' }}/>
        </div>
    </div>
  )
}

export default YeccAppPage