import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import Modal from 'react-bootstrap/Modal'
import { useLocation } from "react-router-dom"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { hideAddBlockOffcanvas } from '../../../features/modal/offcanvasSlice'
import Offcanvas from 'react-bootstrap/Offcanvas'
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { setWebsiteData, setNewBlockList, setLoading } from '../../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'

const AddBlockOffcanvas = ({
    hideAddBlockOffcanvas,
    isAddBlockVisible,
    blockName,
    setWebsiteData,
    showAppToast,
    websiteDataAppearnace,
    newBlockList,
    setNewBlockList,
    setLoading,
    CompleteJSON
}) => {
    // console.log(blockName)
    const [blockList, setBlockList] = useState([{}])
    const [preloader, setPreloader] = useState(false)
    const [index, setIndex] = useState(-1)

    const handleBlockList = () => {
        // console.log(websiteDataAppearnace)
        axios.put('/block/list/' + selectedDomain + '/' + websiteDataAppearnace.properties.template).then(res=>{
            // console.log('RESPONSE', res.data.data)
            setBlockList(res.data.data)
            // console.log(blockList)
        })
    }

    useEffect(() => {
        handleBlockList()
    }, [websiteDataAppearnace])

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    // console.log(websiteDataAppearnace)

    const search = useLocation().search
    let url = ''
    url = new URLSearchParams(search).get('url')
    if (url === null) {
        url = localStorage.getItem('previousPage')
    } else {
        url = new URLSearchParams(search).get('url')
        localStorage.setItem('previousPage', url)
    }

    const fetchWebsiteJSON = () => {
        const webDoc = CompleteJSON; // localStorage.getItem('website')
        const res = webDoc; // (webDoc) ? JSON.parse(atob(webDoc)) : false
        if (res) {
            return res
        } else {
            return false
        }
    }

    const websiteJSON = fetchWebsiteJSON()

    const handleAddNewBlock = (styleID, index, style) => {
        setIndex(index)
        setLoading(true)
        setPreloader(true)
        let pageId = ''
        Object.keys(websiteDataAppearnace.pages).map((value, key) => {
            // console.log(value, key)
            if (websiteDataAppearnace.pages[value].url === url) {
                pageId = value
            }
        })
        let styleList = style;
        if(websiteDataAppearnace.properties.template === 'WEBWEBAI' && styleID.includes('CN_LIST')) {
            styleList = 'CN_LIST_CARD_DFL1'
        }
        if (tokenData.userInfo.RoleID != 'Moderator') {
            axios.post('/block/' + selectedDomain + '/' + pageId + '?Style=' + styleID + '&StyleCode=' + styleList + '&Position=' + blockName).then(res => {
                setLoading(false)
                if (res.data.message === 'Success') {
                    showAppToast({ toastMessage: 'BLOCK ADDED SUCCESSFULLY', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                    setWebsiteData(res.data.data)
                    hideAddBlockOffcanvas()
                    setPreloader(false)
                } else {
                    showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                    hideAddBlockOffcanvas()
                    setPreloader(false)
                }
            })
        } else {
            const selectedCategoryID = tokenData.SelectedDomain.ID
            const categoryPageID = localStorage.getItem('selectedPageID')
            // console.log(selectedCategoryID, pageId, styleID, blockName, categoryPageID)
            axios.post('/block/' + selectedCategoryID + '/' + pageId + '?Style=' + styleID + '&StyleCode=' + styleList + '&Position=' + blockName + '&PageID=' + categoryPageID).then(res => {
                // console.log(res)
                setLoading(false)
                if (res.data.message === 'Success') {
                    showAppToast({ toastMessage: 'BLOCK ADDED SUCCESSFULLY', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                    setWebsiteData(res.data.data)
                    hideAddBlockOffcanvas()
                    setPreloader(false)
                } else {
                    showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                    hideAddBlockOffcanvas()
                    setPreloader(false)
                }
            })
        }
    }

    useEffect(() => {
        if (newBlockList === null) {
            axios.get('/block/getBlockWithStyles')
                .then((response) => {
                    // console.log(response);
                    setNewBlockList(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                    showAppToast({ toastMessage: 'Error', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                })
        }
    }, [])


    return (
        <div>
            {/* <Offcanvas show={isAddBlockVisible} placement={'end'} backdrop={true} scroll={true} id="appCapsule">
                <Offcanvas.Body className="offcanvas-body ps-0 pe-0 p-0">
                    <div className="profileBox webweb-profileBox">
                        <div className="in">
                            <strong>Add Block</strong>
                        </div>
                        <a href="#!" className="close-sidebar-button" onClick={() => {hideAddBlockOffcanvas()}}>
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </div>
                    <div className="section full mb-2 mt-2">
                        {blockList.map((value, key) =>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 text-center" style={{cursor: 'pointer'}} key={key}>
                                <div style={{height:'145px', width: 'auto', backgroundSize: 'cover', borderRadius: '8px', backgroundColor: 'gray', backgroundImage: 'url(' + value.Thumbnail + ')', backgroundPosition: 'center'}} onClick={() => {handleAddNewBlock(value.Name, key)}} className="border">
                                </div>
                                {value.Title}
                            </div>
                            // <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-2" style={{cursor: 'pointer'}} onClick={() => {handleAddNewBlock(value.Name, key)}}>
                            //     <div style={{height:'100px', width: 'auto',backgroundColor: 'gray', backgroundSize: 'cover', borderRadius: '8px'}} className="d-flex justify-content-center align-items-center text-light flex-column border">
                            //         {value.Title}
                            //     </div>
                            // </div>
                        )}
                    </div>
                </Offcanvas.Body>
            </Offcanvas> */}
            <Modal class="modal fade modalbox" id="addblockModal" data-bs-backdrop="false" tabindex="-1" role="dialog" show={isAddBlockVisible} fullscreen={true}>
                <div class="modal-content">
                    <Modal.Header class="modal-header">
                        <h3 class="modal-title">Add Block</h3>
                        <span class="text-primary" style={{ cursor: 'pointer' }} onClick={() => { hideAddBlockOffcanvas() }}>Close</span>
                    </Modal.Header>
                    <Modal.Body class="modalBbody p-0 pb-3" style={{ overflowY: 'auto' }}>
                        {/* {blockList.map((value, key) =>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 text-center" style={{cursor: 'pointer'}} key={key}>
                                    <div style={{height:'145px', width: 'auto', backgroundSize: 'cover', borderRadius: '8px', backgroundColor: 'gray', backgroundImage: 'url(' + value.Thumbnail + ')', backgroundPosition: 'center'}} onClick={() => {handleAddNewBlock(value.Name, key)}} className="border">
                                    </div>
                                    {value.Title}
                                </div>
                            )} */}
                        <div className="row mx-0 stylesBlocks">
                            {
                                newBlockList !== null && newBlockList.map((blocks, i) => {
                                    return <div className='col-md-12 py-3' key={i}>
                                        <h4><b>{blocks.Title}</b></h4>
                                        <Splide
                                            className='arrowsTopRight'
                                            key={i}
                                            options={{
                                                perPage: 6,
                                                rewind: false,
                                                gap: 10,
                                                arrows: true,
                                                pagination: true,
                                                breakpoints: {
                                                    1100: {
                                                        perPage: 4,
                                                    },
                                                    720: {
                                                        perPage: 3,
                                                    },
                                                    450: {
                                                        perPage: 2,
                                                    }
                                              }
                                            }}>
                                            {
                                                Object.keys(blocks.Styles).length > 0 &&
                                                <>
                                                    {
                                                        Object.keys(blocks.Styles).map((style, j) => {
                                                            return <SplideSlide key={j}>
                                                                <div style={{ height: '145px', width: 'auto', backgroundSize: 'cover', borderRadius: '8px', backgroundColor: 'gray', backgroundImage: 'url(' + blocks.Styles[style].thumbnail + ')', backgroundPosition: 'center' }} className="border cursor-pointer thumbanail-card" onClick={() => {handleAddNewBlock(blocks.Name, i, style)}} />
                                                            </SplideSlide>
                                                        })
                                                    }
                                                </>
                                            }


                                        </Splide>
                                    </div>
                                })
                            }
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        isAddBlockVisible,
        blockName
    } = offcanvas
    const {
        appearance: websiteDataAppearnace,
        newBlockList,
        CompleteJSON
    } = websiteData
    return {
        isAddBlockVisible,
        blockName,
        websiteDataAppearnace,
        newBlockList,
        CompleteJSON
    }
}

const mapDispatchToProps = {
    hideAddBlockOffcanvas,
    setWebsiteData,
    showAppToast,
    setNewBlockList,
    setLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBlockOffcanvas)
