import React, { useEffect, useState } from 'react'

function WebsiteGenerater({ htmlCode = '', reference = null }) {

    const [bodyTopSection, setBodyTopSection] = useState('');
    const [bodyBottomSection, setBodyBottomSection] = useState('');

    const [blocks, setBlocks] = useState('');

    const bodyStartString = '<!-- Body Started -->';
    const bodyEndString = '<!-- Body Completed -->';

    useEffect(() => {
        if(htmlCode.trim().length > 0 && reference) {
            const htmlLength = htmlCode.length;
            const bodyStarteAt = htmlCode.indexOf(bodyStartString);
            const bodyEndeAt = htmlCode.indexOf(bodyEndString) + bodyEndString.length;
            console.log(htmlCode.length);
            // console.log(htmlCode.slice(4, bodyStarteAt));
            console.log(htmlCode.slice(bodyEndeAt, htmlLength));
        }
    }, [htmlCode]);
    
    return (
        <div>
            Acac
        </div>
    )
}

export default WebsiteGenerater