import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { setMainBlockProps, setContentBlockItem, setFlagsToCheckChanges } from '../../../features/modal/offcanvasSlice';
import MediaUpdate from './MediaUpdate';

const BackgroundTab = ({
    setMainBlockProps,
    MainBlockProps,
    setContentBlockItem,
    ContentBlockItem,
    isEditVisible,
    NewBlockType,
    FlagsToCheckChanges,
    setFlagsToCheckChanges,
    LayersActiveTab
}) => {
    const [background, setBackground] = useState(null);
    const [overlay, setOverlay] = useState(null)
    const getBlockProps = () => { return JSON.parse(JSON.stringify(MainBlockProps)) };
    const getItemProps = () => { return JSON.parse(JSON.stringify(ContentBlockItem)) };

    const changeFlagsToCheckChanges = (fieldName) => {
        if(LayersActiveTab === 'Content') {
            const tempFlagsToCheckChanges = JSON.parse(JSON.stringify(FlagsToCheckChanges));
            if (tempFlagsToCheckChanges[fieldName] !== undefined && tempFlagsToCheckChanges[fieldName] === false) {
                tempFlagsToCheckChanges[fieldName] = true;
                setFlagsToCheckChanges(tempFlagsToCheckChanges);
            }
        } else {
            const tempFlagsToCheckChanges = JSON.parse(JSON.stringify(FlagsToCheckChanges));
            if (tempFlagsToCheckChanges[fieldName] !== undefined && tempFlagsToCheckChanges[fieldName] === false) {
                tempFlagsToCheckChanges[fieldName] = true;
                setFlagsToCheckChanges(tempFlagsToCheckChanges);
            }
        }
    }

    const ChangedBackground = (props) => {
        if(LayersActiveTab === 'Content' && NewBlockType.BlockType !== 'SM') {
            const blockData = getItemProps();
            if ((blockData.itemBackground !== JSON.stringify(props)) && Object.keys(props).length !== 0) {
                blockData.itemBackground = props;
                setContentBlockItem(blockData);
                changeFlagsToCheckChanges('itemBackground');
            }
        } else {
            const blockData = getBlockProps();
            if ((blockData.background !== JSON.stringify(props)) && Object.keys(props).length !== 0) {
                blockData.background = props;
                if(props.image !== undefined && props.image.length !== 0) {
                    blockData.backgroundType = 'image';
                    blockData.backgroundValue = props.image;
                } else {
                    if(props.color !== undefined) {
                        blockData.backgroundType = 'color';
                        blockData.backgroundValue = props.color;
                    }
                }
                setMainBlockProps(blockData);
                changeFlagsToCheckChanges('background');
            }
        }
    }

    const ChangedOverlay = (props) => {
        if(LayersActiveTab === 'Content' && NewBlockType.BlockType !== 'SM') {
            const blockData = getItemProps();
            if ((blockData.itemOverlay !== JSON.stringify(props)) && Object.keys(props).length !== 0) {
                blockData.itemOverlay = props;
                setContentBlockItem(blockData);
                changeFlagsToCheckChanges('itemOverlay');
            }
        } else {
            const blockData = getBlockProps();
            if ((blockData.overlay !== JSON.stringify(props)) && Object.keys(props).length !== 0) {
                blockData.overlay = props;
                setMainBlockProps(blockData);
                changeFlagsToCheckChanges('overlay');
            }
        }
    }

    useEffect(() => {
        if(LayersActiveTab === 'Content' && NewBlockType && NewBlockType.BlockType !== 'SM') {
            if (ContentBlockItem !== null && ContentBlockItem.itemBackground !== undefined && background === null && isEditVisible === true) {
                setBackground(ContentBlockItem.itemBackground)
            } else if (ContentBlockItem !== null && ContentBlockItem.itemBackground === undefined && background === null && isEditVisible === true) {
                setBackground({});
            }
            if (ContentBlockItem !== null && ContentBlockItem.itemOverlay !== undefined && overlay === null && isEditVisible === true) {
                setOverlay(ContentBlockItem.itemOverlay)
            } else if (ContentBlockItem !== null && ContentBlockItem.itemOverlay === undefined && overlay === null && isEditVisible === true) {
                setOverlay({});
            }
        } else {
            if (MainBlockProps !== null && MainBlockProps.background !== undefined && background === null && isEditVisible === true) {
                setBackground(MainBlockProps.background)
            } else if (MainBlockProps !== null && MainBlockProps.background === undefined && background === null && isEditVisible === true) {
                setBackground({});
            }
            if (MainBlockProps !== null && MainBlockProps.overlay !== undefined && overlay === null && isEditVisible === true) {
                setOverlay(MainBlockProps.overlay)
            } else if (MainBlockProps !== null && MainBlockProps.overlay === undefined && overlay === null && isEditVisible === true) {
                setOverlay({});
            }
        }
    }, [MainBlockProps]);

    useEffect(() => {
        if (isEditVisible === false) {
            setBackground(null);
            setOverlay(null);
        }
    }, [isEditVisible])

    return (
        <div>
            <MediaUpdate
                title="Background" 
                id="1"
                isEditVisible={isEditVisible}
                propsObject={background}
                optionsList={['image', 'color']}
                onChangeProps={ChangedBackground}
            />
            <br />
            <MediaUpdate
                title="Overlay"
                id="0"
                isEditVisible={isEditVisible}
                propsObject={overlay}
                optionsList={['image', 'color']}
                onChangeProps={ChangedOverlay}
            />
        </div>
    )
}


const mapStateToProps = ({ websiteData, offcanvas }) => {

    const {
        MainBlockProps,
        ContentBlockItem,
        isEditVisible,
        NewBlockType,
        FlagsToCheckChanges
    } = offcanvas
    return {
        MainBlockProps,
        ContentBlockItem,
        isEditVisible,
        NewBlockType,
        FlagsToCheckChanges
    }
}

const mapDispatchToProps = {
    setMainBlockProps,
    setContentBlockItem,
    setFlagsToCheckChanges
}

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundTab);