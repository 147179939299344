import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'

function socialMediaCategory(props) {
  return (
    <div>
      <div class="section full">
        <div class="p-2 pb-4 align-elements-center">
          <Splide
            options={{
              perPage: 14,
              rewind: true,
              type: 'slide',
              gap: 0,
              arrows: false,
              pagination: false,
              breakpoints: {
                200: { perPage: 5 },
                411: { perPage: 5 },
                768: { perPage: 5 },
                991: { perPage: 8 }
              }
            }}
            class=""
          >
            <SplideSlide>
                <img
                  src="assets/img/sample/avatar/avatar2.jpg"
                  alt="alt"
                  class="imaged w-75 rounded d-grid m-auto"
                  style={{border: '3px solid #EDCD2F'}}
                />
                <div class="fs-10 text-center overflow-hidden text-nowrap">EID</div>
            </SplideSlide>
            <SplideSlide>
                <img
                  src="assets/img/sample/avatar/avatar3.jpg"
                  alt="alt"
                  class="imaged w-75 rounded d-grid m-auto"
                />
                <div class="fs-10 text-center overflow-hidden text-nowrap">DIWALI</div>
            </SplideSlide>
            <SplideSlide>
                <img
                  src="assets/img/sample/avatar/avatar3.jpg"
                  alt="alt"
                  class="imaged w-75 rounded d-grid m-auto"
                />
                <div class="fs-10 text-center overflow-hidden text-nowrap">HOLI</div>
            </SplideSlide>
            <SplideSlide>
              
                <img
                  src="assets/img/sample/avatar/avatar3.jpg"
                  alt="alt"
                  class="imaged w-75 rounded d-grid m-auto"
                />
                <div class="fs-10 text-center overflow-hidden text-nowrap">RADIO DAY</div>
            </SplideSlide>
            <SplideSlide>
                <img
                  src="assets/img/sample/avatar/avatar3.jpg"
                  alt="alt"
                  class="imaged w-75 rounded d-grid m-auto"
                />
                <div class="fs-10 text-center overflow-hidden text-nowrap">INDEPENDANCE DAY</div>
            </SplideSlide>
            <SplideSlide>
                <img
                  src="assets/img/sample/avatar/avatar3.jpg"
                  alt="alt"
                  class="imaged w-75 rounded d-grid m-auto"
                />
                <div class="fs-10 text-center overflow-hidden text-nowrap">WOMEN DAY</div>
            </SplideSlide>
          </Splide>
        </div>
      </div>
    </div>
  )
}

export default socialMediaCategory
