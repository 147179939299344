import React from 'react'

const SM_CUST_DFL1 = (props) => {
    return (
        <div>
            <div className="section wide-block pt-3 pb-3">
                <div className="row p-1">
                    {props.items[0].image.length > 0 &&
                        <div className="col-3 col-md-3">
                            <img src={props.items[0].image[0].src} alt={props.items[0].image[0].alt} className="imaged w-100" />
                        </div>
                    }
                    <div className="col-9 col-md-9 d-flex align-items-center">
                            <h3 dangerouslySetInnerHTML={{ __html: props.items[0].title }} />
                            <p dangerouslySetInnerHTML={{ __html: props.items[0].subtitle }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SM_CUST_DFL1
