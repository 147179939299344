import React from 'react'
import InteractExtraheader from '../layout/ExtraHeader/interactExtraHeader'

function Interact(props) {
    return (
        <div>
            <InteractExtraheader></InteractExtraheader>
            <div id="appCapsule" className="extra-header-active">
                <div className="tab-content mt-1">
                    <div className="tab-pane fade show active" id="openTicket" role="tabpanel">
                        <ul className="listview image-listview">
                            <li>
                                <a href="thread" className="item">
                                    <ion-icon name="mail-unread-outline" className="image text-primary"></ion-icon>
                                    <div className="in">
                                        <div style={{fontWeight:"bold"}}>
                                            <header>This is test entry</header>
                                            Add Content
                                            <footer>25 minutes ago</footer>
                                        </div>
                                        <span className="badge badge-primary badge-empty" style={{height:"15px !important", width: "15px !important"}}></span>
                                    </div>
                                </a>
                            </li>
                            <li className="bg-lightGray">
                                <a href="thread" className="item">
                                    <ion-icon name="mail-open-outline" className="image text-info"></ion-icon>
                                    <div className="in text-muted">
                                        <div>
                                            <footer>This is test entry</footer>
                                            Update Content
                                            <footer>1 day ago</footer>
                                        </div>
                                        <span className="badge badge-info badge-empty" style={{height:"15px !important", width: "15px !important"}}></span>
                                    </div>
                                </a>
                            </li>
                            <li className="bg-lightGray">
                                <a href="thread" className="item">
                                    <ion-icon name="mail-open-outline" className="image text-info"></ion-icon>
                                    <div className="in text-muted">
                                        <div>
                                            <footer>Upload attached image</footer>
                                            Upload Image
                                            <footer>1 month ago</footer>
                                        </div>
                                        <span className="badge badge-info badge-empty" style={{height:"15px !important", width: "15px !important"}}></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="closeTicket" role="tabpanel">
                        <ul className="listview image-listview">
                            <li>
                                <a href="thread" className="item">
                                    <ion-icon name="checkmark-circle-outline" className="image text-success"></ion-icon>
                                    <div className="in">
                                        <div>
                                            <header>This is test entry</header>
                                            Add Content
                                            <footer>25 minutes ago</footer>
                                        </div>
                                        <span className="badge badge-success badge-empty" style={{height:"15px !important", width: "15px !important"}}></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="thread" className="item">
                                    <ion-icon name="checkmark-circle-outline" className="image text-success"></ion-icon>
                                    <div className="in">
                                        <div>
                                            <header>Upload attached image</header>
                                            Upload Image
                                            <footer>1 month ago</footer>
                                        </div>
                                        <span className="badge badge-success badge-empty" style={{height:"15px !important", width: "15px !important"}}></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="thread" className="item">
                                    <ion-icon name="checkmark-circle-outline" className="image text-success"></ion-icon>
                                    <div className="in">
                                        <div>
                                            <header>Upload attached image</header>
                                            Upload Image
                                            <footer>1 hour ago</footer>
                                        </div>
                                        <span className="badge badge-success badge-empty" style={{height:"15px !important", width: "15px !important"}}></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Interact
