import React, { useState, useEffect } from 'react';
import axios from '../../AxiosConfig'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import jwt_decode from 'jwt-decode'

ChartJS.register(ArcElement, Tooltip, Legend);

function Referal(props) {

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const website_ID = tokenData.SelectedDomain.Website_ID

    const today = new Date()
    const numberOfDaysToMinus = 30
    const fdate = today.setDate(today.getDate() - numberOfDaysToMinus);
    const defaultFromDate = new Date(fdate).toISOString().split('T')[0] // yyyy-mm-dd

    const todayDate = new Date();
    const tdate = todayDate.setDate(todayDate.getDate());
    const defaultToDate  = new Date(tdate).toISOString().split('T')[0] // yyyy-mm-dd

    let [fromDate, setFromDate] = useState(defaultFromDate)
    let [toDate, setToDate] = useState(defaultToDate)
    const [referralVisitors, setReferralVisitors] = useState(null)
    const [referralData, setReferralData] = useState([])

    const getData = () => {
        let referral = []
        let count = []
        axios.get('/analytics/referralReport/'+ website_ID +'/'+ fromDate +'/' + toDate).then(res=>{

            setReferralData(res.data.data)
            Object.keys(res.data.data).forEach(function(key) {
                referral.push(key);
                count.push(res.data.data[key])
			});

            setReferralVisitors({
                labels: referral,
                datasets: [
                {
                    label: '# of Visitors',
                    data: count,
                    backgroundColor: [
                    'rgba(255, 99, 132,1)',
                    'rgba(54, 162, 235,1)',
                    'rgba(255, 206, 86,1)',
                    'rgba(75, 192, 192,1)',
                    'rgba(153, 102, 255,1)',
                    'rgba(255, 159, 64,1)',
                    ],
                    borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
                ],
            })
        })
    }

    const handleFromDateChange = ev => setFromDate(ev.target.value);
    const handleToDateChange = ev => setToDate(ev.target.value);

    useEffect(() => {
        getData()
    }, [fromDate, toDate])

    return (
        <div className="section mt-2 card-body">
            <div className="row">
                <div className="col-6 col-md-6">
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="name5">From</label>
                            <input type="date" className="form-control" id="name5" autoComplete="off" defaultValue={fromDate} onChange={handleFromDateChange} />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-6">
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="name5">To</label>
                            <input type="date" className="form-control" id="name5" autoComplete="off" defaultValue={toDate} onChange={handleToDateChange}/>
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <div className="row">
                    <div className="col-12 col-md-6 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-subtitle">Referals</h5>
                                {referralVisitors &&
                                    <Doughnut data={referralVisitors} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-subtitle">Top Referal Traffic</h5>
                                <div className="row">
                                    {Object.keys(referralData).map((key, i) => (
                                        <>
                                            <div className="col-6">
                                                {key}
                                            </div>
                                            <div className="col-6">
                                                {referralData[key]}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Referal
