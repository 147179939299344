import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { addBlockModal, addPageModal } from '../../features/modal/offcanvasSlice'
import BlockListModal from '../Elements/Modals/BlockListModal'

const Add = ({
  addBlockModal,
  addPageModal
}) => {
  const addNewBlock = () => {
    addBlockModal({showAddBlockModal:true})
  }

  const pageName = localStorage.getItem('previousPage')
    return (
        <>
            <div id="appCapsule">
                <div className="section full mb-2">
                    <div className="wide-block pt-2 pb-1">
                        <div className="row mb-1">
                            <Link to={'/manage?url=' + pageName} className="headerButton text-secondary d-flex align-items-center">
                                <ion-icon name="chevron-back-outline" className="fs-6"></ion-icon>
                                <small>Back</small>
                            </Link>
                            <div className="listview-title mb-0 col-12 col-md-12 d-lfex justify-content-center">
                                <span>Manage Wesbite</span>
                            </div>
                            <div className="col-6 col-md-6">
                                {/* <button type="button" className="btn btn-primary btn-lg btn-block me-1 mb-1" onClick={() => {addNewBlock()}}>
                                    <ion-icon name="add-outline"></ion-icon> Add Block
                                </button> */}
                                <div className="d-flex justify-content-end">
                                    <div className="text-center">
                                        <button type="button" className="btn btn-icon btn-outline-warning rounded btn-lg" style={{height: '80px', width: '80px'}} onClick={() => {addNewBlock()}}>
                                            <ion-icon name="albums-outline" className="fs-1"></ion-icon>
                                        </button>
                                        <div style={{fontSize: '12px'}}>Block</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-6">
                                {/* <button type="button" className="btn btn-primary btn-lg btn-block me-1 mb-1" onClick={() => {addPageModal({showAddPageModal: true})}}>
                                    <ion-icon name="add-outline"></ion-icon> Add Page
                                </button> */}
                                <div className="d-flex justify-content-start">
                                    <div className="text-center">
                                        <button type="button" className="btn btn-icon btn-outline-dark rounded btn-lg" style={{height: '80px', width: '80px'}} onClick={() => {addPageModal({showAddPageModal: true})}}>
                                            <ion-icon name="document-outline" className="fs-1"></ion-icon>
                                        </button>
                                        <div style={{fontSize: '12px'}}>Page</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="listview-title mb-0 col-12 col-md-12 d-lfex justify-content-center">
                                <span>Other</span>
                            </div>
                            <div className="col-6 col-md-6">
                                {/* <Link to="/email" className="btn btn-success btn-lg btn-block me-1 mb-1">
                                    <ion-icon name="add-outline"></ion-icon> Create Email
                                </Link> */}
                                <div className="d-flex justify-content-end">
                                    <div className="text-center">
                                        <Link to="/email" className="btn btn-icon btn-outline-secondary rounded btn-lg" style={{height: '80px', width: '80px'}}>
                                            <ion-icon name="mail-outline" className="fs-1"></ion-icon>
                                        </Link>
                                        <div style={{fontSize: '12px'}}>Email</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-6">
                                {/* <a href="https://webweb.ai/build-website" rel="noreferrer" target="_blank" className="btn btn-success btn-lg btn-block me-1 mb-1">
                                    <ion-icon name="add-outline"></ion-icon> Create Website
                                </a> */}
                                <div className="d-flex justify-content-start">
                                    <div className="text-center">
                                        <a href="https://webweb.ai/build-website" rel="noreferrer" target="_blank" className="btn btn-icon btn-outline-primary rounded btn-lg" style={{height: '80px', width: '80px'}}>
                                            <ion-icon name="globe-outline" className="fs-1"></ion-icon>
                                        </a>
                                        <div style={{fontSize: '12px'}}>Website</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BlockListModal></BlockListModal>
        </>
    )
}

const mapStateToProps = ({ offcanvas }) => {
  const {
    showAddBlockModal,
    showAddPageModal
  } = offcanvas

  return {
    showAddBlockModal,
    showAddPageModal
  }
}
  
const mapDispatchToProps = {
  addBlockModal,
  addPageModal
}

export default connect(mapStateToProps,mapDispatchToProps)(Add)
