import React from 'react'

function FacebookMessenger(props) {
    return (
        <div>
            <div className="listview-title mt-2 pe-0">
                <div className="row w-100">
                    <div className="col-md-6 col-6">
                        Facebook Messenger
                    </div>
                    <div className="col-md-6 col-6 d-flex justify-content-end align-items-center dropdown">
                    <ion-icon name="arrow-up-outline"></ion-icon>&nbsp;&nbsp;&nbsp;&nbsp;<ion-icon name="ellipsis-vertical-outline" data-bs-toggle="dropdown"></ion-icon>
                        <div className="dropdown-menu dropdown-menu-end">
                            <a className="dropdown-item" href="#!">
                                <ion-icon name="cog-outline" className="text-primary"></ion-icon> Configuration
                            </a>
                            <a className="dropdown-item" href="#!">
                                <ion-icon name="cog-outline" className="text-primary"></ion-icon> Style
                            </a>
                            <a className="dropdown-item" href="#!">
                                <ion-icon name="create-outline" className="text-info"></ion-icon> Manage
                            </a>
                            <a className="dropdown-item" href="#!">
                                <ion-icon name="trash-outline" className="text-danger"></ion-icon> Delete
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="listview image-listview">
                <li className="divider-title p-0">
                    <a href="#!" className="item">
                        {/* <img src={Logo} alt="Brand Img" className="image" /> */}
                        <ion-icon name="logo-facebook" className="image"></ion-icon>
                        <div className="in">
                            <div>Facebook Messenger</div>
                            <span className="fs-4 text-secondary"><ion-icon name="cog-outline"></ion-icon></span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default FacebookMessenger
