import React, { useState, useEffect } from 'react';
import Hospital from '../images/icon/hospital.png';
import Dietitian from '../images/icon/dietitian.png';
import Physiotherapist from '../images/icon/physiotherapist.png'
import Dentist from '../images/icon/dentist.png'
import Ayurveda from '../images/icon/ayurveda.png'
import Fitness from '../images/icon/fitness.png'
import Lab from '../images/icon/lab.png'
import axios from 'axios';
import { Link } from 'react-router-dom'

function divideArrayIntoGroups(array, groupSizes) {
    // console.log(array);
    const result = [];
    let groupIndex = 0;
    
    while (array.length > 0) {
      const groupSize = groupSizes[groupIndex % groupSizes.length];
      const group = array.splice(0, groupSize);
      
      result.push({ category: group });
      groupIndex++;
    }
    
    return result;
  }

const AllCategories = props => {
    const [category, setCategory] = useState([]);

    // const groupSizes = [4, 3];
    // useEffect(() => {
    //     if(category.length === 0) {
    //         axios.get('https://api.webweb.ai/WebsitesCategories')
    //             .then(response => {
    //                 const allCategories = response.data.data;
    //                 //   console.log(response)
    //                 const groupedArray = divideArrayIntoGroups(allCategories, groupSizes);
    //                 setCategory(groupedArray);
    //                 console.log(groupedArray);
    //             })
    //             .catch(error => {
    //                 console.log('error');
    //             })
    //     }
    // }, [])

    const catergoryArray = [
        {
            "title": "Healthcare / Wellness",
            "src": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/Healthcare-Wellness-color.svg",
            "color" : "#01866D",
            "bgColor" : "#03e5ba",
            "categories" : [
                {
                    "title": "Hospital / Healthcare centre",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/Hospital.svg",
                    "url": "Hospital-Healthcare-center"
                },
                {
                    "title": "Ayurvedic Consultation",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/ayurvedic-consaltant.svg",
                    "url": "Ayurvedic-Consultation"
                },
                {
                    "title": "Medical Expert",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/medical-expert-final.svg",
                    "url": "Medical-Expert"
                },
                {
                    "title": "Dietitian",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/dietitian-1.svg",
                    "url": "Dietitian"
                },
                {
                    "title": "Dentist",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/dentist-1.svg",
                    "url": "Dentist"
                },
                {
                    "title": "Beauty / Spa / Salon",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/beauty.svg",
                    "url": "Beauty-Spa-Salon"
                },
                {
                    "title": "Physiotherapist",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/physiotherapist-1.svg",
                    "url": "Physiotherapist"
                },
                {
                    "title": "Fitness / Yoga centre",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/fitness-center.svg",
                    "url": "Fitness-Yoga-centre"
                }
            ]
        },
        {
            "title": "Education / Informative",
            "src": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/Education-Informative.svg",
            "color" : "#BF0000",
            "bgColor" : "#FFE4E",
            "categories" : [
                {
                    "title": "Kinder Garden School ",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/kindergarden.svg",
                    "url": "Kinder-Garden-School"
                },
                {
                    "title": "Coaching / training centre",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/training-center.svg",
                    "url": "Coaching-training-centre"
                },
                {
                    "title": "Placement Services",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/placement-services-1.svg",
                    "url": "Placemen-Services"
                },
                {
                    "title": "School / College / Institute ",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/institute.svg",
                    "url": "School-College-Institute "
                },
                {
                    "title": "Non-Profit/Religious Place",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/religious-place.svg",
                    "url": "Non-Profit-Religious-Place"
                },
                {
                    "title": "Blog",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/blog-1.svg",
                    "url": "Blog"
                },
                {
                    "title": "Event",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/event-1.svg",
                    "url": "Event"
                }
            ]
        },
        {
            "title": "Industrial / Manufacturing",
            "src": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/Industrial-Manufacturing.svg",
            "color" : "#013583",
            "bgColor" : "#E2EFF9",
            "categories" : [
                {
                    "title": " Food Processing / Pharma ",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/food-processing-1.svg",
                    "url": "Food-Processing-Pharma"
                },
                {
                    "title": "Architects & Landscapers",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/architech-1.svg",
                    "url": "Architects-Landscapers"
                },
                {
                    "title": "Laboratory",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/laboratory-1.svg",
                    "url": "Laboratory"
                },
                {
                    "title": "Manufacturing",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/manufacturing-1.svg",
                    "url": "Manufacturing"
                },
                {
                    "title": "Civil Services & Contractors",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/civil-services.svg",
                    "url": "Civil-Services-Contractors"
                },
                {
                    "title": "Engineering Company",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/engineering.svg",
                    "url": "Engineering-Company"
                },
                {
                    "title": "Real Estate Company",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/estate.svg",
                    "url": "Real-Estate-Company"
                }
            ]
        },
        {
            "title": "Professional / Small Business",
            "src": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/Professional-Small-Business.svg",
            "color" : "#FFBF16",
            "bgColor" : "#FFF9E8",
            "categories" : [
                {
                    "title": " Professional / Individual ",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/Professional-1.svg",
                    "url": "Professional-Individual"
                },
                {
                    "title": "Studio / Agency",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/studio-1.svg",
                    "url": "Studio-Agency"
                },
                {
                    "title": "Hotel / Cottage / Resort",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/resort.svg",
                    "url": "Hotel-Cottage-Resort"
                },
                {
                    "title": "Portfolio / Photography",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/portfolio-1.svg",
                    "url": " Portfolio-Photography"
                },
                {
                    "title": "Consultancy / Service Provider",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/consultancy.svg",
                    "url": "Consultancy-Service-Provider"
                },
                {
                    "title": "Law Firm",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/law-firm-1.svg",
                    "url": "Law-Firm"
                },
                {
                    "title": "Artist / Singer / Musician",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/artist-1.svg",
                    "url": "Artist-Singer-Musician"
                },
                {
                    "title": "Accounting and Taxation Advisory",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/accounting-1.svg",
                    "url": "Accounting-and-Taxation-Advisory"
                }
            ]
        },
        {
            "title": "Product Showcase",
            "src": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/Product-Showcase.svg",
            "color" : "#D9008D",
            "bgColor" : "#FFE5F3",
            "categories" : [
                {
                    "title": "Food Products",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/food.svg",
                    "url": "Food-Products"
                },
                {
                    "title": "Industrial Supplies",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/indust.svg",
                    "url": "Industrial-Supplies"
                },
                {
                    "title": "Plants & Suppliments",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/plant.svg",
                    "url": "Plants-Suppliments"
                },
                {
                    "title": "Books & Stationary",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/books.svg",
                    "url": " Books-Stationary"
                },
                {
                    "title": "Arts & Handcrafts",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/arts.svg",
                    "url": "Arts-Handcrafts"
                },
                {
                    "title": "Pharmacy & Medical",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/pharmacy-medical-1.svg",
                    "url": "Pharmacy-Medical"
                },
                {
                    "title": "Gadget & Accessories",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/gadget-1.svg",
                    "url": "Gadget-Accessories"
                },
                {
                    "title": "Fashion & Jewellary",
                    "icon": "https://webweb.ams3.digitaloceanspaces.com/data/appThumbnail/Images/fashion-1.svg",
                    "url": "Fashion-Jewellary"
                }
            ]
        },
]

    return (
        <div>
            <div class="categoriesSection">
                <h1 class="text-center ">Choose your business category</h1>

                {
                    catergoryArray.map((cate, i) => {
                        return (
                            <div className='' key={i} style={{backgroundColor: cate.bgColor}}>
                                <div class="row container px-0 mx-auto py-3">
                                    <div class="col-md-3 d-flex align-items-center justify-content-center">
                                        <div className='categoryBox'>
                                            <img className='w-100' src={cate?.src} />
                                            <h4 style={{color: cate?.color}}><b>{cate?.title}</b></h4>
                                        </div>
                                    </div>
                                    <div className='col-md-9'>
                                        <div className='row categoriesOval px-0'>
                                            {
                                                cate.categories.map((item, b) => {
                                                    return (
                                                        <div className='col-md-4 my-1' key={b}>
                                                            <Link to={`?category=${item.url.trim().toLowerCase()}`} class="first-row-1">
                                                                <img class="img-fluid img-w-h-set" src={item.icon} />
                                                                <p class="my-md-0 text-center ps-1 first-row-text" style={{ color : `${cate?.color}` }}>{item.title} </p>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        )
                    })
                }
                    {/* {
                        category.length > 0 && category.map((items, j) => {
                            return <div key={j} class="row col-lg-12 m-auto first-row justify-content-between">
                                {
                                    items !== undefined && items.category.map((item, i) => {
                                        return <div key={i} class="col-6 col-lg-3 col-md-6 col-sm-12">
                                            <Link to={item.url ? `?category=${item.url}` : `?category=${item.CategoryName}`} class="badge rounded-pill first-row-1" style={item.bgColor ? { backgroundColor : item.bgColor} : { backgroundColor : '#1C49AE'}}>
                                                <img class="img-fluid img-w-h-set" src={item.imageUrl ? item.imageUrl : Hospital} />
                                                <p class="my-md-0 text-center ps-1 first-row-text" style={item.textColor ? { color : item.textColor } : { color : '#fff'}}>{item.CategoryName} </p>
                                            </Link>
                                        </div>
                                    })
                                }
                            </div>
                        })
                    } */}



                    {/* <div class="row col-lg-12 m-auto first-row justify-content-between">
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 first-icon">
                                <img class="img-fluid img-w-h-set" src={Hospital} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Hospital / <br />Healthcare centre </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=dietitian'} class="badge rounded-pill first-row-1 second-icon">
                                <img class="img-fluid img-w-h-set" src={Dietitian} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Dietitian </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=physiotherapist'} class="badge rounded-pill first-row-1 third-icon">
                                <img class="img-fluid img-w-h-set" src={Physiotherapist} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Physiotherapist </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=dentist'} class="badge rounded-pill first-row-1 four-icon">
                                <img class="img-fluid img-w-h-set" src={Dentist} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Dentist </p>
                            </Link>
                        </div>

                    </div> */}

                    {/* <div class="row col-lg-12 m-auto first-row justify-content-center">
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=ayurvedic-consultation'} class="badge rounded-pill first-row-1 five-icon">
                                <img class="img-fluid img-w-h-set" src={Ayurveda} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Ayurvedic <br /> Consultation </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 six-icon">
                                <img class="img-fluid img-w-h-set" src={Fitness} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Fitness / <br />Yoga centre </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 seven-icon">
                                <img class="img-fluid img-w-h-set" src={Lab} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Laboratory </p>
                            </Link>
                        </div>


                    </div>

                    <div class="row col-lg-12 m-auto first-row justify-content-between">
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 eight-icon">
                                <img class="img-fluid img-w-h-set" src={Hospital} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Medical Expert </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 nine-icon">
                                <img class="img-fluid img-w-h-set" src={Dietitian} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Beauty / Spa / Salon </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 ten-icon">
                                <img class="img-fluid img-w-h-set" src={Physiotherapist} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Kinder Garden School </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 eleven-icon">
                                <img class="img-fluid img-w-h-set" src={Dentist} />
                                <p class="my-md-0 text-center ps-1 first-row-text">School / College / <br />
                                    Institute </p>
                            </Link>
                        </div>

                    </div>

                    <div class="row col-lg-12 m-auto first-row justify-content-center">
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 twelve-icon">
                                <img class="img-fluid img-w-h-set" src={Hospital} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Coaching / <br />
                                    training centre </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 thirteen-icon">
                                <img class="img-fluid img-w-h-set" src={Dietitian} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Manufacturing </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 fourteen-icon">
                                <img class="img-fluid img-w-h-set" src={Physiotherapist} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Engineering Company </p>
                            </Link>
                        </div>


                    </div>

                    <div class="row col-lg-12 m-auto first-row justify-content-between">
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 fifteen-icon">
                                <img class="img-fluid img-w-h-set" src={Hospital} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Medical Expert </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 sixteen-icon">
                                <img class="img-fluid img-w-h-set" src={Dietitian} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Beauty / Spa / Salon </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 seventeen-icon">
                                <img class="img-fluid img-w-h-set" src={Physiotherapist} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Kinder Garden School </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 eightneen-icon">
                                <img class="img-fluid img-w-h-set" src={Dentist} />
                                <p class="my-md-0 text-center ps-1 first-row-text">School / College / <br />
                                    Institute </p>
                            </Link>
                        </div>

                    </div>

                    <div class="row col-lg-12 m-auto first-row justify-content-center">
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 nineteen-icon">
                                <img class="img-fluid img-w-h-set" src={Hospital} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Coaching / <br />
                                    training centre </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 twenty-icon">
                                <img class="img-fluid img-w-h-set" src={Dietitian} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Manufacturing </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 twenty-one-icon">
                                <img class="img-fluid img-w-h-set" src={Physiotherapist} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Engineering Company </p>
                            </Link>
                        </div>


                    </div>

                    <div class="row col-lg-12 m-auto first-row justify-content-between">
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 twenty-two-icon">
                                <img class="img-fluid img-w-h-set" src={Hospital} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Medical Expert </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 twenty-three-icon">
                                <img class="img-fluid img-w-h-set" src={Dietitian} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Beauty / Spa / Salon </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 twenty-four-icon">
                                <img class="img-fluid img-w-h-set" src={Physiotherapist} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Kinder Garden School </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 twenty-five-icon">
                                <img class="img-fluid img-w-h-set" src={Dentist} />
                                <p class="my-md-0 text-center ps-1 first-row-text">School / College / <br />
                                    Institute </p>
                            </Link>
                        </div>

                    </div>

                    <div class="row col-lg-12 m-auto first-row justify-content-center">
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 twenty-six-icon">
                                <img class="img-fluid img-w-h-set" src={Hospital} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Coaching / <br />
                                    training centre </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 twenty-seven-icon">
                                <img class="img-fluid img-w-h-set" src={Dietitian} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Manufacturing </p>
                            </Link>
                        </div>
                        <div class="col-6 col-lg-3 col-md-6 col-sm-12">
                            <Link to={'/build-website?category=hospital-healthcare-center'} class="badge rounded-pill first-row-1 twenty-eight-icon">
                                <img class="img-fluid img-w-h-set" src={Physiotherapist} />
                                <p class="my-md-0 text-center ps-1 first-row-text">Engineering Company </p>
                            </Link>
                        </div>


                    </div> */}

            </div>
        </div>
    );
};



export default AllCategories;