import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Navbar } from 'react-bootstrap'
import axios from '../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { showOffcanvas, showButtonModal, newButtonData, showSwitchModal } from '../../features/modal/offcanvasSlice'
import { setWebsiteData, cssConverter } from '../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../features/utilities/appToastSlice'

const icon = 'document-text-outline'

const SM_HEAD_DFL1 = ({
  items,
  websiteDataAppearance = { properties: {} },
  websiteDataBlocks,
  websiteDataData,
  blockID,
  showOffcanvas,
  setWebsiteData,
  showAppToast,
  showButtonModal,
  newButtonData,
  showSwitchModal,
  properties
}) => {
  // console.log(items)
  const [blockProperties, setBlockProperties] = useState(null)
  const token = localStorage.getItem('token')
  const tokenData = (token) ? jwt_decode(token) : false
  // console.log(tokenData)
  let { properties: websiteDataAppearanceProperties, navigation } = websiteDataAppearance
  // console.log(navigation)

  if(tokenData.userInfo.RoleID === 'Moderator') {
    const categoryConfig = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('category')))))
    navigation = JSON.parse(categoryConfig.StructureConfig).navigation
    // console.log(navigation)
  }

  const {
    sitemap: sitemapData,
    defaultPage: activePage
  } = websiteDataAppearanceProperties
  // console.log(websiteDataAppearance)
  // const navBar = sitemapList(navigation, sitemapData, activePage)
  let navigate = useNavigate()

  const handleLogoUpdate = () => {
    // showOffcanvas({ isVisible:true, items:items, properties:websiteDataAppearance.properties, blockType: 'SM', blockID:blockID, msg:''})
    navigate(
      '/build?url=' + websiteDataAppearance.properties.defaultPage
    )
  }

  const selectedDomain = tokenData.SelectedDomain.Domain

  const handleRemoveLogo = () => {
    const inputs = {
      "title": items[0].title,
      "subtitle": items[0].subtitle,
      "description": items[0].description,
      "image": [],
      "button": items[0].button,
      "category": "",
      "url": items[0].url,
      "form": "",
      "content": ""
    }
    axios.put('/block/' + selectedDomain + '/' + blockID + '/items', [inputs]).then(res=>{
      if (res.data.message === 'Success') {
          setWebsiteData(res.data.data)
          showAppToast({ toastMessage: 'Updated Successfully', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
      } else {
          showAppToast({ toastMessage: 'Fail ', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
      }
    })
  }
  
  const handleBlockUpdate = (msg) => {
    showOffcanvas({ isVisible: true, items:items, properties:websiteDataAppearance.properties, blockType: 'SM', blockID:blockID, msg:msg})
  }
  
  function handleShowButtonModal(buttonData, ID) {
    handleBlockUpdate()
    setTimeout(() => {
      showButtonModal({
        isButtonModalVisible: true,
        buttonData: buttonData,
        buttonID: ID
      })
    }, [1000])
    newButtonData({ buttonDataObj: buttonData })
  }

  const handlePageSwitch = () => {
    showSwitchModal({isSwitchModalVisible: true, switchModalMessage : 'changePage', blockID: blockID})
  }

  const switchPage = (pageID) => {
    if(tokenData.userInfo.RoleID === 'Moderator') {
      let categoryPages = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('CategoryPages')))))
      // console.log(categoryPages)
      for (let i = 0; i < categoryPages.length; i++) {
        if(categoryPages[i].PageID == pageID) {
          let categoryPageJson = btoa(unescape(encodeURIComponent(categoryPages[i].StructureConfig)))
          categoryPages[i].StructureConfig = JSON.parse(categoryPages[i].StructureConfig)
          // localStorage.setItem('website', categoryPageJson)
          setWebsiteData(categoryPageJson)
          localStorage.setItem('selectedPageID', categoryPages[i].ID)
          setWebsiteData(categoryPages[i].StructureConfig)
          navigate('/build?url=' + categoryPages[i].StructureConfig.appearance.pages['SM_PAGE1'].url)
        }
      }
    } else {
      if (websiteDataAppearance !== undefined) {
        {Object.keys(websiteDataAppearance.pages).map(key =>{
          if (pageID === key) {
            navigate('/build?url=' + websiteDataAppearance.pages[key].url)
          }
        })}
      }
    }
  }
  
  useEffect(() => {
    Object.keys(websiteDataBlocks).map((key,value) => {
      // console.log(key,value)
      if (blockID === key) {
        // console.log(websiteDataBlocks[key]['properties'])
        setBlockProperties(websiteDataBlocks[key]['properties'])
      }
    })
  }, [websiteDataBlocks])

  // const cssConverter = (cssObject, defaultCss) => {
  //   let convertedObject = {}
  //   if (cssObject !== null) {
  //     Object.keys(cssObject).map((key,value) => {
  //       if (key.includes('-')) {
  //         const characterPosition = key.indexOf('-')
  //         const getCharacter = key.charAt(characterPosition + 1).toUpperCase()
  //         const a1 = key.substring(0, characterPosition) + getCharacter + key.substring(characterPosition + 2, key.length)
  //         console.log(a1)
  //         convertedObject[a1] = cssObject[key]
  //       } else {
  //         convertedObject[key] = cssObject[key]
  //       }
  //     })
  //   }
  //   if (defaultCss !== undefined && defaultCss !== null) {
  //     Object.keys(defaultCss).map((key,value) => {
  //       convertedObject[key] = defaultCss[key]
  //     }) 
  //   }
  //   return convertedObject
  // }
  // console.log(properties)
  return (
    <div id={blockID} style={{backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: (properties && properties.background && properties.background.color && properties.background.color !== undefined) ? `${properties.background.color}` : (properties && properties.backgroundValue !== undefined) ? properties.backgroundValue : '' , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
      <div
        className="appHeader position-relative" style={{top: 'auto', zIndex: 0, minHeight: '90px', backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: (properties && properties.background && properties.background.color && properties.background.color !== undefined) ? `${properties.background.color}` : (properties && properties.backgroundValue !== undefined) ? properties.backgroundValue : '' , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
        <div className="left" style={{height: '100px'}}>
          <div className="headerButton dropdown p-0">
            {items[0].image.length > 0 &&
              <>
                <img
                  src={items[0].image[0].src}
                  alt={items[0].image[0].alt}
                  className={'img-fluid ' + items[0].image[0].style}
                  style={cssConverter((blockProperties !== null) ? blockProperties['imageCss'] : null, {maxHeight: '100%', cursor: 'pointer'})}
                  onClick={handleLogoUpdate}
                />
                {/* <span
                  className="badge badge-warning badge-empty"
                  style={{
                    position: 'absolute',
                    top: '70%',
                    right: '17%',
                    cursor: 'pointer'
                  }}
                  data-bs-toggle="dropdown"
                >
                  <ion-icon
                    name="chevron-down-outline"
                    style={{ fontSize: '8px', position: 'absolute', left: 0 }}
                  ></ion-icon>
                </span> */}
              </>
            }
            {items[0].image.length === 0 &&
              <>
                <h3 className="mb-0" onClick={handleLogoUpdate} style={{cursor: 'pointer'}}>
                  {websiteDataData.BusinessName}
                </h3>
                {/* <span
                className="badge badge-warning badge-empty"
                style={{
                  position: 'absolute',
                  top: '55%',
                  right: '17%',
                  cursor: 'pointer'
                }}
                data-bs-toggle="dropdown"
                >
                  <ion-icon
                    name="chevron-down-outline"
                    style={{ fontSize: '8px', position: 'absolute', left: 0 }}
                  ></ion-icon>
                </span> */}
              </>
            }
            
            {/* <div className="webweb-dropdown-menu dropdown-menu">
              <a className="dropdown-item" href="#!" onClick={handleLogoUpdate}>
                <ion-icon
                  name="create-outline"
                  className="text-info"
                ></ion-icon>
                Update Logo
              </a>
              <a className="dropdown-item" href="#!" onClick={handleRemoveLogo}>
                <ion-icon name="trash-outline" className="text-info"></ion-icon>
                Remove Logo
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#!">
                <ion-icon
                  name="add-outline"
                  className="text-success"
                ></ion-icon>{' '}
                Make Bigger
              </a>
              <a className="dropdown-item" href="#!">
                <ion-icon
                  name="remove-outline"
                  className="text-danger"
                ></ion-icon>{' '}
                Make Smaller
              </a>
            </div> */}
          </div>
        </div>
        <div className="right" style={{height: '100px'}}>
          <div className="headerButton dropdown d-none" id="mobile-menu">
            <ion-icon
              name="menu-outline"
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
            ></ion-icon>
            <div className="webweb-dropdown-menu dropdown-menu">
              {navigation.header.map((value, i) => {
                return <div key={i}> {value.hasOwnProperty('children') &&
                  <div className="dropdown d-flex justify-content-start">
                    <div
                        className="headerButton dropdown-toggle justify-content-start flex-column align-items-start"
                        data-bs-toggle="dropdown"
                        style={{ cursor: 'pointer' }}
                    >
                        <span className="mb-1">{value['title']}</span>
                        {value['children'].map((val, j) => {
                          return <Link className="dropdown-item-submenu" key={j} to={'/build?url=home'}>{val['title']}
                          </Link>
                        })} 
                    </div>
                </div>
                }

                {!value.hasOwnProperty('children') &&
                  <Link className="dropdown-item" to={'/build?url='+value['page']}>
                    {value['title']}
                  </Link>
                }
              </div>

              })}
            </div>
          </div>
        </div>
        <div className='center right-md'>
          <div>
            <div className="webweb-w-20 dropdown text-center">
                <div className="form-group boxed">
                    <div className="input-wrapper">
                        <div className="dropdown">
                          <button className="btn btn-text-dark btn-sm dropdown-toggle mobile-view-nav" onClick={handlePageSwitch} type="button" data-bs-toggle="dropdown" style={{width: 'fit-content'}}>
                            <div className="d-flex">
                              <ion-icon name="menu-outline" className="me-0" style={{fontSize: '14px'}}></ion-icon> <div>Navigation</div>
                            </div>
                          </button>
                          <div className="desktop-view-nav">
                            <div className="d-flex flex-row">
                              {navigation.header.map((value, key) => {
                                // if (key <= 3) {
                                  return <div key={key}> {(value.hasOwnProperty('children') && value.children.length !== 0) &&
                                    <div className="dropdown d-flex justify-content-start" onClick={handlePageSwitch}>
                                      <div
                                          className="headerButton dropdown-toggle justify-content-start flex-column align-items-start"
                                          data-bs-toggle="dropdown"
                                          style={{ cursor: 'pointer' }}
                                      >
                                        <a className="dropdown-item btn btn-text-dark d-flex align-items-center pe-1" href="javascript:void(0)" onClick={() => {switchPage(value['page'])}} style={{padding: '3px 12px'}}>
                                          {value['title']}&nbsp;<ion-icon name="chevron-down-outline" className="me-0" style={{marginTop: '4px', fontSize: '12px'}}></ion-icon>
                                        </a>
                                      </div>
                                  </div>
                                  }

                                  {(value.hasOwnProperty('children') && value.children.length === 0) &&
                                    <a className="dropdown-item btn btn-text-dark" href="javascript:void(0)" onClick={() => {switchPage(value['page'])}} style={{padding: '3px 12px'}}>
                                      {value['title']}
                                    </a>
                                  }

                                  {(!value.hasOwnProperty('children')) &&
                                    <a className="dropdown-item btn btn-text-dark" href="javascript:void(0)" onClick={() => {switchPage(value['page'])}} style={{padding: '3px 12px'}}>
                                      {value['title']}
                                    </a>
                                  }
                                  </div>
                                // }
                              })}
                              {/* <a className="dropdown-item btn btn-text-dark" href="javascript:void(0)"  onClick={handlePageSwitch} style={{padding: '3px 12px'}}>
                                <ion-icon name="menu-outline" className="me-0" style={{fontSize: '16px', marginTop: '-2px'}}></ion-icon>More
                              </a> */}
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div className='right' style={{height: '100px'}}>
          {items[0].button !== null && items[0].button.length > 0 &&
            <>
              {items[0].button.map((value, key) => {
                return <button key={key} className={'btn webweb-btn-secondary btn-sm me-1   d-none d-lg-block' + value.style} style={cssConverter((blockProperties !== null) ? blockProperties['buttonCss'] : null)} onClick={() => handleShowButtonModal(items[0].button,key)}>{value.title}</button>  
              })}
            </>
          }
          {/* {items[0].button.length === 0 &&
            <button className="btn btn-secondary btn-sm  d-none d-lg-block" onClick={() => handleShowButtonModal(items[0].button,items[0].button.length)}>Add CTA</button>
          } */}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ websiteData }) => {
  const { 
    appearance: websiteDataAppearance,
    blocks: websiteDataBlocks,
    data: websiteDataData
  } = websiteData

  return {
    websiteDataAppearance,
    websiteDataData,
    websiteDataBlocks
  }
}

const mapDispatchToProps = { 
  showOffcanvas,
  setWebsiteData,
  showAppToast,
  showButtonModal,
  newButtonData,
  showSwitchModal
 }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SM_HEAD_DFL1)