import React from 'react'
import { connect } from 'react-redux'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'
import { showEditOffcanvas, showDeleteModal, showUpdateDataOffcanvas, setNewBlockType } from '../../features/modal/offcanvasSlice'

const CN_GLRY_DFL1 = ({ items = [], blockID, showEditOffcanvas, properties, websiteDataBlocks, showDeleteModal, showUpdateDataOffcanvas, setNewBlockType }) => {
  const handleBlockItemUpdate = itemID => {
    showEditOffcanvas({
      isEditVisible: true,
      itemID: itemID,
      items: items,
      blockID: blockID,
      properties: properties
    })
  }

  const handleNewEditItem = (itemID) => {
    setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID });
    // console.log("whatIsKey",itemID, blockID);
    showUpdateDataOffcanvas({
      isEditVisible: true,
      itemID: itemID,
      items: items,
      blockID: blockID,
      properties: properties
    })
  }

  const handleDeleteContent = (blockName, deleteItemID) => {
    const contentName = websiteDataBlocks[blockName].items.contents.split(
      '.'
    )[1]
    showDeleteModal({
      isDeleteVisible: true,
      deleteMessage: 'Are your sure? You want to delete this content?',
      triggerMessage: 'content',
      deleteID: contentName,
      deleteItemID: deleteItemID
    })
  }

  let cardWidth = -1;
  if (properties.countInRow === null || properties.countInRow === undefined || properties.countInRow === '') {
      if (items.length === 2) {
          cardWidth = 6
      } else if (items.length === 3) {
          cardWidth = 4
      } else if (items.length === 4) {
          cardWidth = 3
      } else if (items.length === 6) {
          cardWidth = 2
      } else if (items.length === 1) {
          cardWidth = 12
      }
  } else {
    if (properties.countInRow === 2) {
        cardWidth = 6
    } else if (properties.countInRow === 3) {
        cardWidth = 4
    } else if (properties.countInRow === 4) {
        cardWidth = 3
    } else if (properties.countInRow === 6) {
        cardWidth = 2
    } else if (properties.countInRow === 1) {
        cardWidth = 12
    }
}
  return (
    <div className="section pt-4 wide-block" style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
      <div class={(properties && properties.container === 'true') || properties.container === undefined || properties.container === '' ? 'container' : 'container-fluid'}>
        {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
          <div className="row">
            {properties.blockHeading !== '' &&
              <div className="col-12">
                <h3 className="text-center"  style={cssConverter(properties.blockHeadingCss)} dangerouslySetInnerHTML={{__html: properties.blockHeading}} />
              </div>
            }
            {properties.blockDescription !== '' &&
              <div className="col-12">
                <p className="text-center"  style={cssConverter(properties.blockDescriptionCss)} dangerouslySetInnerHTML={{__html: properties.blockDescription}} />
              </div>
            }
          </div>
        }
        <div className="row">
          {items.map((item, key) => {
            return (
              <div className={`col-12 col-md-${cardWidth} mb-2 position-relative`} key={key}>
                <div className="card bg-dark text-white"  style={cssConverter(item.itemCss, {backgroundImage: item && item.itemBackground && item.itemBackground.image && `url${item.itemBackground.image}`, backgroundColor: item && item.itemBackground && item.itemBackground.color && `${item.itemBackground.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'})}>
                  {item.image !== null && item.image.length > 0 &&
                  item.image.map((image, i) => {
                    return <img
                      src={image.src}
                      key={i}
                      className="card-img overlay-img cursor-pointer"
                      alt="Slider Img"
                      style={cssConverter(item.imageCss)}
                    />
                  })
                  }
                  {item.title !== '' &&
                    <div className="d-flex align-items-center justify-content-center flex-column">
                      <h4 className="text-center text-light overflow-auto cursor-pointer"  style={cssConverter(item.titleCss)} onClick={() => { handleNewEditItem(key) }} dangerouslySetInnerHTML={{__html: item.title}} />
                    </div>
                  }
                </div>
                <div className='pos-absglr'>
                  {/* <div className="row">
                      <div className="col-6 col-md-6">
                        <button type="button" className="btn btn-secondary btn-sm btn-block me-1 mb-1" onClick={() => {handleBlockItemUpdate(key)}}>Edit</button>
                      </div>
                      <div className="col-6 col-md-6">
                        <button type="button" className="btn btn-danger btn-sm btn-block me-1 mb-1" onClick={() => {handleDeleteContent(blockID, key)}}>Delete</button>
                      </div>
                    </div> */}
                  <div className="row">
                    {/* <div className="col-4 col-md-4 text-end">
                      <span className="badge bg-secondary p-1 editBtnCss" onClick={() => { handleBlockItemUpdate(key) }}>Edit</span>
                    </div> */}
                    <div className="col-6 col-md-6 text-end">
                      <span className="badge bg-success p-1 editBtnCss" onClick={() => { handleNewEditItem(key) }}>Edit</span>
                    </div>
                    <div className="col-6 col-md-6">
                      <span className="badge bg-danger p-1 editBtnCss" onClick={() => { handleDeleteContent(blockID, key) }}>Delete</span>
                    </div>
                  </div>
                </div>
                {/* <div
                  style={{
                    backgroundImage: ' url(' + item.image[0].src + ')',
                    backgroundSize: 'cover',
                    borderRadius: '0.25rem',
                    height: '160px'
                  }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      borderRadius: '0.25rem',
                      height: '160px'
                    }}
                  >
                    <ion-icon
                      name="cog-outline"
                      className="text-light fs-5 mb-1"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleBlockItemUpdate(key)
                      }}
                    ></ion-icon>
                    <h4 className="text-light">{item.title}</h4>
                    <h5 className="text-light">{item.subtitle}</h5>
                  </div>
                </div> */}
                {/* <img src={item.image[0].src} alt={item.image[0].alt} className="imaged w-100" /> */}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ websiteData }) => {
  const {
    blocks: websiteDataBlocks
  } = websiteData
  return {
    websiteDataBlocks
  }
}

const mapDispatchToProps = {
  showEditOffcanvas,
  showDeleteModal,
  showUpdateDataOffcanvas,
  setNewBlockType
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CN_GLRY_DFL1)
