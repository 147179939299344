import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  LinksModalTaps: false,
  DefaultSocialLinksList: [
    { 
        id: 0,
        text: 'Call',
        type: 'call',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/call-phone.svg',
        url: ''
    },
    {
        id: 1,
        text: 'E-mail',
        type: 'email',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/email.svg',
        url: ''
    },
    {
        id: 2,
        text: 'Website',
        type: 'external',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/website.svg',
        url: ''
    },
    {
        id: 3,
        text: 'Add Contact',
        type: 'contact',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/add-contacts.svg',
        url: ''
    },
    {
        id: 4,
        text: 'Threads',
        type: 'socialMedia',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/threads-1.png',
        url: ''
    },
    { 
        id: 5,
        text: 'Twitter',
        type: 'socialMedia',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/twitterx.svg',
        url: ''
    },
    {
        id: 6,
        text: 'WhatsApp',
        type: 'socialMedia',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/whatsapp.svg',
        url: 'https://wa.me/+91'
    },
    {
        id: 7,
        text: 'Facebook',
        type: 'socialMedia',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/facebook.svg',
        url: ''
    },
    {
        id: 8,
        text: 'Instagram',
        type: 'socialMedia',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/instagram.svg',
        url: ''
    },
    {
        id: 9,
        text: 'LinkedIn',
        type: 'socialMedia',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/linkedin.svg',
        url: ''
    },
    {
        id: 10,
        text: 'YouTube',
        type: 'socialMedia',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/youtube.svg',
        url: ''
    },
    {
        id: 11,
        text: 'Telegram',
        type: 'socialMedia',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/telegram.svg',
        url: ''
    },
    {
        id: 12,
        text: 'Google Business',
        type: 'socialMedia',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/google-business-2.svg',
        url: ''
    },
    {
        id: 13,
        text: 'Google Maps',
        type: 'socialMedia',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/google-maps-2.svg',
        url: ''
    },
    {
        id: 14,
        text: 'PDF Download',
        type: 'download',
        icon: 'https://webweb.ams3.cdn.digitaloceanspaces.com/WEBWEB_IN/images/download.svg',
        url: ''
    }
  ]
}

export const LinksModalsSlice = createSlice({
  name: 'TapsModals',
  initialState,
  reducers: {
    setLinksModalTaps: (state, action) => {
      const {
        LinksModalTaps
      } = action.payload
      state.LinksModalTaps = LinksModalTaps;
    },
  }
})

export const { setLinksModalTaps } = LinksModalsSlice.actions;

export default LinksModalsSlice.reducer;
