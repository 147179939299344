import React from 'react'
import WebmailIMage from '../images/Mail sent.gif'

const Webmail = ({
    
}) => {
    return (
        <div id="appCapsule">
            <div className="section mt-2 d-flex align-items-center justify-content-center flex-column">
                {/* <iframe src="https://webmail.managedmails.com/interface/root#/login" width="100%" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                {/* https://webmail.managedmails.com/interface/root#/login */}
                <img src={WebmailIMage} width="40%" />
                <a href="https://webmail.managedmails.com/interface/root#/login" target="_blank" class="btn btn-primary btn-block w-50">Open Webmail</a>
            </div>
        </div>
    )
}

export default Webmail