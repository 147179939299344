import React from 'react'
import jwt_decode from 'jwt-decode'
import { connect } from 'react-redux'
import { showConfirmationModal } from '../../features/modal/offcanvasSlice'

const Websites = ({
    showConfirmationModal
}) => {
    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    const handleConfirmationModal = () => {
        showConfirmationModal({isConfirmationVisibleModal: true, confirmationMessage: 'Are you sure? You want to switch website?'})
    }
    return (
        <div id="appCapsule">
            <div className="section full mt-1">
                <div className="border-0 ps-2 pe-2">
                    <form className="search-form">
                        <div className="input-group form-group boxed p-0">
                            <input type="text" className="form-control" placeholder="Search Image" aria-label="Recipient's username" aria-describedby="button-addon2" value="query" style={{height: '34px'}} />
                            <button className="btn h-custom btn-secondary" type="submit" id="button-addon2" style={{height:'auto !important'}}>
                                <ion-icon name="search-sharp" className="fs-6"></ion-icon>
                            </button>
                        </div>
                    </form>
                </div>
                <ul className="listview image-listview mt-1">
                    <li className="custom-line bg-success">
                        <a href="javascript:void(0)" className="item" onClick={handleConfirmationModal}>
                            <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" className="image" />
                            <div className="in">
                                <div className="text-white">
                                    <header>The English School Panvel</header>
                                    theenglishschoolpanvel.com
                                    <footer className="text-white">105068 <span className="badge badge-secondary"><ion-icon name="earth-outline"></ion-icon>&nbsp;Bigrock</span> <span className="badge badge-secondary"><ion-icon name="mail-outline"></ion-icon>&nbsp;3</span> <span className="badge badge-secondary"><ion-icon name="globe-outline"></ion-icon>&nbsp;DNS</span></footer>
                                </div>
                                <div>
                                    <header className="text-white" style={{fontSize: '11px'}}>20 December 2022</header>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li className="custom-line bg-danger">
                        <a href="javascript:void(0)" className="item" onClick={handleConfirmationModal}>
                            <img src="https://static.webweb.co/data/8300F0341-C95E30C6B-F373EF996-C2BC660D8-566D23C28/resize162479063728.png" alt="image" className="image" />
                            <div className="in">
                                <div className="text-white">
                                    <header>kaizen Lenguistic</header>
                                    kaizenlenguistic.com
                                    <footer className="text-white">105068 <span className="badge badge-secondary"><ion-icon name="earth-outline"></ion-icon>&nbsp;GoDaddy</span> <span className="badge badge-secondary"><ion-icon name="mail-outline"></ion-icon>&nbsp;3</span> <span className="badge badge-secondary"><ion-icon name="globe-outline"></ion-icon>&nbsp;DNS</span></footer>
                                </div>
                                <div>
                                    <header className="text-white" style={{fontSize: '11px'}}>20 September 2022</header>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li className="custom-line bg-warning">
                        <a href="javascript:void(0)" className="item" onClick={handleConfirmationModal}>
                            <img src="https://pps.whatsapp.net/v/t61.24694-24/156060739_480167710743227_5090290181363283625_n.jpg?ccb=11-4&oh=01_AdSRqWYHSuLjuQtJnHaW61qDzEjSbifVq-1cNffaetDdpA&oe=6391DA05" alt="image" className="image" />
                            <div className="in">
                                <div className="text-white">
                                    <header>MGM Hospital Belapur</header>
                                    mgmhospitalcbd.com
                                    <footer className="text-white">105068 <span className="badge badge-secondary"><ion-icon name="earth-outline"></ion-icon>&nbsp;Hostinger</span> <span className="badge badge-secondary"><ion-icon name="mail-outline"></ion-icon>&nbsp;3</span> <span className="badge badge-secondary"><ion-icon name="globe-outline"></ion-icon>&nbsp;DNS</span></footer>
                                </div>
                                <div>
                                    <header className="text-white" style={{fontSize: '11px'}}>20 January 2022</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="fab-button text bottom-center" style={{left: '60%'}}>
                <a href="!#" className="fab">
                    <ion-icon name="add-outline"></ion-icon>
                    Add New
                </a>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {
    }
  }
  
  const mapDispatchToProps = {
    showConfirmationModal
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Websites)