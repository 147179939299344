import React from 'react';
import CARD_DFL1 from './CARD_DFL1';
import CARD_DFL2 from './CARD_DFL2';
import CARD_DFL3 from './CARD_DFL3';
import CARD_DFL4 from './CARD_DFL4';
import CARD_DFL5 from './CARD_DFL5';
import CARD_DFL6 from './CARD_DFL6';
import CARD_DFL7 from './CARD_DFL7';
import CARD_DFL8 from './CARD_DFL8';
import CARD_DFL9 from './CARD_DFL9';
import CARD_DFL10 from './CARD_DFL10';
import CARD_DFL11 from './CARD_DFL11';
import CARD_DFL12 from './CARD_DFL12';
import ItemToolbar from '../../bitComponents/ItemToolbar';

function CardSelector({
    cardType,
    item,
    handleNewEditItem,
    handleDeleteContent,
    keys,
    blockID
}) {


    const cardList = ['CARD_DFL1', 'CARD_DFL2', 'CARD_DFL3', 'CARD_DFL4', 'CARD_DFL5', 'CARD_DFL6', 'CARD_DFL7', 'CARD_DFL8', 'CARD_DFL9', 'CARD_DFL10', 'CARD_DFL11', 'CARD_DFL12']
    return (
        <>
            <ItemToolbar handleNewEditItem={handleNewEditItem} handleDeleteContent={handleDeleteContent} keys={keys} blockID={blockID} />
            {
                (cardList.includes(cardType) && cardType === 'CARD_DFL1' || !cardList.includes(cardType)) &&
                <CARD_DFL1 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
            {
                cardType === 'CARD_DFL2' &&
                <CARD_DFL2 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
            {
                cardType === 'CARD_DFL3' &&
                <CARD_DFL3 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
            {
                cardType === 'CARD_DFL4' &&
                <CARD_DFL4 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
            {
                cardType === 'CARD_DFL5' &&
                <CARD_DFL5 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
            {
                cardType === 'CARD_DFL6' &&
                <CARD_DFL6 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
            {
                cardType === 'CARD_DFL7' &&
                <CARD_DFL7 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
            {
                cardType === 'CARD_DFL8' &&
                <CARD_DFL8 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
            {
                cardType === 'CARD_DFL9' &&
                <CARD_DFL9 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
            {
                cardType === 'CARD_DFL10' &&
                <CARD_DFL10 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
            {
                cardType === 'CARD_DFL11' &&
                <CARD_DFL11 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
            {
                cardType === 'CARD_DFL12' &&
                <CARD_DFL12 item={item} handleNewEditItem={handleNewEditItem} keys={keys} />
            }
        </>
    )
}

export default CardSelector