import React from 'react'
import { useLocation } from 'react-router-dom'

function Notifications(props) {
    const search = useLocation().search
    const ID = new URLSearchParams(search).get('id')
    console.log(ID)
    return (
        <div id="appCapsule">
            <div className="listview-title mt-2">Notifications</div>
            <ul className="listview image-listview media search-result mb-2">
                <li>
                    <a href="#!" className="item" data-bs-toggle="modal" data-component="stories"
                            data-bs-target="#StorywithButton" data-time="3000">
                        <div className="imageWrapper">
                            <img src="assets/img/sample/photo/4.jpg" alt="Avatar" className="imaged w64" />
                        </div>
                        <div className="in">
                            <div>
                                <h4 className="mb-05 fw-bolder">Heading over Title 1</h4>
                                <div className="text-muted fw-bolder">
                                    This is example of search result
                                    <div className="mt-05"><strong className="fw-bolder">www.google.com</strong></div>
                                </div>
                            </div>
                            <div>
                                <span className="badge badge-empty badge-info"></span>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#!" className="item" data-bs-toggle="modal" data-component="stories"
                            data-bs-target="#StorywithButton" data-time="3000">
                        <div className="imageWrapper">
                            <img src="assets/img/sample/photo/6.jpg" alt="Avatar" className="imaged w64" />
                        </div>
                        <div className="in">
                            <div>
                                <h4 className="mb-05">Heading over Title 2</h4>
                                <div className="text-muted">
                                    This is example of search result
                                    <div className="mt-05"><strong>www.themeforest.com</strong></div>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#!" className="item" data-bs-toggle="modal" data-component="stories"
                            data-bs-target="#StorywithButton" data-time="3000">
                        <div className="imageWrapper">
                            <img src="assets/img/sample/photo/5.jpg" alt="Avatar" className="imaged w64" />
                        </div>
                        <div className="in">
                            <div>
                                <h4 className="mb-05">Heading over Title 3</h4>
                                <div className="text-muted">
                                    This is example of search result
                                    <div className="mt-05"><strong>www.envato.com</strong></div>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Notifications
