import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideDeleteModal, hideOffcanvas } from '../../../features/modal/offcanvasSlice'
import { setLoading, setWebsiteData } from '../../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'

const DeleteDialogueBox = ({
    show,
    deleteMessage,
    triggerMessage,
    deleteID,
    deleteItemID,
    hideDeleteModal,
    setWebsiteData,
    showAppToast,
    hideOffcanvas,
    blockID,
    CompleteJSON
}) => {
    // console.log(deleteID)
    const [preloader, setPreloader] = useState(false)

    let navigate = useNavigate()
    const search = useLocation().search
    const url = new URLSearchParams(search).get('url')

    const fetchWebsiteJSON = () => {
      const webDoc = CompleteJSON; // localStorage.getItem('website')
      const res = webDoc // (webDoc) ? JSON.parse(atob(webDoc)) : false
      if (res) {
        return res
      } else {
        return false
      }
    }
    const websiteJson = fetchWebsiteJSON()

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    let selectedDomain = tokenData.SelectedDomain.Domain

    const deleteData = () => {
        setPreloader(true)
        setLoading(true);
        console.log(triggerMessage);
        if (triggerMessage === 'page') {
            if(tokenData.userInfo.RoleID === 'Moderator') {
                selectedDomain = tokenData.SelectedDomain.ID
                deleteID = localStorage.getItem('selectedPageID')
                // console.log(selectedDomain, deleteID)
            }
            axios.delete('/page/' + selectedDomain + '/' + deleteID).then(res=>{
                if (res.data.message === 'Success') {
                    // localStorage.setItem('website',btoa(unescape(encodeURIComponent(JSON.stringify(res.data.data)))))
                    // navigate("/manage?url=" + res.data.data.appearance.navigation.header)
                    setWebsiteData(res.data.data)
                    hideDeleteModal()
                    hideOffcanvas()
                    showAppToast({ toastMessage: 'SUCCESSFULLY DELETED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                    setPreloader(false)
                } else {
                    // console.log('FAIL DELETE PAGE CALL')
                    hideDeleteModal()
                    hideOffcanvas()
                    showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                    setPreloader(false)
                }
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
            })
        }
        else if (triggerMessage === 'block') {
            if(tokenData.userInfo.RoleID != 'Moderator') {
                axios.delete('/block/' + selectedDomain + '/' + deleteID).then(res=>{
                    if (res.data.message === 'Success') {
                        // localStorage.setItem('website',btoa(unescape(encodeURIComponent(JSON.stringify(res.data.data)))))
                        setWebsiteData(res.data.data)
                        hideDeleteModal()
                        hideOffcanvas()
                        showAppToast({ toastMessage: 'SUCCESSFULLY DELETED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                    } else {
                        hideDeleteModal()
                        hideOffcanvas()
                        showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                    }
                    setLoading(false);
                }).catch((error) => {
                    setLoading(false);
                    console.error(error);
                })
            } else {
                const selectedCategoryID = tokenData.SelectedDomain.ID
                const categoryPageID = localStorage.getItem('selectedPageID')
                axios.delete('/block/' + selectedCategoryID + '/' + categoryPageID + '/' + deleteID).then(res=>{
                    // console.log(res)
                    if (res.data.message === 'Success') {
                        // localStorage.setItem('website',btoa(unescape(encodeURIComponent(JSON.stringify(res.data.data)))))
                        setWebsiteData(res.data.data)
                        hideDeleteModal()
                        hideOffcanvas()
                        showAppToast({ toastMessage: 'SUCCESSFULLY DELETED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                    } else {
                        hideDeleteModal()
                        hideOffcanvas()
                        showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                    }
                })
            }
        }
        else if (triggerMessage === 'content') {
            if(tokenData.userInfo.RoleID != 'Moderator') {
                axios.delete('/content/' + selectedDomain + '/' + deleteID + '/' + deleteItemID).then(res=>{
                    if (res.data.message === 'Success') {
                        // localStorage.setItem('website',btoa(unescape(encodeURIComponent(JSON.stringify(res.data.data)))))
                        setWebsiteData(res.data.data)
                        hideDeleteModal()
                        hideOffcanvas()
                        showAppToast({ toastMessage: 'SUCCESSFULLY DELETED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                    } else {
                        hideDeleteModal()
                        hideOffcanvas()
                        showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                    }
                    setLoading(false);
                }).catch((error) => {
                    console.error(error);
                    setLoading(false);
                })
            } else {
                const selectedCategoryID = tokenData.SelectedDomain.ID
                const categoryPageID = localStorage.getItem('selectedPageID')
                axios.delete('/content/' + selectedCategoryID + '/' + categoryPageID + '/' + deleteID + '/' + deleteItemID).then(res=>{
                    if (res.data.message === 'Success') {
                        // localStorage.setItem('website',btoa(unescape(encodeURIComponent(JSON.stringify(res.data.data)))))
                        setWebsiteData(res.data.data)
                        hideDeleteModal()
                        hideOffcanvas()
                        showAppToast({ toastMessage: 'SUCCESSFULLY DELETED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                    } else {
                        hideDeleteModal()
                        hideOffcanvas()
                        showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                    }
                })
            }
        }
        else if (triggerMessage === 'smartContent') {
            let data = []
            deleteID.map((value, key) => {
                if (key !== deleteItemID) {
                    data.push(value)
                }
            })
            axios.put('/block/' + selectedDomain + '/' + blockID + '/items', data).then(res=>{
                if (res.data.message === 'Success') {
                    setWebsiteData(res.data.data)
                    showAppToast({ toastMessage: 'Updated Successfully', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                    hideOffcanvas()
                    setPreloader(false)
                } else {
                    showAppToast({ toastMessage: 'FAIL ', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                    hideOffcanvas()
                    setPreloader(false)
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.error(err);
            })
        }
        else if (triggerMessage === 'hidePage') {
            alert('Successfully hide page')
            setLoading(false);
        } else if (triggerMessage === 'domainSetting') {
            setLoading(false);
        }
        else if (triggerMessage === 'form') {
            if(tokenData.userInfo.RoleID != 'Moderator') {
                axios.delete('/form/' + selectedDomain + '/' + deleteID).then(res=>{
                    if (res.data.message === 'Success') {
                        // localStorage.setItem('website',btoa(unescape(encodeURIComponent(JSON.stringify(res.data.data)))))
                        setWebsiteData(res.data.data)
                        hideDeleteModal()
                        hideOffcanvas()
                        showAppToast({ toastMessage: 'SUCCESSFULLY DELETED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                    } else {
                        hideDeleteModal()
                        hideOffcanvas()
                        showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                    }
                    setLoading(false);
                }).catch((error) => {
                    console.error(error);
                    setLoading(false);
                })
            } else {
                setLoading(false);
            }
        }
    }
    
    return (
        <Modal show={show} className="dialogbox" id="DeleteDialogueBox" data-bs-backdrop="static" tabindex="-1"
            role="dialog" centered>
            {/* <Modal.Dialog className="modal-dialog" role="document"> */}
                <Modal.Header className="modal-header">
                    <h3 className="modal-title">
                        {triggerMessage !== 'domainSetting' &&
                            <>
                                DELETE
                            </>
                        }
                        {triggerMessage === 'domainSetting' &&
                            <>
                                CONFIRM
                            </>
                        }
                    </h3>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {deleteMessage}
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <div className="btn-inline">
                        <span className="btn btn-text-secondary" onClick={hideDeleteModal}>
                            <ion-icon name="close-outline"></ion-icon>
                            CANCEL
                        </span>
                        {!preloader &&
                            <span className="btn btn-text-danger" onClick={() => {deleteData()}}>
                                {triggerMessage !== 'domainSetting' &&
                                    <>
                                        <ion-icon name="trash-outline"></ion-icon>
                                        DELETE
                                    </>
                                }
                                {triggerMessage === 'domainSetting' &&
                                    <>
                                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                                        CONFIRM
                                    </>
                                }
                            </span>
                        }
                        {preloader &&
                            <button className="btn text-danger" type="button" disabled>
                                <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                Deleteing...
                            </button>
                        }
                    </div>
                </Modal.Footer>
            {/* </Modal.Dialog> */}
        </Modal>
    )
}

const mapStateToProps = ({ offcanvas, appToast, websiteData }) => {
    const {
        isVisible,
        toastMessage,
        background,
        icon,
        position
    } = appToast
    const {
        CompleteJSON
    } = websiteData
    const {
        deleteItemID,
        blockID
    } = offcanvas
    return {
        appToastVisible: isVisible,
        appToastMessage: toastMessage,
        appToastBackground: background,
        appToastIcon: icon,
        appToastPosition: position,
        deleteItemID,
        blockID,
        CompleteJSON
    }
  }
  
  const mapDispatchToProps = {
    hideDeleteModal,
    setWebsiteData,
    showAppToast,
    hideOffcanvas
  }

export default connect(mapStateToProps,mapDispatchToProps)(DeleteDialogueBox)
