import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import MDEditor from '@uiw/react-md-editor'
import SampleImage from '../../images/avatar3.jpg'
import { cssConverter } from '../../../features/websiteData/websiteDataSlice'
import CardImgSlider from '../../bitComponents/CardImgSlider'

function CARD_DFL4({ item, handleNewEditItem, keys }) {
    return (
        <div className="card position-relative h-100" style={cssConverter(item.itemCss, {backgroundImage: item && item.itemBackground && item.itemBackground.image && `url${item.itemBackground.image}`, backgroundColor: item && item.itemBackground && item.itemBackground.color && `${item.itemBackground.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'})}>
                {
                    item.image.length > 0 &&
                    <div style={{'maxWidth': '70%', 'minWidth': '200px', 'display': 'block', 'margin': 'auto'}}>
                            <>
                            {
                                item.image.length === 1 ?
                                <img src={item.image[0].src.length > 0 ? item.image[0].src : SampleImage} className="card-img-top" alt="image" />
                                :
                                <CardImgSlider item={item} />
                            }
                            </>
                    </div>
                }
            <div className="card-body">
                <h5 className="card-title cursor-pointer" onClick={() => { handleNewEditItem(keys) }} dangerouslySetInnerHTML={{ __html: item.title }} style={cssConverter(item.titleCss)} />
                <span onClick={() => { handleNewEditItem(keys) }}><MDEditor.Markdown source={item.subtitle} linkTarget="_blank" className="card-text cursor-pointer bg-transparent" style={cssConverter(item.subtitleCss)} /></span>
                <span onClick={() => { handleNewEditItem(keys) }}><MDEditor.Markdown source={item.description} linkTarget="_blank" className="card-text cursor-pointer bg-transparent" onClick={() => { handleNewEditItem(keys) }} style={cssConverter(item.descriptionCss)} /></span>
                <div className="mt-2">
                    {item.button.map((value, key) => {
                        return <button className={`btn btn-sm me-1 ${value.style && value.style !== '' ? value.style : 'btn-primary'}`} key={key} onClick={() => handleNewEditItem(keys)} style={cssConverter(item.buttonCss)}>{value.title}</button>
                    })}
                </div>
            </div>
        </div>
    )
}

export default CARD_DFL4