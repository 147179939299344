import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import MDEditor from '@uiw/react-md-editor'
import { connect } from 'react-redux'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'
import { hidePopupBlockOffcanvas, showOffcanvas, showButtonModal, newButtonData, showDeleteModal, setNewBlockType, showUpdateDataOffcanvas } from '../../features/modal/offcanvasSlice'

const SM_POUP_DFL1 = ({
    properties,
    items,
    blockType,
    blockID,
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal,
    isPopupBlockVisible,
    hidePopupBlockOffcanvas,
    setNewBlockType,
    showUpdateDataOffcanvas
}) => {
    const handleBlockUpdate = (msg) => {
        console.log('CLICKED')
        hidePopupBlockOffcanvas()
        showOffcanvas({ isVisible: true, items: items, itemCount: 'null', properties: properties, blockType: blockType, blockID: blockID, msg: msg })
    }

    function handleShowButtonModal(buttonData, ID) {
        console.log(buttonData)
        handleBlockUpdate(ID)
        setTimeout(() => {
            showButtonModal({
                isButtonModalVisible: true,
                buttonData: buttonData,
                buttonID: ID
            })
        }, [1000])
        newButtonData({ buttonDataObj: buttonData })
    }

    const handleNewEditItem = (msg) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': 0 });
        showUpdateDataOffcanvas({
            isEditVisible: true,
            //   itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties,
            blockType: blockType,
            blockID: blockID,
            msg: msg
        })
        hidePopupBlockOffcanvas();
    }

    const handleImageUpdate = () => {
        showOffcanvas({ isVisible: true, items: items, properties: properties, blockType: 'SM', blockID: blockID, msg: '' })
    }

    const handleDeleteBlock = (blockName) => {
        showDeleteModal({ isDeleteVisible: true, deleteMessage: 'Are your sure? You want to delete this block?', triggerMessage: 'block', deleteID: blockName })
    }
    return (
        <Modal show={isPopupBlockVisible}>
            <div className="row ps-3 pe-3 pt-2">
                <div className="col-6 col-md-6">
                    <Modal.Title className="h5 mb-0">
                        {properties.blockHeading !== '' &&
                            <div style={cssConverter(properties.blockHeadingCss)} dangerouslySetInnerHTML={{ __html: properties.blockHeading}} />
                        }
                    </Modal.Title>
                </div>
                <div className="col-6 col-md-6 text-end" onClick={() => { hidePopupBlockOffcanvas() }}>
                    <ion-icon name="close-outline" className="fs-3" style={{ cursor: 'pointer' }} ></ion-icon>
                </div>
            </div>
            <Modal.Body>
                <div className="section wide-block pt-3 position-relative rounded" style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                    {(properties.blockDescription !== '') &&
                        <div className="row">
                            {properties.blockDescription !== '' &&
                                <div className="col-12">
                                    <p style={cssConverter(properties.blockDescriptionCss)} className="text-center" dangerouslySetInnerHTML={{ __html: properties.blockDescription}} />
                                </div>
                            }
                        </div>
                    }
                    <div className="w-100 text-center">
                        {items[0].image !== null && items[0].image.length > 0 &&
                            <>
                                <img src={items[0].image[0].src} alt={items[0].image[0].alt} className="imaged w-100 cursor-pointer" onClick={handleNewEditItem} style={cssConverter(properties.imageCss)} />
                            </>
                        }
                    </div>
                    <div className="pt-2 pb-1 ps-2 pe-2" style={{ backgroundColor: properties.backgroundValue }}>
                        <h3 className='cursor-pointer' onClick={handleNewEditItem} style={cssConverter(properties.titleCss)}>{items[0].title}</h3>
                        <p style={{ fontSize: '14px' }} onClick={handleNewEditItem}>
                            <MDEditor.Markdown source={items[0].subtitle} linkTarget="_blank" className='cursor-pointer bg-transparent' style={cssConverter(properties.subtitleCss)} /><br />
                            <MDEditor.Markdown source={items[0].description} linkTarget="_blank" className='cursor-pointer bg-transparent' style={cssConverter(properties.descriptionCss)} />
                        </p>
                        <p>
                            {items[0].button !== null && items[0].button.map((item, key) => {
                                return <button className="btn btn-primary me-1" onClick={handleNewEditItem}  style={cssConverter(properties.buttonCss)}>{item.title}</button>
                            })}
                        </p>
                    </div>
                    <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', display: 'flex' }}>
                        {/* <div className="col-4 col-md-4 text-end">
                            <span className="badge bg-secondary p-1 editBtnCss" onClick={() => { handleBlockUpdate() }}>Edit</span>
                        </div> */}
                        <div className="col-6 col-md-6 text-end">
                            <span className="badge bg-success p-1 editBtnCss" onClick={handleNewEditItem}>Edit</span>
                        </div>
                        <div className="col-6 col-md-6">
                            <span className="badge bg-danger p-1 editBtnCss" onClick={() => { handleDeleteBlock(blockID) }}>Delete</span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="secondary">
                    Close
                </Button>
                <Button variant="primary">
                    Save Changes
                </Button>
            </Modal.Footer> */}
        </Modal>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        isPopupBlockVisible
    } = offcanvas
    return {
        isPopupBlockVisible
    }
}

const mapDispatchToProps = {
    hidePopupBlockOffcanvas,
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal,
    setNewBlockType,
    showUpdateDataOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(SM_POUP_DFL1)