import React, { useState, useEffect } from 'react';

const WebwebColorPicker = ({ color, onChangedColor, size }) => {
    const [colors, setColors] = useState('#111111');
    const [transparency, setTransparency] = useState(10);

    const TransRange = (trans) => {
        const firstValue = trans.slice(0, 1);
        let one = '10';
        if (Number(firstValue) === 0 || Number(firstValue) === 1) {
            one = '0';
        } else if (Number(firstValue) === 2 || Number(firstValue) === 3) {
            one = '1';
        } else if (Number(firstValue) === 4 || Number(firstValue) === 5) {
            one = '2';
        } else if (Number(firstValue) === 6 || Number(firstValue) === 7) {
            one = '3';
        } else if (Number(firstValue) === 8 || Number(firstValue) === 9) {
            one = '4';
        } else if (firstValue === 'a') {
            one = '5';
        } else if (firstValue === 'b') {
            one = '6';
        } else if (firstValue === 'c') {
            one = '7';
        } else if (firstValue === 'd') {
            one = '8';
        } else if (firstValue === 'e') {
            one = '9';
        } else {
            one = '10';
        }
        // console.log({firstValue, one});
        return one;
    }

    const NumToTransRange = (num) => {
        let nums = '';
        if (num === 0) {
            nums = '00';
        } else if (num === 1) {
            nums = '20';
        } else if (num === 2) {
            nums = '40';
        } else if (num === 3) {
            nums = '60';
        } else if (num === 4) {
            nums = '80';
        } else if (num === 5) {
            nums = 'a0';
        } else if (num === 6) {
            nums = 'b0';
        } else if (num === 7) {
            nums = 'c0';
        } else if (num === 8) {
            nums = 'd0';
        } else if (num === 9) {
            nums = 'e0';
        } else {
            nums = '';
        }
        // console.log({num, nums});
        return nums
    }

    const checkAndR = (clr) => {
        let string = clr;
        if(clr.charAt(0).length === 0){
            string = `#`;
        }
        if(clr.charAt(1).length === 0){
            string = `${string}0`;
        }
        if(clr.charAt(2).length === 0){
            string = `${string}0`;
        }
        if(clr.charAt(3).length === 0){
            string = `${string}0`;
        }
        if(clr.charAt(4).length === 0){
            string = `${string}0`;
        }
        if(clr.charAt(5).length === 0){
            string = `${string}0`;
        }
        if(clr.charAt(6).length === 0){
            string = `${string}0`;
        }
        return string;
    }
    
    useEffect(() => {
        if (color !== undefined && color !== false && color.length > 0) {
            setColors(checkAndR(color.slice(0, 7)));
            if (color.length > 7) {
                const t1 = TransRange(color.slice(7, 9));
                setTransparency(Number(t1));
            } else {
                setTransparency(10);
            }
        }
    }, [color])

    const ChangedColor = (e) => {
        onChangedColor(`${e.target.value}${NumToTransRange(Number(transparency))}`);
    }

    const onbluchChangedColor = (e) => {
        onChangedColor(`${checkAndR(e.target.value)}${NumToTransRange(Number(transparency))}`);
    }

    const ChangeTransparency = (e) => {
        onChangedColor(`${colors}${NumToTransRange(Number(e.target.value))}`);
    }

    // useEffect(() => {
    //   console.log({transparency, colors, color});
    // }, [transparency, colors, color]);
    

    return (
        <div className="row">
            <div className={size === 'xl' ? "col-2 col-md-2" : "col-4 col-md-4"}>
                <input type="color" className="form-control border-0 p-0" value={colors} onChange={e => ChangedColor(e)} id="fontColor" style={{ height: '27px', width: '27px', opacity: transparency/10 }} />
            </div>
            <div className="col-8 col-md-8" style={{ position: 'relative' }}>
                {/* <label className="form-label mb-2" htmlFor="fontColor" style={{ position: 'absolute', width: '100%', height: '100%', opacity: 0 }}>Font Color</label> */}
                <input type="text" className="form-control border-0 p-0 bg-transparent fs-12" value={colors} pattern="#[0-9a-fA-F]{6}" onBlur={(e) => onbluchChangedColor(e)} onChange={e => ChangedColor(e)} style={{ height: '31px' }} />
            </div>
            <div className="col-12 col-md-12">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="transparency" style={{ fontSize: '10px' }}>Transparency</label>
                </div>
                <input type="range" className="form-control form-range border-0 p-0 fs-12" value={transparency} onChange={(e) => ChangeTransparency(e)} min="0" max="10" id="fontSize" style={{ height: '17px' }}></input>
            </div>
        </div>
    )
}

export default WebwebColorPicker
