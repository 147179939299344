import React from 'react'
import { useLocation } from 'react-router-dom'

function RecPayment(props) {
    const search = useLocation().search
    const ID = new URLSearchParams(search).get('id')
    console.log(ID)
    return (
        <div id="appCapsule">
            <div className="section mt-2">
                <div className="card">
                    <div className="card-header fs-5">
                        <div className="row">
                            <div className="col-8 col-md-8">
                                Payment Details
                            </div>
                            <div className="col-4 col-md-4 text-end">
                                <span className="badge badge-secondary">
                                    <ion-icon name="create-outline"></ion-icon>&nbsp;Edit
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pb-0">
                        <div className="row">
                            <div className="col-md-4 text-center">
                                <img src="assets/img/sample/photo/1.jpg" alt="QR" className="w-50 mb-1" />
                                <p className="fs-small"><span className="text-light">ID</span> 123456fgh56hdfd</p>
                            </div>
                            <div className="col-md-8 mb-2">
                                <p className="fs-small mb-1"><span className="text-light">Account Holder Name</span> Hussain Kothari</p>
                                <p className="fs-small mb-1"><span className="text-light">Account Number</span> 1234567890</p>
                                <p className="fs-small mb-1"><span className="text-light">IFSC Code</span> IND1234567890</p>
                                <p className="fs-small mb-1"><span className="text-light">Bank Name</span> State Bank of India</p>
                                <p className="fs-small mb-1"><span className="text-light">Branch Name</span> Panvel</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section full mt-2">
                <div className="wide-block">
                    <ul className="nav nav-tabs capsuled" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link" href="reqPayment" role="tab">
                                Requested
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#!" role="tab">
                                Received
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="section full mt-2">
                <div className="wide-block">
                    <div className="timeline timed">
                        <div className="item">
                            <span className="time">11:00 AM</span>
                            <div className="dot"></div>
                            <div className="content">
                                <h4 className="title">Call Amanda</h4>
                                <div className="text">Talk about the project</div>
                            </div>
                        </div>
                        <div className="item">
                            <span className="time">1:30 PM</span>
                            <div className="dot bg-danger"></div>
                            <div className="content">
                                <h4 className="title">Meet up</h4>
                                <div className="text">
                                    <img src="assets/img/sample/avatar/avatar3.jpg" alt="avatar" className="imaged w24 rounded" />
                                    <img src="assets/img/sample/avatar/avatar4.jpg" alt="avatar" className="imaged w24 rounded" />
                                    <img src="assets/img/sample/avatar/avatar5.jpg" alt="avatar" className="imaged w24 rounded" />
                                    <img src="assets/img/sample/avatar/avatar7.jpg" alt="avatar" className="imaged w24 rounded" />
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <span className="time">04:40 PM</span>
                            <div className="dot bg-warning"></div>
                            <div className="content">
                                <h4 className="title">Party Night</h4>
                                <div className="text">Get a ticket for party at tonight 9:00 PM</div>
                            </div>
                        </div>
                        <div className="item">
                            <span className="time">06:00 PM</span>
                            <div className="dot bg-info"></div>
                            <div className="content">
                                <h4 className="title">New Release</h4>
                                <div className="text">Export the version 2.3</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecPayment
