import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'

function Home(props) {
    let navigate = useNavigate()
    const manageDns = () => {
        navigate('/dns?type=dns')
    }
    return (
        <div id="appCapsule">

            <div className="header-large-title">
                <div className="row mt-1">
                    <div className="col-6 col-md-6">
                        <h1 className="title m-0">Emails</h1>
                    </div>
                    {/* <div className="col-6 col-md-6 d-flex justify-content-end align-items-center">
                        <button className="btn btn-secondary btn-sm">Create Email</button>
                    </div> */}
                </div>
            </div>

            <div className="section full mt-3 mb-3">
                <Splide
                    options={{
                        type: 'slide',
                        perPage: 4,
                        focus: 1,
                        rewind: true,
                        gap: 8,
                        padding: 16,
                        arrows: false,
                        pagination: false,
                        slideFocus: true,
                        fixWidth: 95,
                        breakpoints: {
                        200: { perPage: 1 },
                        411: { perPage: 2 },
                        768: { perPage: 2 },
                        991: { perPage: 4 }
                        }
                    }}
                >
                    <SplideSlide>
                        <div className="card">
                            <div className="rounded-top d-flex justify-content-center align-items-center flex-column" style={{height: '170px', width: 'auto', backgroundImage: 'url(assets/img/gradiant.png)', backgroundSize: 'cover'}}>
                                <h4 className="mb-0 text-light text-center" style={{textOverflow: 'ellipsis',overflow: 'hidden',whiteSpace: 'nowrap',width: '100%'}}>hussain.kothari@skyq.tech</h4>
                                <h6 className="text-light">52/100 MB</h6>
                            </div>
                            <div className="card-body p-2">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-secondary btn-block btn-sm">Edit</button>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-danger btn-block btn-sm">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="card">
                            <div className="rounded-top d-flex justify-content-center align-items-center flex-column" style={{height: '170px', width: 'auto', backgroundImage: 'url(assets/img/gradiant.png)', backgroundSize: 'cover'}}>
                                <h4 className="mb-0 text-light text-center" style={{textOverflow: 'ellipsis',overflow: 'hidden',whiteSpace: 'nowrap',width: '100%'}}>hussainkothari786.hk@gmail.com</h4>
                                <h6 className="text-light">1024/5120 MB</h6>
                            </div>
                            <div className="card-body p-2">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-secondary btn-block btn-sm">Edit</button>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-danger btn-block btn-sm">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="card">
                            <div className="rounded-top d-flex justify-content-center align-items-center flex-column" style={{height: '170px', width: 'auto', backgroundImage: 'url(assets/img/gradiant.png)', backgroundSize: 'cover'}}>
                                <h4 className="mb-0 text-light text-center" style={{textOverflow: 'ellipsis',overflow: 'hidden',whiteSpace: 'nowrap',width: '100%'}}>nikhil.jadhav@skqy.in</h4>
                                <h6 className="text-light">52/1024 MB</h6>
                            </div>
                            <div className="card-body p-2">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-secondary btn-block btn-sm">Edit</button>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-danger btn-block btn-sm">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="card">
                            <div className="rounded-top d-flex justify-content-center align-items-center flex-column" style={{height: '170px', width: 'auto', backgroundImage: 'url(assets/img/gradiant.png)', backgroundSize: 'cover'}}>
                                <h4 className="mb-0 text-light text-center" style={{textOverflow: 'ellipsis',overflow: 'hidden',whiteSpace: 'nowrap',width: '100%'}}>amruta.patankar@skyq.tech</h4>
                                <h6 className="text-light">1000/1024 MB</h6>
                            </div>
                            <div className="card-body p-2">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-secondary btn-block btn-sm">Edit</button>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-danger btn-block btn-sm">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                </Splide>
            </div>

            <div className="header-large-title">
                <div className="row mt-1">
                    <div className="col-6 col-md-6">
                        <h1 className="title m-0">Forms</h1>
                    </div>
                    {/* <div className="col-6 col-md-6 d-flex justify-content-end align-items-center">
                        <button className="btn btn-secondary btn-sm">Create Email</button>
                    </div> */}
                </div>
            </div>

            <div className="section full mt-3 mb-3">
                <Splide
                    options={{
                        type: 'slide',
                        perPage: 4,
                        focus: 1,
                        rewind: true,
                        gap: 8,
                        padding: 16,
                        arrows: false,
                        pagination: false,
                        slideFocus: true,
                        fixWidth: 95,
                        breakpoints: {
                        200: { perPage: 1 },
                        411: { perPage: 2 },
                        768: { perPage: 2 },
                        991: { perPage: 4 }
                        }
                    }}
                >
                    <SplideSlide>
                        <div className="card">
                            <div className="rounded-top d-flex justify-content-center align-items-center flex-column" style={{height: '170px', width: 'auto', backgroundImage: 'url(assets/img/gradiant.png)', backgroundSize: 'cover'}}>
                                <h1 className="mb-0 text-light" style={{fontSize: '48px'}}>16</h1>
                                <h4 className="text-light">Response</h4>
                                <h6 className="text-light">FEEDBACK</h6>
                            </div>
                            <div className="card-body p-2">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-secondary btn-block btn-sm">Edit</button>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-danger btn-block btn-sm">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="card">
                            <div className="rounded-top d-flex justify-content-center align-items-center flex-column" style={{height: '170px', width: 'auto', backgroundImage: 'url(assets/img/gradiant.png)', backgroundSize: 'cover'}}>
                                <h1 className="mb-0 text-light" style={{fontSize: '48px'}}>01</h1>
                                <h4 className="text-light">Response</h4>
                                <h6 className="text-light">CONTACT</h6>
                            </div>
                            <div className="card-body p-2">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-secondary btn-block btn-sm">Edit</button>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-danger btn-block btn-sm">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="card">
                            <div className="rounded-top d-flex justify-content-center align-items-center flex-column" style={{height: '170px', width: 'auto', backgroundImage: 'url(assets/img/gradiant.png)', backgroundSize: 'cover'}}>
                                <h1 className="mb-0 text-light" style={{fontSize: '48px'}}>52</h1>
                                <h4 className="text-light">Response</h4>
                                <h6 className="text-light">SUBMISSIONS</h6>
                            </div>
                            <div className="card-body p-2">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-secondary btn-block btn-sm">Edit</button>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-danger btn-block btn-sm">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="card">
                            <div className="rounded-top d-flex justify-content-center align-items-center flex-column" style={{height: '170px', width: 'auto', backgroundImage: 'url(assets/img/gradiant.png)', backgroundSize: 'cover'}}>
                                <h1 className="mb-0 text-light" style={{fontSize: '48px'}}>00</h1>
                                <h4 className="text-light">Response</h4>
                                <h6 className="text-light">RECRUITMENT</h6>
                            </div>
                            <div className="card-body p-2">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-secondary btn-block btn-sm">Edit</button>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <button className="btn btn-danger btn-block btn-sm">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                </Splide>
            </div>
        </div>
    )
}

export default Home
