import React, { useState, useEffect } from 'react'
import RadioButtonsSelect from './RadioButtonsSelect';
import WebwebColorPicker from './WebwebColorPicker'

const ManageProperties = ({
    id,
    isEditVisible,
    propsObject,
    fontPreivew,
    ImagePreivew,
    optionsList,
    onChangeProps,
    className
}) => {
    // console.log(propsObject);
    const [propsBackupState, setPropsBackupState] = useState(null);
    const [propsState, setPropsState] = useState(null);

    const [fontsColor, setFontsColor] = useState('#111111');
    const [fontSizeRange, setFontSizeRange] = useState(16);

    const [bordercolor, setBordercolor] = useState('#111111');
    const [borderwidth, setBorderwidth] = useState(0);
    const [borderStyles, setBorderStyle] = useState('none');
    const [textAlignment, setTextAlignment] = useState('');
    const [textTransform, setTextTransform] = useState('');

    const [textShadowBtn, setTextShodowBtn] = useState('off');
    const [textShadow, setTextShodow] = useState('#111111');

    const [checkPxPercentage, setCheckPxPercentage] = useState(false);

    const [marginValue, setMarginValue] = useState('0px 0px 0px 0px');
    const [paddingValue, setPaddingValue] = useState('0px 0px 0px 0px');

    const getpropsState = () => {
        if (typeof (propsState) === 'string') {
            return JSON.parse(propsState);
        } else {
            return JSON.parse(JSON.stringify(propsState))
        }
    };


    useEffect(() => {
        if (isEditVisible && propsObject !== undefined && propsObject !== false && propsObject !== null) {
            const local = propsObject === '[]' ? {} : JSON.parse(JSON.stringify(propsObject));
            // console.log({local, propsObject});
            setPropsState(local);
            setPropsBackupState(local)
        } else {
            setPropsState(null)
            setPropsBackupState(null)
        }
    }, [isEditVisible, propsObject])

    useEffect(() => {
        // console.log({propsState});
        if (propsState !== null) {
            const propsStateLocal = typeof (propsState) === 'string' ? JSON.parse(propsState) : JSON.parse(JSON.stringify(propsState));
            if (propsStateLocal['color'] !== undefined) setFontsColor(propsStateLocal['color']);
            if (propsStateLocal['font-size'] !== undefined) setFontSizeRange(propsStateLocal['font-size'].slice(0, 2));
            if (propsStateLocal['border-width'] !== undefined) setBorderwidth(propsStateLocal['border-width'].replace('px', ''));
            if (propsStateLocal['border-color'] !== undefined) setBordercolor(propsStateLocal['border-color']);
            if (propsStateLocal['border-style'] !== undefined) setBorderStyle(propsStateLocal['border-style']);
            if (propsStateLocal['text-align'] !== undefined) setTextAlignment(propsStateLocal['text-align']);
            if (propsStateLocal['text-transform'] !== undefined) setTextTransform(propsStateLocal['text-transform']);
            if (propsStateLocal['text-shadow'] !== undefined) {
                setTextShodowBtn('on');
                const local = propsStateLocal['text-shadow'].split(' ');
                setTextShodow(local[3]);
            } else {
                setTextShodowBtn('off');
            };
            if (propsStateLocal['padding'] !== undefined) {
                if (propsStateLocal['padding'].includes('px')) {
                    setCheckPxPercentage(false);
                } else {
                    setCheckPxPercentage(true);
                }
                setPaddingValue(propsStateLocal['padding']);
            }
            if (propsStateLocal['margin'] !== undefined) {
                if (propsStateLocal['margin'].includes('px')) {
                    setCheckPxPercentage(false);
                } else {
                    setCheckPxPercentage(true);
                }
                setMarginValue(propsStateLocal['margin']);
            }
            // if(id === '0') {
            //     // console.log(propsState, propsStateLocal);
            // }
            onChangeProps(propsStateLocal, propsBackupState);
        }
    }, [propsState])

    const updateValue = (key, value, key2, value2) => {
        const local = getpropsState();
        local[key] = value;
        if (key2 !== undefined) local[key2] = value2;
        setPropsState(local);
    }

    const deleteValue = (key, key2, key3) => {
        const local = getpropsState();
        delete local[key];
        if (key2 !== undefined) delete local[key2];
        if (key3 !== undefined) delete local[key3];
        console.log(local);
        setPropsState(local);
    }

    const changeFontSize = (e) => { updateValue('font-size', `${e.target.value}px`, 'line-height', `${e.target.value}px`) }

    const changedFontColor = (colorCode) => { updateValue('color', colorCode) }

    const changedBorderColor = (colorCode) => { updateValue('border-color', colorCode) }

    const onBorderwidthChange = (width) => { updateValue('border-width', `${width.target.value}px`) }

    const changeBorderStyle = (style) => { updateValue('border-style', `${style.target.value}`) }

    const changeTextAlignment = (alignment) => { updateValue('text-align', alignment) }

    const changeTextTransform = (transform) => { updateValue('text-transform', transform) }

    const changeTextShadowShow = (isshadow) => {
        if (isshadow === 'on') {
            setTextShodowBtn('on')
            const local = getpropsState();
            local['text-shadow'] = `2px 3px 2px ${textShadow}`;
            setPropsState(local);
            // console.log(local);
        } else {
            const local = getpropsState();
            if (local['text-shadow'] !== undefined) delete local['text-shadow'];
            setPropsState(local);
            setTextShodowBtn('off');
            // console.log(local);
        }
    }

    const changeTextShadowColor = (color) => {
        if (textShadowBtn === 'on') {
            let colorPara = '2px 3px 2px';
            if (propsState !== null && propsState['text-shadow'] !== undefined) {
                const one = propsState['text-shadow'].split(' ');
                colorPara = `${one[0]} ${one[1]} ${one[2]}`;
            }
            updateValue('text-shadow', `${colorPara} ${color}`);
        }
    }

    const PxPercentageChange = (e) => {
        setCheckPxPercentage(e.target.checked);
        if (e.target.checked) {
            updateValue('margin', marginValue.replace(/px/g, '%'), 'padding', paddingValue.replace(/px/g, '%'));
        } else {
            updateValue('margin', marginValue.replace(/%/g, 'px'), 'padding', paddingValue.replace(/%/g, 'px'));
        }
    }

    const getMPvalues = (marginPadding, locations) => {
        let returnValue = ''
        if (marginPadding === 'margin') {
            let margins = marginValue.includes('px') ? marginValue.replace(/px/g, '') : marginValue.replace(/%/g, '')
            margins = margins.split(' ');
            if (locations === 'Top') {
                returnValue = margins[0]
            } else if (locations === 'Right') {
                returnValue = margins[1]
            } else if (locations === 'Bottom') {
                returnValue = margins[2]
            } else {
                returnValue = margins[3]
            }
        } else {
            let paddings = paddingValue.includes('px') ? paddingValue.replace(/px/g, '') : paddingValue.replace(/%/g, '')
            paddings = paddings.split(' ');
            if (locations === 'Top') {
                returnValue = paddings[0];
            } else if (locations === 'Right') {
                returnValue = paddings[1];
            } else if (locations === 'Bottom') {
                returnValue = paddings[2];
            } else {
                returnValue = paddings[3];
            }
        }
        // console.log({locations, returnValue, marginValue});

        return Number(returnValue);
    }

    const onMarginChange = (e, location) => {
        let changedValue = e.target.value;
        let m = '';
        if (marginValue.includes('px')) {
            m = marginValue.replace(/px/g, '');
        } else {
            m = marginValue.replace(/%/g, '');
        }
        let returnValue = '';
        m = m.split(' ');
        if (location === 0) {
            returnValue = checkPxPercentage ? `${changedValue}% ${m[1]}% ${m[2]}% ${m[3]}%` : `${changedValue}px ${m[1]}px ${m[2]}px ${m[3]}px`;
        } else if (location === 1) {
            returnValue = checkPxPercentage ? `${m[0]}% ${changedValue}% ${m[2]}% ${m[3]}%` : `${m[0]}px ${changedValue}px ${m[2]}px ${m[3]}px`;
        } else if (location === 2) {
            returnValue = checkPxPercentage ? `${m[0]}% ${m[1]}% ${changedValue}% ${m[3]}%` : `${m[0]}px ${m[1]}px ${changedValue}px ${m[3]}px`;
        } else {
            returnValue = checkPxPercentage ? `${m[0]}% ${m[1]}% ${m[2]}% ${changedValue}%` : `${m[0]}px ${m[1]}px ${m[2]}px ${changedValue}px`;
        }
        updateValue('margin', returnValue);
        // return returnValue;
    }

    const onPaddingChange = (e, location) => {
        let changedValue = e.target.value;
        let p = '';
        if (paddingValue.includes('px')) {
            p = paddingValue.replace(/px/g, '');
        } else {
            p = paddingValue.replace(/%/g, '');
        }
        let returnValue = '';
        p = p.split(' ');
        if (location === 0) {
            returnValue = checkPxPercentage ? `${changedValue}% ${p[1]}% ${p[2]}% ${p[3]}%` : `${changedValue}px ${p[1]}px ${p[2]}px ${p[3]}px`;
        } else if (location === 1) {
            returnValue = checkPxPercentage ? `${p[0]}% ${changedValue}% ${p[2]}% ${p[3]}%` : `${p[0]}px ${changedValue}px ${p[2]}px ${p[3]}px`;
        } else if (location === 2) {
            returnValue = checkPxPercentage ? `${p[0]}% ${p[1]}% ${changedValue}% ${p[3]}%` : `${p[0]}px ${p[1]}px ${changedValue}px ${p[3]}px`;
        } else {
            returnValue = checkPxPercentage ? `${p[0]}% ${p[1]}% ${p[2]}% ${changedValue}%` : `${p[0]}px ${p[1]}px ${p[2]}px ${changedValue}px`;
        }
        updateValue('padding', returnValue);
    }

    return (
        <div className={`section full mb-2 border-top ${className !== undefined ? className : 'mt-0'}`}>
            <form>
                {fontPreivew !== undefined && fontPreivew === true &&
                    <div className="row">
                        <div className="col-12 col-md-12 mt-2">
                            <label className="form-label mb-1 w-100" htmlFor="fontSize"
                                style={{
                                    color: fontsColor,
                                    fontSize: `${fontSizeRange}px`,
                                    textAlign: textAlignment,
                                    textTransform: textTransform,
                                    textShadow: (textShadowBtn === 'on' && propsState !== null && propsState['text-shadow'] !== undefined) ? propsState['text-shadow'] : null
                                }}>Font Preview</label>
                        </div>
                    </div>
                }
                {(optionsList !== undefined && typeof (optionsList) === 'object' && optionsList.length !== 0) ?
                    <>
                        {
                            (optionsList.includes('fontSize') || optionsList.includes('fontColor')) &&
                            <>
                                <div className="row">
                                    {optionsList.includes('fontSize') &&
                                        <div className="col-7 col-md-7">
                                            <div className="form-group basic container">
                                                <div className="input-wrapper">
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <label className="form-label mb-1" htmlFor="fontSize">Font Size</label>
                                                        <div className='badge bg-warning cursor-pointer' onClick={(e) => { deleteValue('font-size'); setFontSizeRange(16) }}>
                                                            <ion-icon name="refresh-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                        </div>
                                                    </div>
                                                    <input type="range" title={`${fontSizeRange}px`} className="form-control form-range border-0 p-0" value={fontSizeRange} onChange={(e => changeFontSize(e))} min="12" max="50" id="fontSize"></input>
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {optionsList.includes('fontColor') &&
                                        <div className="col-5 col-md-5">
                                            <div className="form-group basic container">
                                                <div className="input-wrapper">
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <label className="form-label" htmlFor="fontColor">Font Color</label>
                                                        <div className='badge bg-warning cursor-pointer' onClick={(e) => { deleteValue('color'); setFontsColor('#111111') }}>
                                                            <ion-icon name="refresh-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                        </div>
                                                    </div>
                                                    <WebwebColorPicker color={fontsColor} onChangedColor={changedFontColor}></WebwebColorPicker>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {
                            optionsList.includes('border') &&
                            <div className="form-group basic container">
                                <div className="input-wrapper">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label className="form-label mb-1 fw-bolder" htmlFor="border">Border</label>
                                        <div className='badge bg-warning cursor-pointer' onClick={(e) => { deleteValue('border-color', 'border-style', 'border-width'); setBorderStyle('none'); setBordercolor('#111111'); setBorderwidth(0) }}>
                                            <ion-icon name="refresh-outline" className="md trash" aria-label="Delete"></ion-icon>
                                        </div>
                                    </div>
                                    <div className="row p-1" style={{ borderWidth: `${borderwidth}px`, borderColor: bordercolor, borderStyle: borderStyles }}>
                                        <div className="col-2 col-md-2">
                                            <label className="form-label" htmlFor="border">Width</label>
                                            <input type="number" className="form-control p-0 fs-12" id="border" placeholder="0"
                                                autoComplete="off" value={borderwidth} min="0" max="9" onChange={(e) => onBorderwidthChange(e)} />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                        <div className="col-1 col-md-1 d-flex align-items-center pb-1 fs-12">
                                            px
                                        </div>
                                        <div className="col-5 col-md-5">
                                            <label className="form-label mb-1" htmlFor="border">Color</label>
                                            <WebwebColorPicker color={bordercolor} onChangedColor={changedBorderColor}></WebwebColorPicker>
                                        </div>
                                        <div className="col-4 col-md-4">
                                            <div className="form-group basic container p-0">
                                                <div className="input-wrapper">
                                                    <label className="form-label mb-1" htmlFor="type">Type</label>
                                                    <select className="form-control form-select p-0 fs-12" id="type" value={borderStyles} onChange={(e) => changeBorderStyle(e)}>
                                                        <option value="solid">Solid</option>
                                                        <option value="dotted">Dotted</option>
                                                        <option value="dashed">Dashed</option>
                                                        <option value="double">Double</option>
                                                        <option value="groove">Groove</option>
                                                        <option value="ridge">Ridge</option>
                                                        <option value="none">None</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            optionsList.includes('textAlignment') &&
                            <div className="form-group basic container">
                                <RadioButtonsSelect
                                    uniqueID={`${id}ID1`}
                                    title='Text Alignment'
                                    defaultValue={textAlignment}
                                    onChangeValue={changeTextAlignment}
                                    buttonoptionsList={[
                                        { 'label': 'Left', 'value': 'left' },
                                        { 'label': 'Center', 'value': 'center' },
                                        { 'label': 'Right', 'value': 'right' },
                                        { 'label': 'Justify', 'value': 'justify' }
                                    ]} />
                            </div>
                        }
                        {
                            optionsList.includes('textTransform') &&
                            <div className="form-group basic container">
                                <RadioButtonsSelect
                                    uniqueID={`${id}ID2`}
                                    title='Text Transform'
                                    defaultValue={textTransform}
                                    onChangeValue={changeTextTransform}
                                    buttonoptionsList={[
                                        { 'label': 'Uppercase', 'value': 'uppercase' },
                                        { 'label': 'Lowercase', 'value': 'lowercase' },
                                        { 'label': 'Capitalize', 'value': 'Capitalize' }
                                    ]} />
                            </div>
                        }
                        {
                            optionsList.includes('textShadow') &&
                            <div className="row">
                                <div className="col-7 col-md-7">
                                    <div className="form-group basic container">
                                        <RadioButtonsSelect
                                            uniqueID={`${id}ID3`}
                                            title='Text Shadow'
                                            defaultValue={textShadowBtn}
                                            onChangeValue={changeTextShadowShow}
                                            buttonoptionsList={[
                                                { 'label': 'Off', 'value': 'off' },
                                                { 'label': 'On', 'value': 'on' }
                                            ]} />
                                    </div>
                                </div>
                                <div className="col-5 col-md-5">
                                    <div className="form-group basic container">
                                        <div className="input-wrapper">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <label className="form-label mb-1" htmlFor="shadowColor">Shadow Color</label>
                                                <div className='badge bg-warning cursor-pointer' onClick={(e) => { deleteValue('text-shadow'); setTextShodow('#111111') }}>
                                                    <ion-icon name="refresh-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                </div>
                                            </div>
                                            <WebwebColorPicker
                                                color={textShadow}
                                                onChangedColor={changeTextShadowColor} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (optionsList.includes('margin') || optionsList.includes('padding')) &&
                            <>
                                <div className="form-group basic container mb-1">
                                    <div className="input-wrapper">
                                        <div className="row">
                                            <div className="col-6 col-md-6 d-flex align-items-center">
                                                <label className="form-label" htmlFor="marginPadding">{optionsList.includes('margin') && 'Margin'} {optionsList.includes('padding') && <>{optionsList.includes('margin') ? '/ Padding' : 'Padding'}</>}</label>
                                                <div className='badge bg-warning cursor-pointer ms-1' onClick={(e) => { deleteValue('padding', 'margin');setMarginValue('0px 0px 0px 0px') ;setPaddingValue('0px 0px 0px 0px') }}>
                                                    <ion-icon name="refresh-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 text-end d-flex align-items-center justify-content-end">
                                                <div className="form-check form-switch webweb-custom-switch-sm">
                                                    <label htmlFor={`SwitchCheckDefault1${id}`} style={{ paddingRight: '3px', fontSize: '11px' }}>px</label>
                                                    <input className="form-check-input" type="checkbox" id={`SwitchCheckDefault1${id}`} onChange={(e) => PxPercentageChange(e)} checked={checkPxPercentage} />
                                                    <label className="form-check-label" htmlFor={`SwitchCheckDefault1${id}`} style={{ paddingLeft: '48px', fontSize: '11px' }}>%</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    optionsList.includes('margin') ?
                                        <div className="card shadow-none">
                                            <div className="card-body border" style={{ padding: '26px 43px 26px 43px' }}>
                                                <input type="number" className="form-control p-0" id="margin-top" placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', top: '0', width: '16%', left: '50%', transform: 'translateX(-50%)' }} value={getMPvalues('margin', 'Top')} onChange={(e) => onMarginChange(e, 0)} />

                                                <input type="number" className="form-control p-0" id="margin-top" placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', top: '50%', width: '16%', right: '0px', transform: 'translateY(-50%)' }} value={getMPvalues('margin', 'Right')} onChange={(e) => onMarginChange(e, 1)} />

                                                <input type="number" className="form-control p-0" id="margin-top" placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', top: '50%', width: '16%', left: '0px', transform: 'translateY(-50%)' }} value={getMPvalues('margin', 'Bottom')} onChange={(e) => onMarginChange(e, 2)} />

                                                <input type="number" className="form-control p-0" id="margin-top" placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', bottom: '0', width: '16%', left: '50%', transform: 'translateX(-50%)' }} value={getMPvalues('margin', 'Left')} onChange={(e) => onMarginChange(e, 3)} />

                                                <div className="card shadow-none">
                                                    <div className="card-body border" style={{ height: '90px' }}>
                                                        {
                                                            optionsList.includes('padding') &&
                                                            <>
                                                                <input type="number" className="form-control p-0" id="margin-top" min='0' placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', top: '0', width: '24%', left: '50%', transform: 'translateX(-50%)' }} onChange={(e) => onPaddingChange(e, 0)} value={getMPvalues('padding', 'Top')} />
                                                                <input type="number" className="form-control p-0" id="margin-top" min='0' placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', top: '50%', width: '24%', right: '0px', transform: 'translateY(-50%)' }} onChange={(e) => onPaddingChange(e, 1)} value={getMPvalues('padding', 'Right')} />
                                                                <input type="number" className="form-control p-0" id="margin-top" min='0' placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', top: '50%', width: '24%', left: '0px', transform: 'translateY(-50%)' }} onChange={(e) => onPaddingChange(e, 2)} value={getMPvalues('padding', 'Bottom')} />
                                                                <input type="number" className="form-control p-0" id="margin-top" min='0' placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', bottom: '0', width: '24%', left: '50%', transform: 'translateX(-50%)' }} onChange={(e) => onPaddingChange(e, 3)} value={getMPvalues('padding', 'Left')} />
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="card shadow-none">
                                            <div className="card-body border" style={{ height: '90px' }}>
                                                <input type="number" className="form-control p-0" id="margin-top" min='0' placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', top: '0', width: '24%', left: '50%', transform: 'translateX(-50%)' }} onChange={(e) => onPaddingChange(e, 0)} value={getMPvalues('padding', 'Top')} />
                                                <input type="number" className="form-control p-0" id="margin-top" min='0' placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', top: '50%', width: '24%', right: '0px', transform: 'translateY(-50%)' }} onChange={(e) => onPaddingChange(e, 1)} value={getMPvalues('padding', 'Right')} />
                                                <input type="number" className="form-control p-0" id="margin-top" min='0' placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', top: '50%', width: '24%', left: '0px', transform: 'translateY(-50%)' }} onChange={(e) => onPaddingChange(e, 2)} value={getMPvalues('padding', 'Bottom')} />
                                                <input type="number" className="form-control p-0" id="margin-top" min='0' placeholder={checkPxPercentage ? "%" : "px"} style={{ position: 'absolute', bottom: '0', width: '24%', left: '50%', transform: 'translateX(-50%)' }} onChange={(e) => onPaddingChange(e, 3)} value={getMPvalues('padding', 'Left')} />
                                            </div>
                                        </div>
                                }
                            </>
                        }
                    </>
                    :
                    null
                }








                {/* <div className="section mt-2"> */}

                {/* </div> */}

            </form>
        </div>
    )
}

export default ManageProperties
