import React, { useEffect, useState } from 'react'
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import Modal from 'react-bootstrap/Modal'
import axios from '../../../AxiosConfig'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { setWebsiteData } from '../../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
// import { useSelector, useDispatch } from 'react-redux';
import ReactJson from 'react-json-view';
import { newImageData, setShowHtmlJsCssModal, hideOffcanvas } from '../../../features/modal/offcanvasSlice';

const EditHtmlJsCssModal = ({ HtmlJsCssModal,
    setShowHtmlJsCssModal,
    setWebsiteData,
    showAppToast,
    hideOffcanvas,
    newImageData,
    MainBlockProps,
    appearance,
    NewBlockType
}) => {
    // const { CompleteJSON } = useSelector((state) => state.websiteData);
    // const { showJSONModal } = useSelector((state) => state.offcanvas);
    const token = localStorage.getItem('token')
    const tokenData = token ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain
    const [CSSvalue, setCSSValue] = useState(`function add(a, b) {\n  return a + b;\n}`);
    const [HTMLvalue, setHTMLValue] = useState(`function add(a, b) {\n  return a + b;\n}`);
    const [JSvalue, setJSValue] = useState(`function add(a, b) {\n  return a + b;\n}`);

    const [activeTab, setActiveTab] = useState(false);

    useEffect(() => {
      if(HtmlJsCssModal !== null) setActiveTab(true);
      else setActiveTab(false);
    }, [HtmlJsCssModal])
    

    useEffect(() => {
        setCSSValue('');
        setHTMLValue('');
        setJSValue('');
        // console.log(appearance);
        if (MainBlockProps && activeTab) {
            let sendID = MainBlockProps.styleID;
            if(NewBlockType.Style !== undefined) {
                sendID = NewBlockType.StyleID;
            } else if (MainBlockProps.styleID.split('_').length > 3) {
                sendID = MainBlockProps.style;
            }
            // axios.get(`/block/getBlockHtml/${appearance.properties.template}/${sendID}/script`)
            //     .then((response) => {
            //         if (response.data.data) {
            //             setJSValue(response.data.data);
            //         } else {
            //             showAppToast({
            //                 toastMessage: 'ERROR',
            //                 background: 'danger',
            //                 timeout: '1000',
            //                 icon: 'cross-circle-outline',
            //                 position: 'toast-center'
            //             })
            //         }
            //     }).catch((error) => {
            //         showAppToast({
            //             toastMessage: 'ERROR',
            //             background: 'danger',
            //             timeout: '1000',
            //             icon: 'close-circle-outline',
            //             position: 'toast-center'
            //         })
            //     })

            axios.get(`/block/getBlockHtml/${appearance.properties.template}/${sendID}/html`)
                .then((response) => {
                    if (response.data.data) {
                        setHTMLValue(response.data.data);
                    } else {
                        showAppToast({
                            toastMessage: 'ERROR',
                            background: 'danger',
                            timeout: '1000',
                            icon: 'close-circle-outline',
                            position: 'toast-center'
                        })
                    }
                }).catch((error) => {
                    showAppToast({
                        toastMessage: 'ERROR',
                        background: 'danger',
                        timeout: '1000',
                        icon: 'close-circle-outline',
                        position: 'toast-center'
                    })
                })

            // axios.get(`/block/getBlockHtml/${appearance.properties.template}/${sendID}/css`)
            //     .then((response) => {
            //         if (response.data.data) {
            //             setCSSValue(response.data.data);
            //         } else {
            //             showAppToast({
            //                 toastMessage: 'ERROR',
            //                 background: 'danger',
            //                 timeout: '1000',
            //                 icon: 'cross-circle-outline',
            //                 position: 'toast-center'
            //             })
            //         }
            //     }).catch((error) => {
            //         showAppToast({
            //             toastMessage: 'ERROR',
            //             background: 'danger',
            //             timeout: '1000',
            //             icon: 'cross-circle-outline',
            //             position: 'toast-center'
            //         })
            //     })
        }
    }, [activeTab])

    const hideHTMLJSCSSModal = () => {
        setShowHtmlJsCssModal(null);
    }

    const updateWebsiteHTMLCSSJS = () => {
        if (tokenData.userInfo.RoleID != 'Moderator' || tokenData.userInfo.RoleID == 'Moderator') {
            let sendID = MainBlockProps.styleID;
            if(NewBlockType.Style !== undefined) {
                sendID = NewBlockType.StyleID;
            } else if (MainBlockProps.styleID.split('_').length > 3) {
                sendID = MainBlockProps.style;
            }
            axios.post(`/block/updateBlockHtml/${appearance.properties.template}/${sendID}/html`, { 'html': HTMLvalue })
                .then(res => {
                    if (res.data.message === 'Success') {
                        // setWebsiteData(res.data.data)
                        showAppToast({
                            toastMessage: 'UPDATED ',
                            background: 'success',
                            timeout: '1000',
                            icon: 'checkmark-circle-outline',
                            position: 'toast-center'
                        })
                        newImageData({ imageDataObj: [] })
                        hideOffcanvas()
                    } else {
                        showAppToast({
                            toastMessage: 'ERROR',
                            background: 'danger',
                            timeout: '1000',
                            icon: 'close-circle-outline',
                            position: 'toast-center'
                        })
                        newImageData({ imageDataObj: [] })
                        hideOffcanvas()
                    }
                }).catch((error) => {

                    showAppToast({
                        toastMessage: 'ERROR',
                        background: 'danger',
                        timeout: '1000',
                        icon: 'close-circle-outline',
                        position: 'toast-center'
                    })
                })
            newImageData({ imageDataObj: [] })
            hideOffcanvas()
            console.log("CSSvalue", CSSvalue);
        }
        hideHTMLJSCSSModal();
    }


    const [disableBtn, setDisableBtn] = useState(false);

    return (
        <>
            <Modal class="modal fade modalbox" id="addpageModal" data-bs-backdrop="false" tabindex="-1" role="dialog" show={HtmlJsCssModal} fullscreen={true}>
                <div class="modal-content">
                    <Modal.Header class="modal-header">
                        <h5 class="modal-title">HTML / CSS / JS</h5>
                        <div>
                            <button className='btn btn-primary me-2' onClick={() => updateWebsiteHTMLCSSJS()}>
                                Update
                            </button>
                            <button class="text-primary btn-link bg-transparent border-0" style={{ cursor: 'pointer', textDecoration: 'none' }} onClick={() => hideHTMLJSCSSModal()}>
                                Close
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body class="modalBbody p-0">
                        <div class="p-2">
                            <div class="row mx-0">
                                <div className='col-md-12 d-flex gap-2'>
                                    {/* <button className={HtmlJsCssModal === 'CSS' ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={() => setShowHtmlJsCssModal('CSS')}>CSS</button> */}
                                    <button className={HtmlJsCssModal === 'HTML' ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={() => setShowHtmlJsCssModal('HTML')}>HTML</button>
                                    {/* <button className={HtmlJsCssModal === 'JS' ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={() => setShowHtmlJsCssModal('JS')}>JS</button> */}
                                </div>
                                <div className='col-md-12' style={{ 'width': '100%', 'max-height': '90vh', 'overflow': 'scroll' }}>
                                    {/* <div className={HtmlJsCssModal === 'CSS' ? 'd-block cssEditor' : 'd-none'}>
                                        <Editor
                                            value={CSSvalue}
                                            onValueChange={CSSvalue => setCSSValue(CSSvalue)}
                                            highlight={CSSvalue => highlight(CSSvalue, languages.js)}
                                            padding={10}
                                            style={{
                                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                                fontSize: 12,
                                            }}
                                        />
                                    </div> */}
                                    <div className={HtmlJsCssModal === 'HTML' ? 'd-block htmlEditor' : 'd-none'}>
                                        <Editor
                                            value={HTMLvalue}
                                            onValueChange={HTMLvalue => setHTMLValue(HTMLvalue)}
                                            highlight={HTMLvalue => highlight(HTMLvalue, languages.js)}
                                            padding={10}
                                            style={{
                                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                                fontSize: 12,
                                            }}
                                        />
                                    </div>
                                    {/* <div className={HtmlJsCssModal === 'JS' ? 'd-block jsEditor' : 'd-none'}>
                                        <Editor
                                            value={JSvalue}
                                            onValueChange={JSvalue => setJSValue(JSvalue)}
                                            highlight={JSvalue => highlight(JSvalue, languages.js)}
                                            padding={10}
                                            style={{
                                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                                fontSize: 12,
                                            }}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
                {/* </div> */}
            </Modal>
        </>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        HtmlJsCssModal,
        MainBlockProps,
        NewBlockType
    } = offcanvas
    const {
        appearance
    } = websiteData
    return {
        HtmlJsCssModal,
        MainBlockProps,
        appearance,
        NewBlockType
    }
}

const mapDispatchToProps = {
    setShowHtmlJsCssModal,
    setWebsiteData,
    showAppToast,
    newImageData,
    hideOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(EditHtmlJsCssModal)