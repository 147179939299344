import React, { useState, useEffect } from 'react';
import MDEditor, { bold, commands, italic, image, unorderedListCommand, orderedListCommand, checkedListCommand, link, title } from '@uiw/react-md-editor';
import { connect } from "react-redux";
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Nestable from 'react-nestable';
import 'react-nestable/dist/styles/index.css';
// import StyleEditor from 'react-style-editor';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { setContentBlockItem, setMainBlockProps, setContentItems, setContentItemsArray, setFlagsToCheckChanges } from '../../../features/modal/offcanvasSlice';

const BlockTab = ({
    items = [],
    itemID,
    contents,
    properties,
    blockID,
    blockType,
    websiteDataBlocks,
    blockRules,
    ContentItems,
    setMainBlockProps,
    MainBlockProps,
    setContentItems,
    isEditVisible,
    setContentItemsArray,
    FlagsToCheckChanges,
    setFlagsToCheckChanges,
    NewBlockType,
    role,
    setTabReturn
}) => {
    let navigate = useNavigate()
    const [blockStyleCode, setBlockStyleCode] = useState('');
    const [visibleField, setVisibleField] = useState([]);
    const [contentItms, setContentItms] = useState([])

    const getItemsObject = () => { return JSON.parse(JSON.stringify(ContentItems)) };
    const getBlockProps = () => { return JSON.parse(JSON.stringify(MainBlockProps)) };

    const changeFlagsToCheckChanges = (fieldName) => {
        const tempFlagsToCheckChanges = JSON.parse(JSON.stringify(FlagsToCheckChanges));
        if (tempFlagsToCheckChanges[fieldName] !== undefined && tempFlagsToCheckChanges[fieldName] === false) {
            tempFlagsToCheckChanges[fieldName] = true;
            setFlagsToCheckChanges(tempFlagsToCheckChanges);
        }
    }

    const onBlockHeadingChange = (e) => {
        const blockData = getBlockProps();
        blockData.blockHeading = e.target.value;
        setMainBlockProps(blockData);
        changeFlagsToCheckChanges('blockHeading');
    }

    const onBlockDescriptionChange = (e) => {
        const blockData = getBlockProps();
        blockData.blockDescription = e.target.value;
        setMainBlockProps(blockData);
        changeFlagsToCheckChanges('blockDescription');
    }

    const onChangeFilter = (e) => {
        const ItemsObj = getItemsObject();
        ItemsObj.itemFilters = e.target.value;
        setContentItems(ItemsObj);
        changeFlagsToCheckChanges('itemFilters');
        // console.log(e.target.value);
    }

    const onChangeCount = (e) => {
        const ItemsObj = getItemsObject();
        console.log(e.target.value, ItemsObj);
        ItemsObj.numItems = e.target.value;
        setContentItems(ItemsObj);
        changeFlagsToCheckChanges('numItems');
        // console.log(e.target.value);
    }

    const changeItemInRow = (item) => {
        const blockData = getBlockProps();
        blockData.countInRow = item;
        setMainBlockProps(blockData);
        changeFlagsToCheckChanges('countInRow');
    }

    useEffect(() => {
        if (isEditVisible && ContentItems !== null && NewBlockType.BlockType === 'CN') {
            const getId = ContentItems.contents.replace('contents.', '');
            if (Object.keys(contents).length !== 0) {
                let tempObject = contents[getId];
                let arrayOfItms = JSON.parse(JSON.stringify(tempObject.items));
                arrayOfItms = arrayOfItms.map((itm, i) => {
                    itm.id = i
                    return itm;
                })
                setContentItms(arrayOfItms);
            }
        }
        // else if (isEditVisible && ContentItems !== null && NewBlockType.BlockType === 'SM') {

        // }
    }, [ContentItems])


    useEffect(() => {
        if (blockID !== '' && blockID !== undefined) {
            setBlockStyleCode(websiteDataBlocks[blockID].properties.style)
        }
    }, [websiteDataBlocks, blockID])

    const setBlockData = () => {
        document.getElementsByName('v2blockHeading')[0].value =
            properties.blockHeading
        document.getElementsByName('v2blockDescription')[0].value =
            properties.blockDescription
    }

    useEffect(() => {
        if (properties !== undefined) {
            if (blockStyleCode !== 'SM_HEAD' && blockStyleCode !== 'SM_FOOT') {
                setBlockData()
            }
        }
    }, [properties])

    useEffect(() => {
        if (blockID !== '' && blockID !== undefined) {
            const blockStyle = websiteDataBlocks[blockID].properties.style

            if (blockRules <= 0) {
                navigate('/load')
            } else {
                if (blockRules.hasOwnProperty(blockStyle)) {
                    setVisibleField(blockRules[blockStyle].fields)
                } else {
                    setVisibleField(blockRules['CN'].fields)
                }
            }
        }
    }, [blockID, blockRules])

    // const collectData = () => {
    //     if (itemID !== undefined && itemID !== -1) {
    //         setInputValues()
    //     } else {
    //         if (blockType === 'SM') {
    //             // if (blockStyleCode !== 'SM_HEAD' && blockStyleCode !== 'SM_FOOT') {
    //             setInputValuesSmart()
    //             // }
    //         }
    //     }
    // }

    // const updatedItems = [];
    // console.log(updatedItems);

    const getImage = (items, key, prpty) => {
        if (Array.isArray(items)) {
            switch (prpty) {
                case 'src':
                    return items.length > key ? items[key].src : ''  // AddImage

                case 'alt':
                    return items.length > key ? items[key].alt : 'Avatar'

                default:
                    return ''
            }
        } else {
            return false
        }
    }

    const renderItem = ({ item }) => {
        return (
            <ul className="listview image-listview">
                <li className="custom-line">
                    <a href="#!" className="item pt-0 pb-0 ps-0" style={{ 'cursor': 'grab' }}>
                        <div className="icon-box bg-light">
                            <ion-icon name="pause-outline"></ion-icon>
                        </div>
                        <div className="icon-box bg-light">
                            <img
                                src={getImage(item.image, 0, 'src')}
                                alt={getImage(item.image, 0, 'alt')}
                                className="image"
                            />
                        </div>
                        <div className="in">
                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '67%' }}>{item.title}</div>
                        </div>
                    </a>
                </li>
            </ul>
        )
    }

    const changeContents = (e) => {
        setContentItms(e.items);
        setContentItemsArray(e.items);
        changeFlagsToCheckChanges('contents');
    }

    const [fieldsListening, setFieldsListening] = useState(null);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();


    function startSpeechRecognition(FieldName) {
        SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });
        setFieldsListening(FieldName);
    }

    function stopSpeechRecognition(FieldName) {
        SpeechRecognition.stopListening()
        const blockData = getBlockProps();
        blockData[FieldName] = `${blockData[FieldName]} ${transcript}`;
        setMainBlockProps(blockData);
        changeFlagsToCheckChanges(FieldName);
        setTimeout(() => {
            resetTranscript();
        }, 100);
        setFieldsListening(null);
    }


    const isSpeechRecognitionDoable = (FieldName) => {
        if (!browserSupportsSpeechRecognition) {
            return (<span className='fs-16' title='Voice to Text is not supported on this browser!'>
                <ion-icon name="mic-off-outline"></ion-icon>
            </span>)
        } else {
            return (
                <>
                    {
                        listening && FieldName === fieldsListening ?
                            <button type='button' className='speaker-on speaker-btn' title='Stop Listening' onClick={() => stopSpeechRecognition(FieldName)}>
                                <div class="pulse-ring" />
                                <div className='listening-animation'>
                                    <div className='sides border-circle' />
                                    <div className='center border-circle' />
                                    <div className='sides border-circle' />
                                </div>
                                <ion-icon name="mic-off-outline" class="off-mic" style={{ cursor: 'pointer' }}></ion-icon>
                            </button>
                            :
                            <button type='button' className='speaker-off speaker-btn' title='Type by Voice' disabled={listening && FieldName !== fieldsListening} onClick={() => startSpeechRecognition(FieldName)}>
                                <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                            </button>

                    }
                </>
            )
        }
    }

    // const token = localStorage.getItem('token');
    // const tokenData = (token) ? jwt_decode(token) : false;
    // const RoleID = (tokenData !== undefined && tokenData !== null && tokenData !== false) ? tokenData.userInfo.RoleID : 'Customer';

    return (
        // onLoad={collectData}
        <div>
            {/* {(itemID === undefined || itemID === -1) &&
                (blockType === 'SM' || blockType === 'PL') && (
                    <>
                        {items.map((value, key) => (
                            <form>
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <div className="row">
                                                    <div className="col-6 col-md-6">
                                                        <label className="form-label" htmlFor="v2blockHeading">Block Heading</label>
                                                    </div>
                                                    <div className="col-6 col-md-6 text-end text-primary">
                                                        <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                                    </div>
                                                </div>
                                                <input type="text" className="form-control" id="v2blockHeading" name="v2blockHeading" placeholder="Enter Block Heading" value={MainBlockProps !== null && MainBlockProps.blockHeading} onChange={(e) => { onBlockHeadingChange(e) }} />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <div className="row">
                                                    <div className="col-6 col-md-6">
                                                        <label className="form-label" htmlFor="v2blockDescription">Block Description</label>
                                                    </div>
                                                    <div className="col-6 col-md-6 text-end text-primary">
                                                        <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                                    </div>
                                                </div>
                                                <textarea rows="1" className="form-control" placeholder="Enter block description" name="v2blockDescription" value={MainBlockProps !== null && MainBlockProps.blockDescription} onChange={(e) => { onBlockDescriptionChange(e) }}></textarea>
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        role !== null && role === 'Admin' && 
                                        <div className="col-12 col-md-12">
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <div className="row">
                                                        <div className="col-6 col-md-6 mb-2">
                                                            <label className="form-label" htmlFor="v2blockDescription">Block CSS</label>
                                                        </div>
                                                    </div>
                                                    <StyleEditor value={MainBlockProps !== null && MainBlockProps.css} onChange={(e) => onBlockCssChange(e)} />
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </form>
                        ))}
                    </>
                )} */}


            {/* {itemID !== undefined && itemID !== -1 && ( */}
            <form>
                <div className="row">
                    <div className='col-md-12'>
                        <div className="accordion" id="accordionExample1">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed ps-0" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#accordionBock">
                                        Block Details
                                    </button>
                                </h2>
                                <div id="accordionBock" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div className="accordion-body px-0">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <div className="row">
                                                            <div className="col-4 col-md-4">
                                                                <label className="form-label" htmlFor="v2blockHeading">Block Heading</label>
                                                            </div>
                                                            <div className="col-8 col-md-8 d-flex justify-content-end cursor-pointer align-items-center">
                                                                <button className='badge bg-transparent btn-link d-flex cursor-pointer me-1 border-0' onClick={() => setTabReturn('Block Heading')}>
                                                                    <ion-icon name="brush"></ion-icon>
                                                                    <span className='ms-1'>Customise</span>
                                                                </button>
                                                                {isSpeechRecognitionDoable('blockHeading')}
                                                            </div>
                                                        </div>
                                                        {
                                                            listening && fieldsListening === 'blockHeading' ?
                                                            <div className="h6">{MainBlockProps !== null && MainBlockProps.blockHeading} {transcript}</div>
                                                            :
                                                            <input type="text" className="form-control" id="v2blockHeading" name="v2blockHeading" placeholder="Enter Block Heading" value={MainBlockProps !== null && MainBlockProps.blockHeading} onChange={(e) => { onBlockHeadingChange(e) }} />
                                                        }
                                                        <i className="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12">
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <div className="row">
                                                            <div className="col-6 col-md-6">
                                                                <label className="form-label" htmlFor="v2blockDescription">Block Description</label>
                                                            </div>
                                                            <div className='col-6 d-flex justify-content-end cursor-pointer align-items-center'>
                                                                <button className='badge bg-transparent btn-link d-flex cursor-pointer me-1 border-0' onClick={() => setTabReturn('Block Description')}>
                                                                    <ion-icon name="brush"></ion-icon>
                                                                    <span className='ms-1'>Customise</span>
                                                                </button>
                                                                {/* <div className="col-6 col-md-6 text-end text-primary">
                                                                    <span className='badge bg-secondary cursor-pointer me-1' onClick={() => setTabReturn('Block Description')}>
                                                                        <ion-icon name="brush"></ion-icon>
                                                                    </span> */}
                                                                {isSpeechRecognitionDoable('blockDescription')}
                                                            </div>
                                                        </div>
                                                        {
                                                            listening && fieldsListening === 'blockDescription' ?
                                                            <div className="h6">{MainBlockProps !== null && MainBlockProps.blockDescription} {transcript}</div>
                                                            :
                                                            <textarea rows="1" className="form-control" placeholder="Enter block description" name="v2blockDescription" value={MainBlockProps !== null && MainBlockProps.blockDescription} onChange={(e) => { onBlockDescriptionChange(e) }}></textarea>
                                                        }
                                                        <i className="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        MainBlockProps !== null && NewBlockType !== null && NewBlockType.BlockType === 'CN' && MainBlockProps.style !== 'CN_CRSL' &&
                        <div className="col-12 col-md-12">
                            <div className="form-group basic mb-1">
                                <div className="input-wrapper">
                                    <label className="form-label mb-2" htmlFor="itemRow">Item in Row</label>
                                    <div className="btn-group w-100" role="group">
                                        <input type="radio" className="btn-check" name="btnradiog" id="btnradios1" checked={MainBlockProps !== null && MainBlockProps.countInRow === ''} onChange={() => changeItemInRow('')} />
                                        <label className="btn btn-icon btn-outline-primary btn-sm" htmlFor="btnradios1">
                                            Auto
                                        </label>
                                        <input type="radio" className="btn-check" name="btnradiog" id="btnradios2" checked={MainBlockProps !== null && MainBlockProps.countInRow !== '' && Number(MainBlockProps.countInRow) === 1} onChange={() => changeItemInRow(1)} />
                                        <label className="btn btn-icon btn-outline-primary btn-sm" htmlFor="btnradios2">
                                            1
                                        </label>
                                        <input type="radio" className="btn-check" name="btnradiog" id="btnradios3" checked={MainBlockProps !== null && MainBlockProps.countInRow !== '' && Number(MainBlockProps.countInRow) === 2} onChange={() => changeItemInRow(2)} />
                                        <label className="btn btn-icon btn-outline-primary btn-sm" htmlFor="btnradios3">
                                            2
                                        </label>
                                        <input type="radio" className="btn-check" name="btnradiog" id="btnradios4" checked={MainBlockProps !== null && MainBlockProps.countInRow !== '' && Number(MainBlockProps.countInRow) === 3} onChange={() => changeItemInRow(3)} />
                                        <label className="btn btn-icon btn-outline-primary btn-sm" htmlFor="btnradios4">
                                            3
                                        </label>
                                        <input type="radio" className="btn-check" name="btnradiog" id="btnradios5" checked={MainBlockProps !== null && MainBlockProps.countInRow !== '' && Number(MainBlockProps.countInRow) === 4} onChange={() => changeItemInRow(4)} />
                                        <label className="btn btn-icon btn-outline-primary btn-sm" htmlFor="btnradios5">
                                            4
                                        </label>
                                        <input type="radio" className="btn-check" name="btnradiog" id="btnradios6" checked={MainBlockProps !== null && MainBlockProps.countInRow !== '' && Number(MainBlockProps.countInRow) === 6} onChange={() => changeItemInRow(6)} />
                                        <label className="btn btn-icon btn-outline-primary btn-sm" htmlFor="btnradios6">
                                            6
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        MainBlockProps !== null && NewBlockType !== null && NewBlockType.BlockType === 'CN' &&
                        <div className="col-6 col-md-6">
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="form-label" htmlFor="filter">Filter</label>
                                    <select className="form-control form-select" value={ContentItems !== null && ContentItems.itemFilters !== undefined && ContentItems.itemFilters.toLowerCase()} id="filter" onChange={(e) => onChangeFilter(e)}>
                                        <option value="" selected disabled>Select Filter</option>
                                        <option value="all">All</option>
                                        <option value="first">First</option>
                                        <option value="last">Last</option>
                                        <option value="random">Random</option>
                                    </select>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        MainBlockProps !== null && NewBlockType !== null && NewBlockType.BlockType === 'CN' &&
                        <div className="col-6 col-md-6">
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="form-label" htmlFor="count">Count</label>
                                    <input type="number" className="form-control" id="v2blockHeading" name="v2blockHeading" placeholder="Enter items counts to show" max={contentItms.length} value={ContentItems !== null && ContentItems.numItems} onChange={(e) => onChangeCount(e)} />

                                    {/* <select className="form-control form-select" id="count" onChange={(e) => onChangeCount(e)}>
                                            <option value="" selected disabled>Select Count</option>
                                            <option value="1" selected={ContentItems !== null && ContentItems.numItems !== '' && ContentItems.numItems.toString() === '1'}>1</option>
                                            <option value="2" selected={ContentItems !== null && ContentItems.numItems !== '' && ContentItems.numItems.toString() === '2'}>2</option>
                                            <option value="3" selected={ContentItems !== null && ContentItems.numItems !== '' && ContentItems.numItems.toString() === '3'}>3</option>
                                            <option value="4" selected={ContentItems !== null && ContentItems.numItems !== '' && ContentItems.numItems.toString() === '4'}>4</option>
                                        </select> */}
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </form>
            {/* )} */}

            {
                NewBlockType !== null && NewBlockType.BlockType === 'CN' &&
                <div
                    style={{ marginLeft: '-16px', marginRight: '-16px' }}
                >
                    <div className="listview-title mb-0">
                        <span>Reorder Item</span>
                    </div>
                    <Nestable
                        items={contentItms}
                        renderItem={renderItem}
                        maxDepth={1}
                        handler={false}
                        onChange={(e) => { changeContents(e) }}
                    />
                </div>
            }
        </div>
    )
}

const mapStateToProps = ({ websiteData, offcanvas, auth }) => {
    const {
        blocks: websiteDataBlocks,
        blockRules,
        contents
    } = websiteData
    const {
        items,
        itemID,
        properties,
        blockID,
        blockType,
        MainBlockProps,
        ContentItems,
        isEditVisible,
        NewBlockType,
        FlagsToCheckChanges
    } = offcanvas
    const { role } = auth
    return {
        contents,
        websiteDataBlocks,
        items,
        itemID,
        properties,
        blockID,
        blockType,
        blockRules,
        ContentItems,
        MainBlockProps,
        ContentItems,
        isEditVisible,
        NewBlockType,
        FlagsToCheckChanges,
        role
    }
}

const mapDispatchToProps = {
    setContentBlockItem,
    setMainBlockProps,
    setContentItems,
    setContentItemsArray,
    setFlagsToCheckChanges
}


export default connect(mapStateToProps, mapDispatchToProps)(BlockTab)
