import React from 'react'
import MDEditor from '@uiw/react-md-editor'
import { connect } from 'react-redux'
import { showOffcanvas, showButtonModal, newButtonData, showDeleteModal, showUpdateDataOffcanvas,setNewBlockType  } from '../../features/modal/offcanvasSlice'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const SM_ACTN_DFL1 = ({
    properties,
    items,
    blockType,
    blockID,
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal,
    showUpdateDataOffcanvas,
    setNewBlockType
}) => {
    const handleBlockUpdate = (msg) => {
        console.log('CLICKED')
        showOffcanvas({ isVisible: true, items: items, itemCount: 'null', properties: properties, blockType: blockType, blockID: blockID, msg: msg })
    }

    function handleShowButtonModal(buttonData, ID) {
        console.log(buttonData)
        handleBlockUpdate(ID)
        setTimeout(() => {
            showButtonModal({
                isButtonModalVisible: true,
                buttonData: buttonData,
                buttonID: ID
            })
        }, [1000])
        newButtonData({ buttonDataObj: buttonData })
    }

    const handleNewEditItem = (msg) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': 0 });
        showUpdateDataOffcanvas({
            isEditVisible: true,
            //   itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties,
            blockType: blockType,
            blockID: blockID,
            msg: msg
        })
    }

    const handleDeleteBlock = (blockName) => {
        showDeleteModal({ isDeleteVisible: true, deleteMessage: 'Are your sure? You want to delete this block?', triggerMessage: 'block', deleteID: blockName })
    }
    return (
        <div>
            <div className="section full">
                <div>
                    <div className="card comment-box rounded-0 shadow-none border position-relative" style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                        <h4 className="card-title cursor-pointer" onClick={() => { handleBlockUpdate() }} dangerouslySetInnerHTML={{ __html: items[0].title }} style={properties.titleCss && cssConverter(properties.titleCss)} />
                        <h4 onClick={() => { handleBlockUpdate() }} className='cursor-pointer'><MDEditor.Markdown source={items[0].subtitle} linkTarget="_blank" style={properties.subtitleCss && cssConverter(properties.subtitleCss)}/></h4>
                        <div className="text">
                            {items[0].button !== null && items[0].button.map((item, key) => {
                                return <a href={item.link} className={`btn rounded me-1 ${item.style && item.style !== '' ? item.style : 'btn-primary'}`} onClick={() => handleNewEditItem(items[0].button, key)} key={key} style={properties.buttonCss && cssConverter(properties.buttonCss)}>{item.title}</a>
                            })}
                        </div>
                        {/* <div className='d-flex pos-absglr'>
                            <div className="col-6 col-md-6 text-end">
                                <span className="badge bg-success p-1 editBtnCss" onClick={() => { handleNewEditItem() }}>Edit</span>
                            </div>
                            <div className="col-6 col-md-6">
                                <span className="badge bg-danger p-1 editBtnCss" onClick={() => { handleDeleteBlock(blockID) }}>Delete</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal,
    showUpdateDataOffcanvas,
    setNewBlockType
}

export default connect(mapStateToProps, mapDispatchToProps)(SM_ACTN_DFL1)
