import React, { useState, useEffect } from 'react';
import { hideUpdateDataOffcanvas } from '../../../features/modal/offcanvasSlice';
import { setWebsiteData, setLoading } from '../../../features/websiteData/websiteDataSlice';
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { connect } from 'react-redux'
import { showAppToast } from '../../../features/utilities/appToastSlice'

const BlockLayout = ({
    isEditVisible,
    NewBlockType,
    setWebsiteData,
    hideUpdateDataOffcanvas,
    MainBlockProps,
    showAppToast,
    checkItemClicked,
    websiteDataAppearnace,
    setLoading
}) => {
    const token = localStorage.getItem('token')
    const tokenData = token ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    const [blockStyleList, setBlockStyleList] = useState([]);
    const [cardStyleList, setCardStyleList] = useState([]);

    const handleBlockStyleUpdate = (newStyleName) => {
        const styleData = newStyleName.split('_')
        const blockType = styleData[0]
        const blockName = styleData[1]
        const newBlockCardStyle = styleData[2]
        let data = { style: blockType + '_' + blockName, styleID: newStyleName }
        setLoading(true);
        if(newBlockCardStyle.length > 4 && blockType === 'CN') {
            data = { style: newStyleName, styleID: 'CN_LIST_CARD_DFL1' }
            if(MainBlockProps !== null) {
                const currentBlockStyleID = MainBlockProps.styleID.split('_');
                data.styleID = currentBlockStyleID.length === 4 ? MainBlockProps.styleID : 'CN_LIST_CARD_DFL1';
            }
        }
        // console.log(data, newBlockCardStyle, blockType, blockName);
        // return;
        if (tokenData.userInfo.RoleID != 'Moderator') {
            axios.put('/block/' + selectedDomain + '/' + NewBlockType.BlockID, data).then(res => {
                if (res.data.message === 'Success') {
                    setWebsiteData(res.data.data)
                    showAppToast({
                        toastMessage: 'PROPERTIES UPDATED ',
                        background: 'success',
                        timeout: '1000',
                        icon: 'checkmark-circle-outline',
                        position: 'toast-center'
                    })
                    hideUpdateDataOffcanvas()
                } else {
                    showAppToast({
                        toastMessage: 'FAIL',
                        background: 'danger',
                        timeout: '1000',
                        icon: 'close-circle-outline',
                        position: 'toast-center'
                    })
                    hideUpdateDataOffcanvas()
                }
                setLoading(false);
            })
        }
    }

    const handleCardStyleUpdate = (cardStyleID) => {
        let data = { style: MainBlockProps.style, styleID: cardStyleID }
        setLoading(true);
        if (tokenData.userInfo.RoleID != 'Moderator') {
            axios.put('/block/' + selectedDomain + '/' + NewBlockType.BlockID, data).then(res => {
                if (res.data.message === 'Success') {
                    setWebsiteData(res.data.data)
                    showAppToast({
                        toastMessage: 'PROPERTIES UPDATED ',
                        background: 'success',
                        timeout: '1000',
                        icon: 'checkmark-circle-outline',
                        position: 'toast-center'
                    })
                    hideUpdateDataOffcanvas()
                } else {
                    showAppToast({
                        toastMessage: 'FAIL',
                        background: 'danger',
                        timeout: '1000',
                        icon: 'close-circle-outline',
                        position: 'toast-center'
                    })
                    hideUpdateDataOffcanvas()
                }
                setLoading(false);
            })
        }
    }
    

    useEffect(() => {
        // console.log(NewBlockType)
        // console.log("hcgshhx");
        // console.log(websiteDataAppearnace.properties.template)
        if (isEditVisible !== undefined && isEditVisible && NewBlockType !== undefined && NewBlockType !== null && blockStyleList.length === 0 && NewBlockType.Style === undefined) {
            axios.get('/block/getBlockStyles/' + NewBlockType.BlockName + '/' + selectedDomain + '/' + websiteDataAppearnace.properties.template)
            .then(res => {
                if (res.data.message === 'Success') {
                    let blocklist = res.data.data;
                    // if(NewBlockType.BlockName === 'CN_LIST'){
                    //     blocklist = [...blocklist, 
                    //         {"blockID":"CN_LIST_GRID1","blockName":"Grid","thumbnail":""},
                    //         {"blockID":"CN_LIST_MSNY1","blockName":"Masonary","thumbnail":""},
                    //         {"blockID":"CN_LIST_SLDR1","blockName":"Slider","thumbnail":""},
                    //         {"blockID":"CN_LIST_TIME1","blockName":"Vertical Timeline","thumbnail":""},
                    //         {"blockID":"CN_LIST_TIME2","blockName":"Horizontal Timeline","thumbnail":""}
                    //     ]
                    // }
                    setBlockStyleList(blocklist)
                } else {
                    console.log('FAIL BLOCK STYLE LIST')
                }
            })
        }
        // console.log(NewBlockType)
        if (isEditVisible !== undefined && isEditVisible && NewBlockType !== undefined && NewBlockType !== null && blockStyleList.length === 0 && NewBlockType.Style !== undefined && NewBlockType.Style.split('_').length > 2) {
            console.log("cardStyle")
            // const blocklist = [
            //     {"blockID":"CN_LIST_CARD_DFL1","blockName":"DFL1","thumbnail":""},
            //     {"blockID":"CN_LIST_CARD_DFL2","blockName":"DFL2","thumbnail":""},
            //     {"blockID":"CN_LIST_CARD_DFL3","blockName":"DFL3","thumbnail":""},
            //     {"blockID":"CN_LIST_CARD_DFL4","blockName":"DFL4","thumbnail":""},
            //     {"blockID":"CN_LIST_CARD_DFL5","blockName":"DFL5","thumbnail":""},
            //     {"blockID":"CN_LIST_CARD_DFL6","blockName":"DFL6","thumbnail":""},
            //     {"blockID":"CN_LIST_CARD_DFL7","blockName":"DFL7","thumbnail":""},
            //     {"blockID":"CN_LIST_CARD_DFL8","blockName":"DFL8","thumbnail":""},
            //     {"blockID":"CN_LIST_CARD_DFL9","blockName":"DFL9","thumbnail":""},
            //     {"blockID":"CN_LIST_CARD_DFL10","blockName":"DFL10","thumbnail":""},
            //     {"blockID":"CN_LIST_CARD_DFL11","blockName":"DFL11","thumbnail":""},
            //     {"blockID":"CN_LIST_CARD_DFL12","blockName":"DFL12","thumbnail":""}
            // ]
            axios.get('/block/getCardStyles/' + NewBlockType.BlockName + '/' + selectedDomain + '/' + websiteDataAppearnace.properties.template)
            .then(res => {
                if (res.data.message === 'Success') {
                    let blocklist = res.data.data;
                    setCardStyleList(blocklist)
                } else {
                    console.log('FAIL BLOCK STYLE LIST')
                }
            })
        }
        if(isEditVisible !== undefined && isEditVisible === false) {
            setBlockStyleList([]);
        }
    }, [isEditVisible, NewBlockType])

    return (
        <div class="section full mt-2 mb-2">
            <div class="row">
                {
                    NewBlockType !== null && NewBlockType.Style && NewBlockType.Style.split('_').length > 2 ?
                    <>
                        {(cardStyleList.length > 0 && MainBlockProps !== undefined && MainBlockProps !== null) && 
                            <>
                                {cardStyleList.map((value, i) => {
                                    return (
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 text-center position-relative" style={{ cursor: 'pointer' }} key={i}>
                                            <div style={{ height: '145px', width: 'auto', backgroundSize: 'cover', borderRadius: '8px', backgroundColor: (MainBlockProps.styleID === value.blockID) ? '' : 'gray', backgroundImage: 'url(' + value.thumbnail + ')', backgroundPosition: 'center', border: '2px solid #edcd2f' }} class={(MainBlockProps.styleID === value.blockID) ? 'd-flex justify-content-center align-items-center text-dark flex-column' : 'd-flex justify-content-center align-items-center text-light flex-column border'} onClick={() => {
                                                handleCardStyleUpdate(value.blockID)
                                            }}>
                                            </div>
                                            {value.blockName}
                                            {(MainBlockProps.styleID === value.blockID) &&
                                                <div className='position-absolute e-0 t-n1 z-index-10 bg-warning cursor-pointer d-flex align-items-center' style={{height: '30px', width: '30px', borderRadius: '50%'}}>
                                                    <ion-icon name="checkmark-outline" class="fs-5 m-1" aria-label="Delete"></ion-icon>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </>
                    :
                    <>
                        {(blockStyleList.length > 0 && MainBlockProps !== undefined && MainBlockProps !== null) ? 
                            <>
                                {blockStyleList.map((value, i) => {
                                    return (
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 text-center position-relative" style={{ cursor: 'pointer' }} key={i}>
                                            <div style={{ height: '145px', width: 'auto', backgroundSize: 'cover', borderRadius: '8px', backgroundColor: (MainBlockProps.styleID === value.blockID) ? '' : 'gray', backgroundImage: 'url(' + value.thumbnail + ')', backgroundPosition: 'center', border: '2px solid #edcd2f' }} class={(MainBlockProps.styleID === value.blockID || MainBlockProps.style === value.blockID) ? 'd-flex justify-content-center align-items-center text-dark flex-column' : 'd-flex justify-content-center align-items-center text-light flex-column border'} onClick={() => {
                                                handleBlockStyleUpdate(value.blockID)
                                            }}>
                                            </div>
                                            {value.blockName}
                                            {(MainBlockProps.styleID === value.blockID || MainBlockProps.style === value.blockID) &&
                                                <div className='position-absolute e-0 t-n1 z-index-10 bg-warning cursor-pointer d-flex align-items-center' style={{height: '30px', width: '30px', borderRadius: '50%'}}>
                                                    <ion-icon name="checkmark-outline" class="fs-5 m-1" aria-label="Delete"></ion-icon>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </>
                        : 
                            <>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 text-center" style={{ cursor: 'pointer' }}>
                                    <div style={{ height: '145px', width: 'auto', backgroundSize: 'cover', borderRadius: '8px', backgroundColor: 'gray', border: '2px solid #edcd2f' }} class="d-flex justify-content-center align-items-center text-dark flex-column">
                                    </div>
                                    Default
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        isEditVisible,
        NewBlockType,
        MainBlockProps
    } = offcanvas
    const {
        appearance: websiteDataAppearnace
    } = websiteData
    return {
        isEditVisible,
        NewBlockType,
        MainBlockProps,
        websiteDataAppearnace
    }
}

const mapDispatchToProps = {
    setWebsiteData,
    showAppToast,
    hideUpdateDataOffcanvas,
    setLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockLayout)