import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import frontcardlogo from '../images/SkyQ-Tech-Vertical-blue.svg';
import backcardlogo from '../images/SkyQ-Tech-Vertical-yellow.svg';
import Thumbnail1 from '../images/thumbnail/Thumbnail-1.svg';
import Thumbnail2 from '../images/thumbnail/Thumbnail-2.svg';
import Thumbnail3 from '../images/thumbnail/Thumbnail-3.svg';
import Thumbnail4 from '../images/thumbnail/Thumbnail-4.svg';
// import NFCIcon from '../../../public/assets/img/cardDesigns/NFC-icon-white.svg';
// import QRCode from '../../../public/assets/img/cardDesigns/qrcode.svg'

function VBCardEditor() {
   
  return (
    <div className="row sm-mb-4 sm-pb-4">
        <div className="col-12">
            <div id="appCapsule" className='mainBody'></div>
            <div className='continer-mobile'>
                <div className="section full">
                    <div className="py-4 mb-6">
                        <ul className="nav nav-tabs capsuled" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#frontView" role="tab">
                                    Front
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#backView" role="tab">
                                    Back
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content mt-2 d-flex justify-content-center">
                            <div className="tab-pane fade w-100 show active" id="frontView" role="tabpanel">
                                {/* Card Design Front Start */}
                                <div className="mt-1 d-flex justify-content-center">
                                    <div className="card card-front">
                                        <div className='position-absolute top-0 bottom-0 right-0 left-0'>
                                            <svg className='BGSVGImg' width="350" height="200" viewBox="-1250 0 1258 2126" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M407 0H1258V2126H0C760.218 1373.12 771.381 825.813 407 0Z" fill="#1971B7"/>
                                            </svg>
                                        </div>
                                        <div className='position-absolute top-0 bottom-0 right-0 left-0'>
                                            <div className='card-top-half d-flex justify-content-between'>
                                                <img src={frontcardlogo} className="VBImg card-img-top" alt="card" />
                                                <img src='assets/img/cardDesigns/NFC-icon-white.svg' className="NFCImg" alt="card" />
                                            </div>
                                            <div className='card-bottom-half d-flex justify-content-between'>
                                                <div className="card-body">
                                                    <h5 className="card-name card-title">Saurabh Karve</h5>
                                                    <p className="card-designation card-text">Director</p>
                                                </div>
                                                <img src='assets/img/cardDesigns/qrcode.svg' className="QRImg" alt="card" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card Design Front Ends */}
                                {/* Input Fields Start */}
                                <div className="section mt-2">
                                    <Form>
                                        <div>
                                            <label>
                                                Upload your logo:
                                                <input type="file" id="fileuploadInput" accept=".png, .jpg, .jpeg" />
                                            </label>
                                            <Button className='mt-1 w-100' variant="primary" type="submit">Upload</Button>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="form-label" for="name4">Name</label>
                                                <input type="text" className="form-control" id="name4" placeholder="Enter your name" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="form-label" for="desig4">Designation</label>
                                                <input type="text" className="form-control" id="desig4" placeholder="Enter your designation" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="form-label mb-1" for="desig4">Select the shape background color</label>
                                                <input type="color" value="#c7c7c7" className="form-control p-0 rounded" id="color4" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="form-label mb-1" for="desig4">Select the shape</label>
                                                <div className="row mt-2 mb-5">
                                                    <div className="col-3">
                                                        <img src={Thumbnail1} className="w-100 border border-dark rounded" alt="Thumbnail 1" />
                                                        <p className='text-center'><small>Blank</small></p>
                                                    </div>
                                                    <div className="col-3">
                                                        <img src={Thumbnail2} className="w-100 border border-dark rounded" alt="Thumbnail 2" />
                                                        <p className='text-center'><small>Line</small></p>
                                                    </div>
                                                    <div className="col-3">
                                                        <img src={Thumbnail3} className="w-100 border border-dark rounded" alt="Thumbnail 3" />
                                                        <p className='text-center'><small>Slant</small></p>
                                                    </div>
                                                    <div className="col-3">
                                                        <img src={Thumbnail4} className="w-100 border border-dark rounded" alt="Thumbnail 4" />
                                                        <p className='text-center'><small>Curve</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Form>
                                </div>
                                {/* Input Fields Ends */}
                            </div>
                            <div className="tab-pane fade w-100" id="backView" role="tabpanel">
                                {/* Card Design Back Start */}
                                <div className="mt-1 d-flex justify-content-center">
                                    <div className="card card-back d-flex justify-content-center align-items-center">
                                        <img src={backcardlogo} className="VBImg card-img-top" alt="card" />
                                    </div>
                                </div>
                                {/* Card Design Back Ends */}
                                {/* Input Fields Start */}
                                <div className='section mt-2'>
                                    <Form>
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="form-label mb-1" for="desig4">Select the shape background color</label>
                                                <input type="color" value="#c7c7c7" className="form-control p-0" id="color4" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                {/* Input Fields Start */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default VBCardEditor
