import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import Webweblogo from '../images/webweb.svg'
import Logo from '../images/icon/webweb-logo.png'
import Phone from '../images/icon/phone.jpg'
const PublishWebsiteAnimate = ({ url, setTokenAndWebsiteJSON }) => {
  return (
    <div id="appCapsule" className="pt-0 pb-0">
      <div className="container-fluid buildForm publishAnimation px-0">
        <div className="row mx-0">
          <div className="col-md-5  mt-4 leftPublish">
            <div className="image-logo justify-content-start d-flex mb-4 ps-5">
              <img class="logo-img" src={Logo} />
            </div>
          </div>

          <div className="col-md-7 position-relative rightPublish">
            <div className='logoAndBtns'>
              <img class="logo-img" src={Logo} />
              <div className='btns-div'>
                <a href="https://webweb.webweb.ai/build-website" target='_blank' className='btn-outline-white signIn-link me-1'>Sign In</a>
                <a href='https://webweb.webweb.ai/support' target='_blank' className='btn-link support-link'>Support</a>
              </div>
            </div>
            <div className="w-100 h-100 d-flex align-items-center flex-column justify-content-center">
                <h1 className="text-light text-center">Congratulations !!</h1>
                <h4 className="text-light text-center">Website Created Successfully!!!</h4>
                <div className="d-flex mt-3">
                    <button onClick={() => setTokenAndWebsiteJSON()} className="btn btn-primary rounded me-1 fs-20 px-4 py-3">Edit Website</button>
                    <a href={`https://${url}`} target="_blank" className="btn btn-outline-primary rounded me-1 fs-20 px-4 py-3">View Website</a>
                </div>
            </div>
            <div className="shape shape-start shape-fluid-y svg-shim text-white">
              <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h100v386l-50 772v386H0V0z" fill="currentColor"></path></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublishWebsiteAnimate
