import React from 'react'

const GetBoost = ({
    
}) => {
    return (
        <div className="section full mt-2 mb-2 ms-2">
            <h2>Coming Soon</h2>
        </div>
    )
}

export default GetBoost
