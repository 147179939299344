import React from 'react'
import { useLocation } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import SwitchImage from '../images/loading-animate.svg'

function DomainSearch(props) {
    const search = useLocation().search
    const searched = new URLSearchParams(search).get('searched')
    
    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain
    return (
        <div id="appCapsule">
            <div className="section-title justify-content-center">{selectedDomain}</div>
            <div className="section full">
                <div className="wide-block pt-2 pb-2">
                    <a href="#!"><h3 class="text-primary">Connect Existing Domain ?</h3></a>
                    <form className="search-form">
                        <div className="section-title p-0 pb-1">Search Domain Name</div>
                        <div className="input-group form-group boxed p-0">
                            <input type="text" className="form-control" placeholder="Search Domain" aria-label="Recipient's username" aria-describedby="button-addon2" />
                            <button className="btn btn-secondary h-auto" type="button" id="button-addon2" style={{height:"inherit !important"}}>
                                <ion-icon name="search-sharp"></ion-icon>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        
            <div className="section full">
                <div className="section-title">Search Result</div>
                <ul className="listview image-listview flush">
                    <li>
                        <a href="#!" className="item">
                            <ion-icon name="checkmark-circle-outline" className="image text-success"></ion-icon>
                            <div className="in">
                                <div>
                                    webweb.in
                                </div>
                                <span className="badge badge-success">Buy Now</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#!" className="item">
                            <ion-icon name="checkmark-circle-outline" className="image text-success"></ion-icon>
                            <div className="in">
                                <div>
                                    webweb.co.in
                                </div>
                                <span className="badge badge-success">Buy Now</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#!" className="item">
                            <ion-icon name="close-circle-outline" className="image text-danger"></ion-icon>
                            <div className="in">
                                <div>
                                    webweb.net
                                </div>
                                <span className="badge badge-danger">Unavailable</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

            <div style={{position: 'fixed',display: 'flex',justifyContent: 'center',alignItems: 'center',top: 0,left: '20%',right: 0,bottom: 0}}>
                <div>
                    <img src={SwitchImage} width="100%" style={{maxWidth: '250px'}} />
                    <h3 className="text-center text-secondary">Please wait domain booking in progress...</h3>
                </div>
            </div>

            <div className="section full">
                <div className="section mt-2">
                    <div className="card">
                        <div className="card-header card-title bg-success">
                            Success
                        </div>
                        <div className="card-body">
                            <h3 className="card-text">Domain booked successfully</h3>
                            <p className="card-text">https://hussain.com</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section full">
                <div className="section mt-2">
                    <div className="card">
                        <div className="card-header card-title bg-danger">
                            Fail
                        </div>
                        <div className="card-body">
                            <h3 className="card-text">Domain booking failed</h3>
                            <p className="card-text">https://hussain.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DomainSearch
