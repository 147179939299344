import React from 'react'
import PropTypes from 'prop-types'
import Rightpanel from '../layout/Rightpanel/Appearance'
import { connect } from 'react-redux'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'
import { showOffcanvas, showButtonModal, newButtonData, showDeleteModal, setNewBlockType, showUpdateDataOffcanvas } from '../../features/modal/offcanvasSlice'

const SM_BRDC_DFL1 = ({
  properties,
  items,
  blockType,
  blockID,
  showOffcanvas,
  showDeleteModal,
  setNewBlockType,
  showUpdateDataOffcanvas
}) => {
  const handleBlockUpdate = (msg) => {
    console.log('CLICKED')
    showOffcanvas({ isVisible: true, items: items, itemCount: 'null', properties: properties, blockType: blockType, blockID: blockID, msg: msg })
  }

  const handleNewEditItem = (msg) => {
    setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': 0 });
    showUpdateDataOffcanvas({
      isEditVisible: true,
      //   itemID: itemID,
      items: items,
      blockID: blockID,
      properties: properties,
      blockType: blockType,
      blockID: blockID,
      msg: msg
    });
  }

  const handleDeleteBlock = (blockName) => {
    showDeleteModal({ isDeleteVisible: true, deleteMessage: 'Are your sure? You want to delete this block?', triggerMessage: 'block', deleteID: blockName })
  }
  return (
    <div>
      <div className="section full position-relative" style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
        {/* <div className='d-flex pos-absglr'>
          <div className="col-6 col-md-6 text-end">
            <span className="badge bg-success p-1 editBtnCss" onClick={() => { handleNewEditItem() }}>Edit</span>
          </div>
          <div className="col-6 col-md-6">
            <span className="badge bg-danger p-1 editBtnCss" onClick={() => { handleDeleteBlock(blockID) }}>Delete</span>
          </div>
        </div> */}
        <div className="card text-white rounded-0" style={{ minHeight: '150px', zIndex: '-1', backgroundColor: (properties.backgroundType === 'color') ? properties.backgroundValue : '' }}>
          {properties.backgroundValue === '' &&
            <img
              src={(properties.backgroundValue === '') ? items[0]?.image[0]?.src : properties.backgroundValue}
              className="rounded-0 card-img overlay-img"
              alt="Some IMG"
              style={cssConverter(properties.imageCss, { height: '150px'})}
            />
          }
          {properties.backgroundType === 'image' &&
            <img
              src={properties.backgroundValue}
              style={cssConverter(properties.imageCss, { height: '150px'})}
              className="rounded-0 card-img overlay-img"
              alt="Some IMG"
            />
          }
          <div className="card-img-overlay rounded-0 d-flex ms-5">
            <h2 className="card-title">
              <div className="row">
                <div className="col-7 col-md-7" style={cssConverter(properties.titleCss)} dangerouslySetInnerHTML={{ __html: items[1]?.title }} />
              </div>
            </h2>
            <p className="card-text d-flex align-items-center" style={cssConverter(properties.titleCss)}>
              
              <h2 dangerouslySetInnerHTML={{ __html: items[0]?.title }} />{' '}
              {/* <ion-icon name="chevron-forward-outline"></ion-icon>{' '} */}
              <span  dangerouslySetInnerHTML={{ __html: items[1]?.title }} />{' '}
            </p>
          </div>
        </div>
      </div>
      {/* <Rightpanel /> */}
    </div>
  )
}

SM_BRDC_DFL1.propTypes = {
  items: PropTypes.array,
  properties: PropTypes.object
}

SM_BRDC_DFL1.defaultProps = {
  items: [],
  properties: {}
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  showOffcanvas,
  showButtonModal,
  newButtonData,
  showDeleteModal,
  setNewBlockType,
  showUpdateDataOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(SM_BRDC_DFL1)
