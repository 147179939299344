import React from 'react'
import { useLocation, Link } from 'react-router-dom'

function SupportTickets(props) {
    const search = useLocation().search
    const ID = new URLSearchParams(search).get('id')
    console.log(ID)
    return (
        <>
            <div id="appCapsule">
                <div className="message-divider">
                    <Link to="/support" className="text-secondary fs-6 d-flex align-items-center justify-content-center">
                        <ion-icon name="arrow-back-outline"></ion-icon>&nbsp;Back
                    </Link>
                </div>

                <div className="message-item">
                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="avatar" />
                    <div className="content">
                        <div className="title">John</div>
                        <div className="bubble">
                            Hi everyone, how are you?
                        </div>
                        <div className="footer">8:40 AM</div>
                    </div>
                </div>

                <div className="message-item">
                    <img src="assets/img/sample/avatar/avatar2.jpg" alt="avatar" className="avatar" />
                    <div className="content">
                        <div className="title">Marry</div>
                        <div className="bubble">
                            I'm fine, how are you today john, do you feel good?
                        </div>
                        <div className="footer">10:40 AM</div>
                    </div>
                </div>

                <div className="message-item user">
                    <div className="content">
                        <div className="bubble">
                            Would you please repost the photo you sent yesterday?
                        </div>
                        <div className="footer">10:40 AM</div>
                    </div>
                </div>

                <div className="message-divider">
                    Friday, Sep 20, 10:40 AM
                </div>

                <div className="message-item">
                    <img src="assets/img/sample/avatar/avatar2.jpg" alt="avatar" className="avatar" />
                    <div className="content">
                        <div className="title">Marry</div>
                        <div className="bubble">
                            <img src="assets/img/sample/photo/1.jpg" alt="photo" className="imaged w160" />
                        </div>
                        <div className="footer">10:40 AM</div>
                    </div>
                </div>

                <div className="message-item">
                    <img src="assets/img/sample/avatar/avatar4.jpg" alt="avatar" className="avatar" />
                    <div className="content">
                        <div className="title">Katie</div>
                        <div className="bubble">
                            Nice photo !
                        </div>
                        <div className="footer">10:40 AM</div>
                    </div>
                </div>

                <div className="message-item">
                    <img src="assets/img/sample/avatar/avatar2.jpg" alt="avatar" className="avatar" />
                    <div className="content">
                        <div className="title">Marry</div>
                        <div className="bubble">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vitae nisl et nibh iaculis
                            sagittis. In hac habitasse platea dictumst. Sed eu massa lacinia, interdum ex et, sollicitudin elit.
                        </div>
                        <div className="footer">10:40 AM</div>
                    </div>
                </div>

                <div className="message-item user">
                    <div className="content">
                        <div className="bubble">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vitae nisl et nibh iaculis
                            sagittis. In hac habitasse platea dictumst. Sed eu massa lacinia, interdum ex et, sollicitudin elit.
                        </div>
                        <div className="footer">10:40 AM</div>
                    </div>
                </div>
            </div>
            <div className="chatFooter position-sticky">
                <form>
                    <a href="#" className="btn btn-icon btn-secondary rounded" data-bs-toggle="offcanvas"
                        data-bs-target="#actionSheetAdd">
                        <ion-icon name="add"></ion-icon>
                    </a>
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <input type="text" className="form-control" placeholder="Type a message..." />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                    <button type="button" className="btn btn-icon btn-primary rounded">
                        <ion-icon name="send"></ion-icon>
                    </button>
                </form>
            </div>
        </>
    )
}

export default SupportTickets