import React from 'react'
import { connect } from 'react-redux'
import { showOffcanvas, showButtonModal, newButtonData, showDeleteModal } from '../../features/modal/offcanvasSlice'

const PL_BUTN_DFL1 = ({
    properties,
    items,
    blockType,
    blockID,
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal
}) => {
    const handleBlockUpdate = (msg) => {
        console.log('CLICKED')
        showOffcanvas({ isVisible: true, items:items, itemCount:'null', properties:properties, blockType: blockType, blockID:blockID, msg:msg})
    }
    function handleShowButtonModal(buttonData, ID) {
        console.log(buttonData)
        handleBlockUpdate(ID)
        setTimeout(() => {
          showButtonModal({
            isButtonModalVisible: true,
            buttonData: buttonData,
            buttonID: ID
          })
        }, [1000])
        newButtonData({ buttonDataObj: buttonData })
    }

    const handleDeleteBlock = (blockName) => {
        showDeleteModal({isDeleteVisible:true, deleteMessage:'Are your sure? You want to delete this button?', triggerMessage:'block', deleteID: blockName})
    }
    return (
        <div>
            <div className="fab-button animate bottom-right dropdown" style={{bottom: '90px'}}>
            <span className="badge badge-danger" style={{right: '-5px',position: 'absolute',top: '-10px',cursor: 'pointer'}} onClick={() => {handleDeleteBlock(blockID)}}><ion-icon name="trash-outline"></ion-icon></span>
                {items[0].button.map((item,key) => {
                    return <a href="javascript:void(0)" className="fab" onClick={() => handleShowButtonModal(items[0].button,key)}>
                        {item['type'] === 'call' &&
                            <ion-icon name="call-outline"></ion-icon>
                        }
                        {item['type'] === 'page' &&
                            <ion-icon name="document-text-outline"></ion-icon>
                        }
                        {item['type'] === 'image' &&
                            <ion-icon name="image-outline"></ion-icon>
                        }
                        {item['type'] === 'external' &&
                            <ion-icon name="open-outline"></ion-icon>
                        }
                        {item['type'] === 'document' &&
                            <ion-icon name="document-outline"></ion-icon>
                        }
                        {item['type'] === 'email' &&
                            <ion-icon name="mail-outline"></ion-icon>
                        }
                        {item['type'] === 'whatsapp' &&
                            <ion-icon name="logo-whatsapp"></ion-icon>
                        }
                        {item['type'] === 'payment' &&
                            <ion-icon name="card-outline"></ion-icon>
                        }
                    </a> 
                })}
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}
  
const mapDispatchToProps = {
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal
}

export default connect(mapStateToProps,mapDispatchToProps)(PL_BUTN_DFL1)
