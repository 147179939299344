import React, { useState, useEffect } from 'react'
import MDEditor, { bold, commands, italic, image, unorderedListCommand, orderedListCommand, checkedListCommand, link, title } from '@uiw/react-md-editor'
import classNames from 'classnames'
import { connect } from "react-redux"
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { useNavigate, useLocation } from 'react-router-dom'
import Nestable from 'react-nestable'
import 'react-nestable/dist/styles/index.css'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { showImageModal, newImageData, setWhereToUpdateImage, setContentBlockItem, setMainBlockProps, setContentItems, getImageType, showButtonModal, newButtonData, setFlagsToCheckChanges, showNavigationOffcanvas, setReorderItems, hideEditOffcanvas, showFormOffcanvas } from '../../../features/modal/offcanvasSlice';
import ButtonDetailsOnEditItem from '../Comps/ButtonDetailsOnEditItem';


const ContentTab = ({
    items = [],
    itemID,
    ContentBlockItem,
    properties,
    blockID,
    blockType,
    websiteDataBlocks,
    blockRules,
    ContentItems,
    setContentBlockItem,
    setMainBlockProps,
    MainBlockProps,
    NewBlockType,
    showImageModal,
    newImageData,
    imageData,
    isImageModalVisible,
    WhereToUpdateImage,
    ContentItemsArray,
    setWhereToUpdateImage,
    showButtonModal,
    newButtonData,
    buttonDataObj,
    isButtonModalVisible,
    setFlagsToCheckChanges,
    FlagsToCheckChanges,
    showNavigationOffcanvas,
    setReorderItems,
    reorderItems,
    isNavigationVisible,
    isEditVisible,
    hideEditOffcanvas,
    showFormOffcanvas,
    addForm,
    setTabReturn
}) => {
    let navigate = useNavigate()
    const [blockStyleCode, setBlockStyleCode] = useState('')
    const [visibleField, setVisibleField] = useState([]);

    const [ReorderItemSection, setReorderItemSection] = useState(null);

    const [ImageItemToReorder, setImageItemToReorder] = useState([]);
    const [ButtonItemToReorder, setButtonItemToReorder] = useState([]);

    const [SubTitleValue, setSubTitleValue] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');


    const [innerPageValue, setInnerPageValue] = useState('false');
    const [tempUrl, setTempUrl] = useState('');

    const [urlAccepted, setUrlAccepted] = useState(null);

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    const [additionalDetailsValue, setAdditionalDetailsValue] = useState({
        'Period': {
            'startDate': null,
            'endDate': null
        },
        'Location': {
            'title': '',
            'latlong': {}
        },
        'Amount': {
            'currency': 'INR',
            'amount': '0'
        }
    });

    const getItemObject = () => { return JSON.parse(JSON.stringify(ContentBlockItem)) };

    const changeFlagsToCheckChanges = (fieldName) => {
        const tempFlagsToCheckChanges = JSON.parse(JSON.stringify(FlagsToCheckChanges));
        if (tempFlagsToCheckChanges[fieldName] !== undefined && tempFlagsToCheckChanges[fieldName] === false) {
            tempFlagsToCheckChanges[fieldName] = true;
            setFlagsToCheckChanges(tempFlagsToCheckChanges);
        }
    }

    const onTitleChange = (e) => {
        const ItemData = getItemObject();
        ItemData.title = e.target.value;
        changeFlagsToCheckChanges('title');
        setContentBlockItem(ItemData);
    }
    const onURLChange = (e) => {
        const ItemData = getItemObject();
        ItemData.url = e.target.value;
        changeFlagsToCheckChanges('url');
        setContentBlockItem(ItemData);
    }
    const onSubTitleChange = (e) => {
        setSubTitleValue(e)
        const ItemData = getItemObject();
        ItemData.subtitle = e;
        setContentBlockItem(ItemData);
        changeFlagsToCheckChanges('subtitle');
    }

    const onDescriptionChange = (e) => {
        setDescriptionValue(e)
        const ItemData = getItemObject();
        ItemData.description = e;
        setContentBlockItem(ItemData);
        changeFlagsToCheckChanges('description');
    }

    const onAdditionalDetailChange = (val, p1, c1) => {
        const tempValue = JSON.parse(JSON.stringify(additionalDetailsValue));
        tempValue[p1][c1] = val;
        setAdditionalDetailsValue(tempValue);
        const ItemData = getItemObject();
        ItemData.additionalDetails = tempValue;
        setContentBlockItem(ItemData);
        changeFlagsToCheckChanges('additionalDetails');
    }

    const deleteImage = (i) => {
        const ItemData = getItemObject();
        ItemData.image.splice(i, 1);
        setContentBlockItem(ItemData);
        changeFlagsToCheckChanges('image');
    }

    const deleteButton = (e, i) => {
        e.preventDefault();
        const ItemData = getItemObject();
        // console.log(ItemData.button);
        ItemData.button.splice(i, 1);
        setContentBlockItem(ItemData);
        changeFlagsToCheckChanges('button');
    }

    useEffect(() => {
        if (blockID !== '' && blockID !== undefined) {
            setBlockStyleCode(websiteDataBlocks[blockID].properties.style)
        }
    }, [websiteDataBlocks, blockID])

    // const setBlockData = () => {
    //     document.getElementsByName('v2blockHeading')[0].value = properties.blockHeading
    //     document.getElementsByName('v2blockDescription')[0].value = properties.blockDescription
    // }

    // useEffect(() => {
    //     if (properties !== undefined) {
    //         if (blockStyleCode !== 'SM_HEAD' && blockStyleCode !== 'SM_FOOT') {
    //             // setBlockData()
    //         }
    //     }
    // }, [properties]);

    useEffect(() => {
        if (blockID !== '' && blockID !== undefined) {
            const blockStyle = websiteDataBlocks[blockID].properties.style

            if (blockRules <= 0) {
                navigate('/load')
            } else {
                if (blockRules.hasOwnProperty(blockStyle)) {
                    setVisibleField(blockRules[blockStyle].fields)
                } else {
                    setVisibleField(blockRules['CN'].fields)
                }
            }
        }
    }, [blockID, blockRules]);

    // const setInputValuesSmart = () => {
    //     items.map((value, key) => {
    //       if (blockStyleCode === 'SM_FOOT') {
    //         // if (visibleField.includes('image')) {
    //         //   document.getElementsByName('v2image' + key)[0].value = value.image
    //         // } 
    //         // if (visibleField.includes('button')) {
    //         //   document.getElementsByName('v2button' + key)[0].value = value.button
    //         // } 
    //         if (visibleField.includes('title')) {
    //           document.getElementsByName('v2title' + key)[0].value = value.title
    //         }
    //       } else {
    //         if (value.form === '') {
    //           if (visibleField.includes('title')) {
    //             document.getElementsByName('v2title' + key)[0].value = value.title
    //             console.log(value.title)
    //           }
    //         //   if (visibleField.includes('image')) {
    //         //     document.getElementsByName('v2image' + key)[0].value = value.image
    //         //   }
    //         //   if (visibleField.includes('button')) {
    //         //     document.getElementsByName('v2button' + key)[0].value = value.button
    //         //   }
    //         //   if (visibleField.includes('url')) {
    //         //     document.getElementsByName('v2url' + key)[0].value = value.url
    //         //   }
    //         }
    //       }
    //     })
    // }

    // const setInputValues = () => {
    // document.getElementsByName('v2title')[0].value = items[itemID].title
    // document.getElementsByName('v2subtitle')[0].value = items[itemID].subtitle
    // document.getElementsByName("descriptions")[0].value = items[itemID].description
    // document.getElementsByName("ur")[0].value = items[itemID].url
    // document.getElementsByName("image")[0].value = items[itemID].image
    // }


    function handleShowImageModal(imageData, ID, blockType, ItemOrBg) {
        // console.log(JSON.stringify(imageData), ID);
        const imageType = getImageType(blockType);
        setWhereToUpdateImage({
            blockType: NewBlockType.BlockType,
            ItemOrBg: ItemOrBg,
            ItemPosition: ID
        })
        showImageModal({
            isImageModalVisible: true,
            imageData: imageData,
            imageID: ID,
            imageType: imageType
        });
        newImageData({ imageDataObj: imageData });
    }

    useEffect(() => {
        // console.log({ WhereToUpdateImage, imageData, NewBlockType });
        if (NewBlockType !== null && NewBlockType.BlockType !== undefined && NewBlockType.BlockType !== null && (NewBlockType.BlockType === 'CN' || NewBlockType.BlockType === 'SM')) {
            if (!isImageModalVisible && imageData !== undefined && imageData !== null && imageData.length !== 0 && WhereToUpdateImage !== null && WhereToUpdateImage !== undefined) {

                if (WhereToUpdateImage.ItemOrBg === 'Item') {
                    const ItemData = getItemObject();
                    // if (imageData.length <= WhereToUpdateImage.ItemPosition + 1) {
                    console.log(ItemData);
                    if (ItemData.image !== null && ItemData.image.length - 1 < WhereToUpdateImage.ItemPosition) {
                        // const tempImgObj = {
                        //     src: imageData[0].src,
                        //     alt: imageData[0].alt,
                        //     style: (imageData[0].style !== undefined && imageData[0].style !== null) ? imageData[0].style : ''
                        // }
                        const tempImgObj = JSON.parse(JSON.stringify(imageData[0]));
                        delete tempImgObj.id;
                        delete tempImgObj.children;
                        ItemData.image.push(tempImgObj);
                    } else {
                        // ItemData.image[WhereToUpdateImage.ItemPosition].src = imageData[0].src;
                        // ItemData.image[WhereToUpdateImage.ItemPosition].alt = imageData[0].alt;
                        // ItemData.image[WhereToUpdateImage.ItemPosition].style = (imageData[0].style !== undefined && imageData[0].style !== null) ? imageData[0].style : '';
                        const tempImgObj = JSON.parse(JSON.stringify(imageData[0]));
                        delete tempImgObj.id;
                        delete tempImgObj.children;
                        ItemData.image[WhereToUpdateImage.ItemPosition] = tempImgObj;
                    }
                    newImageData({ imageDataObj: [] })
                    setContentBlockItem(ItemData);
                    changeFlagsToCheckChanges('image');
                    setWhereToUpdateImage(null)
                }

                if (WhereToUpdateImage.ItemOrBg === 'ImageUploadToSubtitle') {
                    const ItemData = getItemObject();
                    // if (imageData.length <= WhereToUpdateImage.ItemPosition + 1) {
                    console.log(ItemData);
                    if(imageData.length > 0){
                        const tempImgObj = imageData[0].src;
                        ItemData.subtitle = `${ItemData.subtitle} ![](${tempImgObj})`;
                        setSubTitleValue(`${ItemData.subtitle} ![](${tempImgObj})`)
                        changeFlagsToCheckChanges('subtitle');
                    }
                    
                    newImageData({ imageDataObj: [] })
                    setContentBlockItem(ItemData);
                    setWhereToUpdateImage(null)
                }

                if (WhereToUpdateImage.ItemOrBg === 'ImageUploadToDescription') {
                    const ItemData = getItemObject();
                    // if (imageData.length <= WhereToUpdateImage.ItemPosition + 1) {
                    console.log(ItemData);
                    if(imageData.length > 0){
                        const tempImgObj = imageData[0].src;
                        ItemData.description = `${ItemData.description} ![](${tempImgObj})`;
                        setDescriptionValue(`${ItemData.description} ![](${tempImgObj})`);
                        changeFlagsToCheckChanges('description');
                    }
                    
                    newImageData({ imageDataObj: [] })
                    setContentBlockItem(ItemData);
                    setWhereToUpdateImage(null)
                }

            }
        }
    }, [imageData, isImageModalVisible])


    // const collectData = () => {
    //     if (itemID !== undefined && itemID !== -1) {
    //         setInputValues()
    //     } else {
    //         if (blockType === 'SM') {
    //             // if (blockStyleCode !== 'SM_HEAD' && blockStyleCode !== 'SM_FOOT') {
    //             setInputValuesSmart()
    //             // }
    //         }
    //     }
    // }

    function handleShowButtonModal(e, buttonData, ID) {
        e.preventDefault();
        // console.log([buttonData], ID)
        if (ID === 'new') {
            showButtonModal({
                isButtonModalVisible: true,
                buttonData: (buttonData !== null && buttonData.length !== 0) ? buttonData : [],
                buttonID: buttonData !== null ? buttonData.length : 0
            })
        } else {
            showButtonModal({
                isButtonModalVisible: true,
                buttonData: buttonData,
                buttonID: ID
            })
        }
    }

    useEffect(() => {
        if (NewBlockType !== null && NewBlockType.BlockType !== undefined && (NewBlockType.BlockType === 'CN' || NewBlockType.BlockType === 'SM')) {
            if (!isButtonModalVisible && buttonDataObj !== null) {
                // console.log('run')
                const ItemData = getItemObject();
                ItemData.button = buttonDataObj;
                setContentBlockItem(ItemData);
                changeFlagsToCheckChanges('button');
                newButtonData({ buttonDataObj: null })
            }
        }
    }, [buttonDataObj, isButtonModalVisible])

    const handleReorder = (itemArrays, itemSection) => {
        showNavigationOffcanvas({ isNavigationVisible: true, blockID: blockID, triggerMessage: itemSection });
        const temparray = itemArrays.map((item, i) => {
            const tempItem = JSON.parse(JSON.stringify(item));
            tempItem.id = i;
            return tempItem;
        })
        setReorderItemSection(itemSection);
        setReorderItems(temparray);
    }

    const handleNavigation = () => {
        showNavigationOffcanvas({ isNavigationVisible: true, triggerMessage: '', blockID: NewBlockType.BlockID })
        hideEditOffcanvas()
    }

    useEffect(() => {
        if (!isNavigationVisible && reorderItems.length !== 0 && ReorderItemSection !== null) {
            const ItemData = getItemObject();
            if (ReorderItemSection === 'ButtonReorder') {
                ItemData.button = reorderItems;
                changeFlagsToCheckChanges('button');
            } else if (ReorderItemSection === 'ImageReorder') {
                ItemData.image = reorderItems;
                changeFlagsToCheckChanges('image');
            }
            setContentBlockItem(ItemData);
            setReorderItems([]);
            setReorderItemSection(null);
        }
    }, [isNavigationVisible])

    useEffect(() => {
        // console.log(ContentBlockItem, isEditVisible)
        if (isEditVisible && ContentBlockItem !== null && ContentBlockItem.image.length !== 0 && ContentBlockItem.image.length > 1) {
            const ItemData = getItemObject();
            const imageList = ItemData.image.map((item, i) => {
                item.id = i
                return item
            })
            // console.log(imageList)
            setImageItemToReorder(imageList);
        } else {
            setImageItemToReorder([]);
        }
        if (isEditVisible && ContentBlockItem !== null && ContentBlockItem.button !== null && ContentBlockItem.button.length !== 0 && ContentBlockItem.button.length > 1) {
            const ItemData = getItemObject();
            const buttonList = ItemData.button.map((item, i) => {
                item.id = i
                return item
            })
            // console.log(buttonList)
            setButtonItemToReorder(buttonList);
        } else {
            setButtonItemToReorder([]);
        }

        if (ContentBlockItem !== null) {
            if (ContentBlockItem.subtitle !== SubTitleValue) {
                setSubTitleValue(ContentBlockItem.subtitle)
            }

            if (ContentBlockItem.description !== descriptionValue) {
                setDescriptionValue(ContentBlockItem.description)
            }

            if (ContentBlockItem.additionalDetails !== undefined && ContentBlockItem.additionalDetails !== additionalDetailsValue) {
                setAdditionalDetailsValue(ContentBlockItem.additionalDetails)
            }
        }
    }, [isEditVisible, ContentBlockItem])

    const renderSitemap = ({ item, index, depth }) => {
        const imageStyles = item.style !== undefined ? item.style : ''
        return (
            <div className="col-12 col-md-12">
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <div className="d-flex justify-content-center align-items-center position-relative">
                            {item.src !== undefined &&
                                <>
                                    <button className='use-button' type='button'
                                        onClick={() =>
                                            handleShowImageModal(
                                                [item],
                                                index,
                                                NewBlockType.BlockName,
                                                'Item'
                                            )
                                        }>
                                        <img
                                            src={item.src}
                                            alt={item.alt}
                                            className={`imaged w-100 border border-primary border-2 cursor-move ${imageStyles}`}
                                        />
                                    </button>
                                    <div className='badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer' onClick={() => deleteImage(index)}>
                                        <ion-icon name="trash-outline" className="md trash" aria-label="Delete"></ion-icon>
                                    </div>
                                    {
                                        item.videoUrl && item.videoUrl.length > 0 &&
                                        <div className='badge position-absolute s-n1 t-n1 z-index-10 bg-secondary'>
                                            <ion-icon name="videocam-outline" className="md trash"></ion-icon>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderButtonSitemap = ({ item, index, depth }) => {
        return (
            <li key={index} className="px-0 border-bottom cursor-move">
                <div className="btnlist item ps-0 py-0">
                    <div className='d-flex w-100 py-2'>
                        <div className='d-flex w-100 justify-content-between align-items-center'>
                            <div className='position-relative'>
                                <button type='button' className={`btn btn-sm fs-10 ${(item.style !== undefined && item.style.length !== 0) ? item.style : 'btn-bg-muted'}`} onClick={(e) =>
                                    handleShowButtonModal(e, ContentBlockItem.button, index)}>{item.title}</button>

                                {/* <div className='p-1 cursor-pointer' onClick={(e) =>
                                    handleShowButtonModal(e, ContentBlockItem.button, index)}>
                                    Edit
                                </div> */}
                                <button type='button' className='badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer border-0' onClick={(e) => deleteButton(e, index)}>
                                    <ion-icon name="trash-outline" className="md trash" aria-label="Delete"></ion-icon>
                                </button>
                                {/* <div className='cursor-pointer' onClick={(e) => deleteButton(e, index)}>
                                    <ion-icon size="small" className="m-1 fs-10 md icon-small hydrated" className="m-0" name="trash-outline"></ion-icon>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </li>
        )
    }

    const changeImageReorder = (reorderList) => {
        const ItemData = getItemObject();
        ItemData.image = reorderList;
        changeFlagsToCheckChanges('image');
        setContentBlockItem(ItemData);
        setImageItemToReorder(reorderList)
    }

    const changeButtonReorder = (reorderList) => {
        const ItemData = getItemObject();
        ItemData.button = reorderList;
        changeFlagsToCheckChanges('button');
        setContentBlockItem(ItemData);
        setButtonItemToReorder(reorderList)
    }

    const search = useLocation().search
    const url = new URLSearchParams(search).get('url')
    let titleToUrl = ''
    const handleInnerPageSwitch = (e) => {
        setInnerPageValue(e.target.value)
        if (e.target.value === 'true') {
            titleToUrl = ContentBlockItem.title.replace(/[^\w\s]|_/g, '').replace(/\s+/g, '-').toLowerCase()
            setTimeout(function () {
                document.getElementById('innerPageUrl').value = url + '/' + titleToUrl
            }, 2000);
            setTempUrl(url + '/' + titleToUrl)
            // console.log(url, titleToUrl)
        }
    }

    const validateUrl = (value) => {
        axios.post('https://api.webweb.ai/appearance/checkUniqueUrl/' + selectedDomain, { url: value }).then(res => {
            if (res.data.message === 'Success') {
                console.log(res.data.data)
                if (res.data.data === 'Url Accepted') {
                    setUrlAccepted(true)
                } else if (res.data.data === 'Url Not Accepted') {
                    setUrlAccepted(false)
                }
                console.log(urlAccepted)
            } else {
                console.log('URL API ISSUE')
            }
        })
    }

    const editForm = (formID) => {
        if (formID === undefined || formID === null) {
            // showFormOffcanvas({ isFormVisible : true, formID : 'LEAD1'});
            addForm();
        } else {
            showFormOffcanvas({ isFormVisible: true, formID: formID });
        }
    }
    // console.log({blockType});


    const [fieldsListening, setFieldsListening] = useState(null);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();


    function startSpeechRecognition(FieldName) {
        SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });
        setFieldsListening(FieldName);
    }

    function stopSpeechRecognition(FieldName) {
        SpeechRecognition.stopListening()
        const itemData = getItemObject();
        itemData[FieldName] = `${itemData[FieldName]} ${transcript}`;
        setContentBlockItem(itemData);
        changeFlagsToCheckChanges(FieldName);
        setTimeout(() => {
            resetTranscript();
        }, 100);
        setFieldsListening(null);
    }


    const isSpeechRecognitionDoable = (FieldName) => {
        if (!browserSupportsSpeechRecognition) {
            return (<span className='fs-16' title='Voice to Text is not supported on this browser!'>
                <ion-icon name="mic-off-outline"></ion-icon>
            </span>)
        } else {
            return (
                <>
                    { 
                        listening && FieldName === fieldsListening ?
                            <button type='button' className='speaker-on speaker-btn' title='Stop Listening' onClick={() => stopSpeechRecognition(FieldName)}>
                                <div class="pulse-ring" />
                                <div className='listening-animation'>
                                    <div className='sides border-circle' />
                                    <div className='center border-circle' />
                                    <div className='sides border-circle' />
                                </div>
                                <ion-icon name="mic-off-outline" class="off-mic" style={{ cursor: 'pointer' }}></ion-icon>
                            </button>
                            :
                            <button type='button' className='speaker-off speaker-btn' title='Type by Voice' disabled={listening && FieldName !== fieldsListening} onClick={() => startSpeechRecognition(FieldName)}>
                                <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                            </button>

                    }
                </>
            )
        }
    }

    
    const help = {
        name: "help",
        keyCommand: "help",
        buttonProps: { "aria-label": "Insert help" },
        icon: (
            <svg viewBox="0 0 16 16" width="12px" height="12px">
                <path
                    d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm.9 13H7v-1.8h1.9V13Zm-.1-3.6v.5H7.1v-.6c.2-2.1 2-1.9 1.9-3.2.1-.7-.3-1.1-1-1.1-.8 0-1.2.7-1.2 1.6H5c0-1.7 1.2-3 2.9-3 2.3 0 3 1.4 3 2.3.1 2.3-1.9 2-2.1 3.5Z"
                    fill="currentColor"
                />
            </svg>
        ),
        execute: (state, api) => {
            window.open("https://www.markdownguide.org/basic-syntax/", "_blank");
        }
    };


    const ImageUploadToSubtitle = {
        name: "ImageUpload",
        keyCommand: "ImageUpload",
        buttonProps: { "aria-label": "Image Upload" },
        icon: (
            <svg viewBox="0 0 16 16" width="12px" height="12px">
                <path
                    d="M15 9c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4-7H1c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm-1 13l-6-5-2 2-4-5-4 8V4h16v11z"
                    fill="currentColor"
                />
            </svg>
        ),
        execute: (state, api) => handleShowImageModal(
                [],
                0,
                NewBlockType.BlockName,
                'ImageUploadToSubtitle'
            )
        
    };

    const ImageUploadToDescription = {
        name: "ImageUpload",
        keyCommand: "ImageUpload",
        buttonProps: { "aria-label": "Image Upload" },
        icon: (
            <svg viewBox="0 0 16 16" width="12px" height="12px">
                <path
                    d="M15 9c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4-7H1c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm-1 13l-6-5-2 2-4-5-4 8V4h16v11z"
                    fill="currentColor"
                />
            </svg>
        ),
        execute: (state, api) => handleShowImageModal(
                [],
                0,
                NewBlockType.BlockName,
                'ImageUploadToDescription'
            )
        
    };

    return (
        // onLoad={collectData}
        <div>
            {
                NewBlockType !== null && NewBlockType.BlockType !== undefined &&
                <>
                    <form>
                        {/* Image */}
                        {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('image') &&
                            <div className="row">
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="name1">Media</label>
                                                </div>
                                            </div>
                                        </div>
                                        {ContentBlockItem !== null && ContentBlockItem.image !== null &&
                                            <div className='col-8 d-flex justify-content-end cursor-pointer d-flex align-items-center'>
                                                <button type='button' className='badge bg-transparent btn-link d-flex cursor-pointer me-1 border-0' onClick={() => setTabReturn('Media')}>
                                                    <ion-icon name="brush"></ion-icon>
                                                    <span className='ms-1'>Customise</span>
                                                </button>
                                                {
                                                    (ContentBlockItem.image.length === 0 || ContentBlockItem.image.length < blockRules[NewBlockType.BlockName].maxImage) &&
                                                    <button type='button' className="badge bg-primary cursor-pointer border-0" onClick={() =>
                                                        handleShowImageModal(
                                                            [],
                                                            ContentBlockItem.image.length,
                                                            NewBlockType.BlockName,
                                                            'Item'
                                                        )
                                                    }>
                                                        <ion-icon name="add-outline" className="md hydrated" aria-label="image outline"></ion-icon>
                                                        <span>Add</span>
                                                    </button>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                {ContentBlockItem !== null && ContentBlockItem.image !== null && ContentBlockItem.image.length !== 0 &&
                                    <>
                                        {
                                            ContentBlockItem.image.length > 1 ?
                                                <div className='nestable-grid'>
                                                    <Nestable
                                                        items={ImageItemToReorder}
                                                        renderItem={renderSitemap}
                                                        maxDepth={1}
                                                        handler={false}
                                                        onChange={newItems => {
                                                            const updatedItems = newItems.items
                                                            // console.log(updatedItems)
                                                            changeImageReorder(updatedItems)
                                                        }}
                                                    />
                                                </div>
                                                :
                                                ContentBlockItem.image.map((imgitm, i) => {
                                                    const imageStyles = imgitm.style !== undefined ? imgitm.style : ''
                                                    return <div className="col-4 col-md-4" key={i}>
                                                        <div className="form-group basic">
                                                            <div className="input-wrapper">
                                                                <div className="d-flex justify-content-center align-items-center position-relative">
                                                                    {imgitm.src !== undefined &&
                                                                        <>
                                                                            <button type='button' className='use-button'
                                                                                onClick={() =>
                                                                                    handleShowImageModal(
                                                                                        [imgitm],
                                                                                        i,
                                                                                        NewBlockType.BlockName,
                                                                                        'Item'
                                                                                    )
                                                                                }>
                                                                                <img
                                                                                    src={imgitm.src}
                                                                                    alt={imgitm.alt}
                                                                                    className={`imaged w-100 border border-primary border-2 cursor-pointer ${imageStyles}`}
                                                                                />
                                                                            </button>
                                                                            <div className='badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer' onClick={() => deleteImage(i)}>
                                                                                <ion-icon name="trash-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                                            </div>
                                                                            {
                                                                                imgitm.videoUrl && imgitm.videoUrl.length > 0 &&
                                                                                <div className='badge position-absolute s-n1 t-n1 z-index-10 bg-info'>
                                                                                    <ion-icon name="videocam-outline" className="md trash"></ion-icon>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                        }
                                    </>
                                }
                                {ContentBlockItem !== null && ContentBlockItem.image !== null && ContentBlockItem.image.length === 0 &&
                                    <h5 className='py-2 mb-0 text-muted'>No Media Added</h5>
                                }
                            </div>
                        }

                        {/* Title */}
                        {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('title') &&
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <div className="row align-items-center">
                                        <div className="col-6 col-md-6">
                                            <label className="form-label" htmlFor={'v2title'}>Title</label>
                                        </div>
                                        <div className="col-6 col-md-6 text-end text-primary d-flex align-items-end">
                                            <button type='button' className='badge bg-transparent btn-link d-flex cursor-pointer me-1 border-0' onClick={() => setTabReturn('Title')}>
                                                <ion-icon name="brush"></ion-icon>
                                                <span className='ms-1'>Customise</span>
                                            </button>
                                            {isSpeechRecognitionDoable('title')}
                                        </div>
                                    </div>
                                    {/* <input type="text" className="form-control" id={'v2title'} name={'v2title'} placeholder="Enter Title" /> */}
                                    {
                                        listening && fieldsListening === 'title' ?
                                            <div className="h6">{ContentBlockItem !== null && ContentBlockItem.title} {transcript}</div>
                                            :
                                            <input type="text" className="form-control" id={'v2title'} name={'v2title'} placeholder='Enter Title' value={ContentBlockItem !== null && ContentBlockItem.title} onChange={(e) => { onTitleChange(e) }} />
                                    }
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        }

                        {/* Sub Title */}
                        {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('subtitle') &&
                            <div className="form-group basic">
                                <div className="input-wrapper custom-subtitle">
                                    <div className="row align-items-center mb-1">
                                        <div className="col-6 col-md-6">
                                            <label className="form-label" htmlFor="contentSubtitle">Subtitle</label>
                                        </div>
                                        <div className="col-6 col-md-6 text-end text-primary d-flex align-items-center">
                                            <button type='button' className='badge bg-transparent btn-link d-flex cursor-pointer me-1 border-0' onClick={() => setTabReturn('Subtitle')}>
                                                <ion-icon name="brush"></ion-icon>
                                                <span className='ms-1'>Customise</span>
                                            </button>
                                            {isSpeechRecognitionDoable('subtitle')}
                                        </div>
                                    </div>
                                    {
                                        listening && fieldsListening === 'subtitle' ?
                                            <>
                                                <div className="h6">{SubTitleValue} {transcript}</div>
                                            </>
                                            :
                                            <MDEditor height={200} value={SubTitleValue} onChange={(e) => { onSubTitleChange(e) }} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, ImageUploadToSubtitle, link, help]} extraCommands={[]} preview="edit" style={{ backgroundColor: 'white', color: '#333333' }} />
                                    }
                                </div>
                            </div>
                        }

                        {/* Description */}
                        {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('description') &&
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <div className="row align-items-center mb-1">
                                        <div className="col-6 col-md-6">
                                            <label className="form-label" htmlFor="contentDescription">Description</label>
                                        </div>
                                        <div className="col-6 col-md-6 text-end text-primary d-flex align-items-center">
                                            <button type='button' className='badge bg-transparent btn-link d-flex cursor-pointer me-1 border-0' onClick={() => setTabReturn('Description')}>
                                                <ion-icon name="brush"></ion-icon>
                                                <span className='ms-1'>Customise</span>
                                            </button>
                                            {isSpeechRecognitionDoable('description')}
                                        </div>
                                    </div>
                                    {
                                        listening && fieldsListening === 'description' ?
                                            <>
                                                <div className="h6">{descriptionValue} {transcript}</div>
                                            </>
                                            : 
                                            <MDEditor value={descriptionValue} onChange={(e) => { onDescriptionChange(e) }} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, ImageUploadToDescription, link]} extraCommands={[]} preview='edit' style={{ backgroundColor: 'white', color: '#333333' }} />
                                    }
                                </div>
                            </div>
                        }

                        {/* Button */}
                        {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('button') &&
                            <>
                                <div className='col-12 mt-1'>
                                    <div className='row'>
                                        <div className='col-4 ps-0'>
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="name1">Buttons / Links</label>
                                                </div>
                                            </div>
                                        </div>
                                        {ContentBlockItem !== null && <>
                                            <div className='col-8 d-flex justify-content-end cursor-pointer d-flex align-items-center'>
                                                <button type='button' className='badge bg-transparent btn-link d-flex cursor-pointer me-1 border-0' onClick={() => setTabReturn('Buttons/Links')}>
                                                    <ion-icon name="brush"></ion-icon>
                                                    <span className='ms-1'>Customise</span>
                                                </button>
                                                {
                                                    (ContentBlockItem.button === null || (ContentBlockItem.button !== null && (ContentBlockItem.button.length === 0 || ContentBlockItem.button.length < blockRules[NewBlockType.BlockName].maxButton))) &&
                                                    <button type='button' className="badge bg-primary cursor-pointer border-0" onClick={(e) =>
                                                        handleShowButtonModal(e, ContentBlockItem.button, 'new')
                                                    }>
                                                        <ion-icon name="add-outline" className="md hydrated" aria-label="image outline"></ion-icon>
                                                        <span className='fs-8'>Add</span>
                                                    </button>
                                                }
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('button') &&
                            <ul className="listview link-listview mx-1" style={{ marginLeft: '-16px', marginRight: '-16px' }}>
                                {ContentBlockItem !== null && ContentBlockItem.button !== null && ContentBlockItem.button.length !== 0 &&
                                    <>
                                        {ContentBlockItem.button.length > 1 ?
                                            <Nestable
                                                items={ButtonItemToReorder}
                                                renderItem={renderButtonSitemap}
                                                maxDepth={1}
                                                handler={false}
                                                onChange={newItems => {
                                                    const updatedItems = newItems.items
                                                    // console.log(updatedItems)
                                                    changeButtonReorder(updatedItems)
                                                }}
                                            />
                                            :
                                            ContentBlockItem.button.map((btnitem, i) => {
                                                return (
                                                    <>
                                                        <li key={i} className="px-0 border-bottom cursor-move">
                                                            <div className="btnlist item ps-0 py-0">
                                                                <div className='d-flex w-100 py-2'>
                                                                    <div className='d-flex w-100 justify-content-between align-items-center'>
                                                                        <div className='position-relative'>
                                                                            <button className={`btn btn-sm fs-10 ${(btnitem.style !== undefined && btnitem.style.length !== 0) ? btnitem.style : 'btn-bg-muted'}`} onClick={(e) =>
                                                                                handleShowButtonModal(e, ContentBlockItem.button, i)}>{btnitem.title}</button>


                                                                            <button type="button" className='badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer border-0' onClick={(e) => deleteButton(e, i)}>
                                                                                <ion-icon name="trash-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                }
                                {ContentBlockItem !== null && ContentBlockItem.button !== null && ContentBlockItem.button.length === 0 &&
                                    <h5 className='py-2 mb-0 text-muted'>No Button/Links Added</h5>
                                }
                            </ul>
                        }

                        {/* URL */}
                        {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('url') &&
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label
                                        className="form-label mb-0"
                                        htmlFor="title"
                                    >
                                        <div className="row">
                                            <div className="col-6 col-md-6">
                                                URL
                                            </div>
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="url"
                                        name='url'
                                        value={ContentBlockItem !== null && ContentBlockItem.url} onChange={(e) => { onURLChange(e) }}
                                        placeholder="Enter url"
                                        autoComplete="off"
                                    />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        }

                        {/* Additional Details */}
                        {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('additionalDetails') &&
                            <div className="form-group basic">
                                <div className="accordion" id="accordionExample1">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed ps-0" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#accordion1">
                                                Additional Details
                                            </button>
                                        </h2>
                                        <div id="accordion1" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                            <div className="accordion-body px-0">
                                                <div className='row mb-1'>
                                                    <div className='col-md-6'>
                                                        <label className="form-label" htmlFor='startDate'>From Date</label>
                                                        <input type="date" placeholder="dd-mm-yyyy" className="form-control" value={additionalDetailsValue.Period.startDate} name='startDate' id='startDate' onChange={(e) => onAdditionalDetailChange(e.target.value, 'Period', 'startDate')} />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="form-label" htmlFor='endDate'>End Date</label>
                                                        <input type="date" placeholder="dd-mm-yyyy" className="form-control" value={additionalDetailsValue.Period.endDate} name='endDate' id='endDate' onChange={(e) => onAdditionalDetailChange(e.target.value, 'Period', 'endDate')} />
                                                    </div>
                                                </div>
                                                <div className='row mb-1'>
                                                    <div className='col-md-12'>
                                                        <label className="form-label" htmlFor='Location'>Location</label>
                                                        <input type="text" placeholder="Please enter location" value={additionalDetailsValue.Location.title} className="form-control" name='Location' id='Location' onChange={(e) => onAdditionalDetailChange(e.target.value, 'Location', 'title')} />
                                                        <input type="text" hidden className='form-control' value={JSON.stringify(additionalDetailsValue.Location.latlong)} id="latLong" />
                                                    </div>
                                                </div>
                                                <div className='row mb-1'>
                                                    <div className='col-md-12'>
                                                        <label className="form-label" htmlFor='amount'>Amount</label>
                                                        <div className='row'>
                                                            <div className='col-md-3'>
                                                                <input type="currency" placeholder="Please enter location" className="form-control" readOnly value={additionalDetailsValue.Amount.currency} name='currency' id='currency' onChange={(e) => onAdditionalDetailChange(e.target.value, 'Amount', 'currency')} />
                                                            </div>
                                                            <div className='col-md-9'>
                                                                <input type="number" placeholder="Please enter amount" className="form-control" name='amount' value={additionalDetailsValue.Amount.amount} id='amount' onChange={(e) => onAdditionalDetailChange(e.target.value, 'Amount', 'amount')} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        }

                        {/* Create New Page */}
                        {NewBlockType !== null && NewBlockType.BlockName === 'CN_LIST' &&
                            <div className="mt-1">
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label
                                            className="form-label mb-0"
                                            htmlFor="ineerPage"
                                        >
                                            <div className="row">
                                                <div className="col-12 col-md-12">
                                                    Create Inner Page?
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ marginBottom: '3px' }}>
                                    <input type="radio" className="btn-check" name="innerPageRadio" id="btnradioq1" value="true" checked={innerPageValue === 'true'} onChange={handleInnerPageSwitch} />
                                    <label className="btn btn-outline-primary btn-sm" htmlFor="btnradioq1">
                                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                                        Yes
                                    </label>

                                    <input type="radio" className="btn-check" name="innerPageRadio" id="btnradioq2" value="false" checked={innerPageValue === 'false'} onChange={handleInnerPageSwitch} />
                                    <label className="btn btn-outline-primary btn-sm" htmlFor="btnradioq2">
                                        <ion-icon name="close-circle-outline"></ion-icon>
                                        No
                                    </label>
                                </div><br />
                                {innerPageValue === 'true' &&
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <h4 className="mb-0 mt-2 text-secondary">https://{selectedDomain}/</h4>
                                            <input type="text" className="form-control" id={'innerPageUrl'} name={'innerPageUrl'} onChange={(e) => { validateUrl(e.target.value) }} placeholder='Enter URL' />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                        {innerPageValue === 'true' &&
                                            <div classNames="row">
                                                <div className="col-12 col-md-12 text-end">
                                                    {(urlAccepted) &&
                                                        <span className="text-success fs-14">Url Accepted</span>
                                                    }
                                                    {(!urlAccepted) &&
                                                        <span className="text-danger fs-14">Url Not Accepted</span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }

                        {/* form edit */}
                        {NewBlockType !== null && NewBlockType.BlockType === 'SM' && (NewBlockType.BlockName === 'SM_FOOT' || NewBlockType.BlockName === 'SM_INFO' || NewBlockType.BlockName === 'SM_CNTC') && NewBlockType.ItemNo === 0 &&
                            <>
                                {
                                    ContentBlockItem !== null && (ContentBlockItem.form === '' || ContentBlockItem.form === null || ContentBlockItem.form === undefined) ?
                                        <div className="form-group basic mt-2">
                                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => editForm()}>
                                                <ion-icon name="add-outline" className="me-1"></ion-icon>
                                                Form Add
                                            </button>
                                        </div>
                                        :
                                        <div className="form-group basic mt-2">
                                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => editForm(ContentBlockItem.form.split('.')[1])}>
                                                <ion-icon name="create-outline" className="me-1"></ion-icon>
                                                Form Edit
                                            </button>
                                        </div>
                                }
                            </>
                        }

                        {/* Naviigation */}
                        {(NewBlockType !== null && NewBlockType.BlockName !== 'SM_CNTC' && (NewBlockType.BlockName === 'SM_HEAD' || NewBlockType.BlockName === 'SM_FOOT')) &&
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label
                                        className="form-label mb-2"
                                        htmlFor="navigation"
                                    >
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                Navigation
                                            </div>
                                        </div>
                                    </label>
                                    <button className="btn btn-secondary btn-sm btn-block" type="button" onClick={handleNavigation}>Manage Navigation</button>
                                </div>
                            </div>
                        }
                    </form>




                    {/* {(NewBlockType.BlockType === 'SM' || NewBlockType.BlockType === 'PL') ? (
                            <>
                                {(NewBlockType !== null && NewBlockType.BlockName !== 'SM_CNTC') ?
                                    <>
                                        {items.map((value, key) => (
                                            <form>
                                                <div>
                                                    <div className="row">
                                                        {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('image') &&
                                                            <>
                                                                <div className='col-12'>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className="form-group basic">
                                                                                <div className="input-wrapper">
                                                                                    <label className="form-label" htmlFor="name1">Media</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {ContentBlockItem !== null && ContentBlockItem.image !== null && (ContentBlockItem.image.length === 0 || ContentBlockItem.image.length < blockRules[NewBlockType.BlockName].maxImage) &&
                                                                            <div className='col-6 d-flex justify-content-end cursor-pointer'>
                                                                                <div className="badge bg-primary cursor-pointer" onClick={() =>
                                                                                    handleShowImageModal(
                                                                                        [],
                                                                                        ContentBlockItem.image.length,
                                                                                        NewBlockType.BlockName,
                                                                                        'Item'
                                                                                    )
                                                                                }>
                                                                                    <ion-icon name="add-outline" className="md hydrated" aria-label="image outline"></ion-icon>
                                                                                    <span>Add Media</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {ContentBlockItem !== null && ContentBlockItem.image !== null && ContentBlockItem.image.length !== 0 &&
                                                                    <>
                                                                        {
                                                                            ContentBlockItem.image.length > 1 ?
                                                                                <div className='nestable-grid'>
                                                                                    <Nestable
                                                                                        items={ImageItemToReorder}
                                                                                        renderItem={renderSitemap}
                                                                                        maxDepth={1}
                                                                                        handler={false}
                                                                                        onChange={newItems => {
                                                                                            const updatedItems = newItems.items
                                                                                            // console.log(updatedItems)
                                                                                            changeImageReorder(updatedItems)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                ContentBlockItem.image.map((imgitm, i) => {
                                                                                    const imageStyles = imgitm.style !== undefined ? imgitm.style : ''
                                                                                    return <div className="col-4 col-md-4" key={i}>
                                                                                        <div className="form-group basic">
                                                                                            <div className="input-wrapper">
                                                                                                <div className="d-flex justify-content-center align-items-center position-relative">
                                                                                                    {imgitm.src !== undefined &&
                                                                                                        <>
                                                                                                            <img
                                                                                                                onClick={() =>
                                                                                                                    handleShowImageModal(
                                                                                                                        [imgitm],
                                                                                                                        i,
                                                                                                                        NewBlockType.BlockName,
                                                                                                                        'Item'
                                                                                                                    )
                                                                                                                }
                                                                                                                src={imgitm.src}
                                                                                                                alt={imgitm.alt}
                                                                                                                className={`imaged w-100 border border-primary border-2 cursor-pointer ${imageStyles}`}
                                                                                                            />
                                                                                                            <div className='badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer' onClick={() => deleteImage(i)}>
                                                                                                                <ion-icon name="trash-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                                                                            </div>
                                                                                                            {
                                                                                                                imgitm.videoUrl && imgitm.videoUrl.length > 0 &&
                                                                                                                <div className='badge position-absolute s-n1 t-n1 z-index-10 bg-info'>
                                                                                                                    <ion-icon name="videocam-outline" className="md trash"></ion-icon>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                })
                                                                        }
                                                                    </>

                                                                }
                                                            </>
                                                        }
                                                    </div>

                                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('title') &&
                                                        <div className="form-group basic">
                                                            <div className="input-wrapper">
                                                                <div className="row">
                                                                    <div className="col-6 col-md-6">
                                                                        <label className="form-label" htmlFor={'v2title'}>Title</label>
                                                                    </div>
                                                                    <div className="col-6 col-md-6 text-end text-primary">
                                                                        <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                                                    </div>
                                                                </div>
                                                                <input type="text" className="form-control" id={'v2title'} name={'v2title'} placeholder='Enter Title' value={ContentBlockItem !== null && ContentBlockItem.title} onChange={(e) => { onTitleChange(e) }} />
                                                                <i className="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    }

                                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('subtitle') &&
                                                        <div className="form-group basic">
                                                            <div className="input-wrapper custom-subtitle">
                                                                <div className="row">
                                                                    <div className="col-6 col-md-6">
                                                                        <label className="form-label mb-2" htmlFor="contentSubtitle">Subtitle</label>
                                                                    </div>
                                                                    <div className="col-6 col-md-6 text-end text-primary">
                                                                        <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                                                    </div>
                                                                </div>
                                                                <MDEditor height={200} value={SubTitleValue} onChange={(e) => { onSubTitleChange(e) }} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, image, link]} extraCommands={[]} preview='edit' style={{ backgroundColor: 'white', color: '#333333' }} />
                                                            </div>
                                                        </div>
                                                    }

                                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('description') &&
                                                        <div className="form-group basic">
                                                            <div className="input-wrapper">
                                                                <div className="row">
                                                                    <div className="col-6 col-md-6">
                                                                        <label className="form-label mb-2" htmlFor="contentDescription">Description</label>
                                                                    </div>
                                                                    <div className="col-6 col-md-6 text-end text-primary">
                                                                        <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                                                    </div>
                                                                </div>
                                                                <MDEditor value={descriptionValue} onChange={(e) => { onDescriptionChange(e) }} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, image, link]} extraCommands={[]} preview='edit' style={{ backgroundColor: 'white', color: '#333333' }} />
                                                            </div>
                                                        </div>
                                                    }

                                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('button') &&
                                                        <>
                                                            <div className='col-12 mt-1'>
                                                                <div className='row'>
                                                                    <div className='col-6 ps-0'>
                                                                        <div className="form-group basic">
                                                                            <div className="input-wrapper">
                                                                                <label className="form-label" htmlFor="name1">Buttons / Links</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {ContentBlockItem !== null && (ContentBlockItem.button === null || (ContentBlockItem.button !== null && (ContentBlockItem.button.length === 0 || ContentBlockItem.button.length < blockRules[NewBlockType.BlockName].maxButton))) && <>
                                                                        <div className='col-6 d-flex justify-content-end cursor-pointer'>
                                                                            <div className="badge bg-primary cursor-pointer" onClick={(e) =>
                                                                                handleShowButtonModal(e, ContentBlockItem.button, 'new')
                                                                            }>
                                                                                <ion-icon name="add-outline" className="md hydrated" aria-label="image outline"></ion-icon>
                                                                                <span>Add Button</span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('button') &&
                                                        <ul className="listview link-listview mx-1" style={{ marginLeft: '-16px', marginRight: '-16px' }}>
                                                            {ContentBlockItem !== null && ContentBlockItem.button !== null && ContentBlockItem.button.length !== 0 &&
                                                                <>
                                                                    {ContentBlockItem.button.length > 1 ?
                                                                        <Nestable
                                                                            items={ButtonItemToReorder}
                                                                            renderItem={renderButtonSitemap}
                                                                            maxDepth={1}
                                                                            handler={false}
                                                                            onChange={newItems => {
                                                                                const updatedItems = newItems.items
                                                                                // console.log(updatedItems)
                                                                                changeButtonReorder(updatedItems)
                                                                            }}
                                                                        />
                                                                        :
                                                                        ContentBlockItem.button.map((btnitem, i) => {
                                                                            return (
                                                                                <>
                                                                                    <li key={i} className="px-0 border-bottom cursor-move">
                                                                                        <div className="btnlist item ps-0 py-0">
                                                                                            <div className='d-flex w-100 py-2'>
                                                                                                <div className='d-flex w-100 justify-content-between align-items-center'>
                                                                                                    <div className='position-relative'>
                                                                                                        <button className={`btn btn-sm fs-10 ${(btnitem.style !== undefined && btnitem.style.length !== 0) ? btnitem.style : 'btn-bg-muted'}`} onClick={(e) =>
                                                                                                            handleShowButtonModal(e, ContentBlockItem.button, i)}>{btnitem.title}</button>

                                                                                                       
                                                                                                        <div className='badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer' onClick={(e) => deleteButton(e, i)}>
                                                                                                            <ion-icon name="trash-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            }

                                                        </ul>
                                                    }

                                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('url') &&
                                                        <div className="form-group basic">
                                                            <div className="input-wrapper">
                                                                <label
                                                                    className="form-label mb-0"
                                                                    htmlFor="title"
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-6 col-md-6">
                                                                            URL
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="url"
                                                                    name='url'
                                                                    value={ContentBlockItem !== null && ContentBlockItem.url} onChange={(e) => { onURLChange(e) }}
                                                                    placeholder="Enter url"
                                                                    autoComplete="off"
                                                                />
                                                                <i className="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    }

                                                    {NewBlockType !== null && (NewBlockType.BlockName === 'SM_HEAD' || NewBlockType.BlockName === 'SM_FOOT') &&
                                                        <div className="form-group basic">
                                                            <div className="input-wrapper">
                                                                <label
                                                                    className="form-label mb-2"
                                                                    htmlFor="navigation"
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-12 col-md-12">
                                                                            Navigation
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                                <button className="btn btn-secondary btn-sm btn-block" type="button" onClick={handleNavigation}>Manage Navigation</button>
                                                            </div>
                                                        </div>

                                                    }

                                                    {NewBlockType !== null && NewBlockType.BlockName !== 'SM_HEAD' && (NewBlockType.BlockName === 'SM_INFO' || NewBlockType.BlockName === 'SM_FOOT') &&
                                                        <>
                                                            {
                                                                ContentBlockItem !== null && (ContentBlockItem.form === '' || ContentBlockItem.form === null || ContentBlockItem.form === undefined) ?
                                                                    <div className="form-group basic mt-2">
                                                                        <button type='button' className='btn btn-sm btn-secondary' onClick={() => editForm()}>
                                                                            Form Add
                                                                            <ion-icon name="add-outline" className="ms-1"></ion-icon>
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    <div className="form-group basic mt-2">
                                                                        <button type='button' className='btn btn-sm btn-secondary' onClick={() => editForm(ContentBlockItem.form)}>
                                                                            Form Edit
                                                                            <ion-icon name="create-outline" className="ms-1"></ion-icon>
                                                                        </button>
                                                                    </div>
                                                            }
                                                        </>
                                                    }

                                                </div>
                                            </form>
                                        ))}
                                    </>
                                    :
                                    <form>
                                        <div>
                                            <div className="row">
                                                {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('image') &&
                                                    <>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className="form-group basic">
                                                                        <div className="input-wrapper">
                                                                            <label className="form-label" htmlFor="name1">Media</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {ContentBlockItem !== null && ContentBlockItem.image !== null && (ContentBlockItem.image.length === 0 || ContentBlockItem.image.length < blockRules[NewBlockType.BlockName].maxImage) &&
                                                                    <div className='col-6 d-flex justify-content-end cursor-pointer'>
                                                                        <div className="badge bg-primary cursor-pointer" onClick={() =>
                                                                            handleShowImageModal(
                                                                                [],
                                                                                ContentBlockItem.image.length,
                                                                                NewBlockType.BlockName,
                                                                                'Item'
                                                                            )
                                                                        }>
                                                                            <ion-icon name="add-outline" className="md hydrated" aria-label="image outline"></ion-icon>
                                                                            <span>Add Media</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {ContentBlockItem !== null && ContentBlockItem.image !== null && ContentBlockItem.image.length !== 0 &&
                                                            <>
                                                                {
                                                                    ContentBlockItem.image.length > 1 ?
                                                                        <div className='nestable-grid'>
                                                                            <Nestable
                                                                                items={ImageItemToReorder}
                                                                                renderItem={renderSitemap}
                                                                                maxDepth={1}
                                                                                handler={false}
                                                                                onChange={newItems => {
                                                                                    const updatedItems = newItems.items
                                                                                    // console.log(updatedItems)
                                                                                    changeImageReorder(updatedItems)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        ContentBlockItem.image.map((imgitm, i) => {
                                                                            const imageStyles = imgitm.style !== undefined ? imgitm.style : ''
                                                                            return <div className="col-4 col-md-4" key={i}>
                                                                                <div className="form-group basic">
                                                                                    <div className="input-wrapper">
                                                                                        <div className="d-flex justify-content-center align-items-center position-relative">
                                                                                            {imgitm.src !== undefined &&
                                                                                                <>
                                                                                                    <img
                                                                                                        onClick={() =>
                                                                                                            handleShowImageModal(
                                                                                                                [imgitm],
                                                                                                                i,
                                                                                                                NewBlockType.BlockName,
                                                                                                                'Item'
                                                                                                            )
                                                                                                        }
                                                                                                        src={imgitm.src}
                                                                                                        alt={imgitm.alt}
                                                                                                        className={`imaged w-100 border border-primary border-2 cursor-pointer ${imageStyles}`}
                                                                                                    />
                                                                                                    <div className='badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer' onClick={() => deleteImage(i)}>
                                                                                                        <ion-icon name="trash-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                                                                    </div>
                                                                                                    {
                                                                                                        imgitm.videoUrl && imgitm.videoUrl.length > 0 &&
                                                                                                        <div className='badge position-absolute s-n1 t-n1 z-index-10 bg-info'>
                                                                                                            <ion-icon name="videocam-outline" className="md trash"></ion-icon>
                                                                                                        </div>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div>

                                            {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('title') &&
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <div className="row">
                                                            <div className="col-6 col-md-6">
                                                                <label className="form-label" htmlFor={'v2title'}>Title</label>
                                                            </div>
                                                            <div className="col-6 col-md-6 text-end text-primary">
                                                                <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                                            </div>
                                                        </div>
                                                        <input type="text" className="form-control" id={'v2title'} name={'v2title'} placeholder='Enter Title' value={ContentBlockItem !== null && ContentBlockItem.title} onChange={(e) => { onTitleChange(e) }} />
                                                        <i className="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            }

                                            {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('subtitle') &&
                                                <div className="form-group basic">
                                                    <div className="input-wrapper custom-subtitle">
                                                        <div className="row">
                                                            <div className="col-6 col-md-6">
                                                                <label className="form-label mb-2" htmlFor="contentSubtitle">Subtitle</label>
                                                            </div>
                                                            <div className="col-6 col-md-6 text-end text-primary">
                                                                <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                                            </div>
                                                        </div>
                                                        <MDEditor height={200} value={SubTitleValue} onChange={(e) => { onSubTitleChange(e) }} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, image, link]} extraCommands={[]} preview='edit' style={{ backgroundColor: 'white', color: '#333333' }} />
                                                    </div>
                                                </div>
                                            }

                                            {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('description') &&
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <div className="row">
                                                            <div className="col-6 col-md-6">
                                                                <label className="form-label mb-2" htmlFor="contentDescription">Description</label>
                                                            </div>
                                                            <div className="col-6 col-md-6 text-end text-primary">
                                                                <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                                            </div>
                                                        </div>
                                                        <MDEditor value={descriptionValue} onChange={(e) => { onDescriptionChange(e) }} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, image, link]} extraCommands={[]} preview='edit' style={{ backgroundColor: 'white', color: '#333333' }} />
                                                    </div>
                                                </div>
                                            }

                                            {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('button') &&
                                                <div className='col-12 mt-1'>
                                                    <div className='row'>
                                                        <div className='col-6 ps-0'>
                                                            <div className="form-group basic">
                                                                <div className="input-wrapper">
                                                                    <label className="form-label" htmlFor="name1">Buttons / Links</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {ContentBlockItem !== null && (ContentBlockItem.button === null || (ContentBlockItem.button !== null && (ContentBlockItem.button.length === 0 || ContentBlockItem.button.length < blockRules[NewBlockType.BlockName].maxButton))) && <>
                                                            <div className='col-6 d-flex justify-content-end cursor-pointer'>
                                                                <div className="badge bg-primary cursor-pointer" onClick={(e) =>
                                                                    handleShowButtonModal(e, ContentBlockItem.button, 'new')
                                                                }>
                                                                    <ion-icon name="add-outline" className="md hydrated" aria-label="image outline"></ion-icon>
                                                                    <span>Add Button</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('button') &&
                                                <ul className="listview link-listview mx-1" style={{ marginLeft: '-16px', marginRight: '-16px' }}>
                                                    {ContentBlockItem !== null && ContentBlockItem.button !== null && ContentBlockItem.button.length !== 0 &&
                                                        <>
                                                            {ContentBlockItem.button.length > 1 ?
                                                                <Nestable
                                                                    items={ButtonItemToReorder}
                                                                    renderItem={renderButtonSitemap}
                                                                    maxDepth={1}
                                                                    handler={false}
                                                                    onChange={newItems => {
                                                                        const updatedItems = newItems.items
                                                                        // console.log(updatedItems)
                                                                        changeButtonReorder(updatedItems)
                                                                    }}
                                                                />
                                                                :
                                                                ContentBlockItem.button.map((btnitem, i) => {
                                                                    return (
                                                                        <>
                                                                            <li key={i} className="px-0 border-bottom cursor-move">
                                                                                <div className="btnlist item ps-0 py-0">
                                                                                    <div className='d-flex w-100 py-2'>
                                                                                        <div className='d-flex w-100 justify-content-between align-items-center'>
                                                                                            <div className='position-relative'>
                                                                                                <button className={`btn btn-sm fs-10 ${(btnitem.style !== undefined && btnitem.style.length !== 0) ? btnitem.style : 'btn-bg-muted'}`} onClick={(e) =>
                                                                                                    handleShowButtonModal(e, ContentBlockItem.button, i)}>{btnitem.title}</button>
                                                                                                <div className='badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer' onClick={(e) => deleteButton(e, i)}>
                                                                                                    <ion-icon name="trash-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    }

                                                </ul>
                                            }

                                            {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('url') &&
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <label
                                                            className="form-label mb-0"
                                                            htmlFor="title"
                                                        >
                                                            <div className="row">
                                                                <div className="col-6 col-md-6">
                                                                    URL
                                                                </div>
                                                            </div>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm"
                                                            id="url"
                                                            name='url'
                                                            value={ContentBlockItem !== null && ContentBlockItem.url} onChange={(e) => { onURLChange(e) }}
                                                            placeholder="Enter url"
                                                            autoComplete="off"
                                                        />
                                                        <i className="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            }

                                            {NewBlockType !== null && (NewBlockType.BlockName === 'SM_HEAD' || NewBlockType.BlockName === 'SM_FOOT') &&
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <label
                                                            className="form-label mb-2"
                                                            htmlFor="navigation"
                                                        >
                                                            <div className="row">
                                                                <div className="col-12 col-md-12">
                                                                    Navigation
                                                                </div>
                                                            </div>
                                                        </label>
                                                        <button className="btn btn-secondary btn-sm btn-block" type="button" onClick={handleNavigation}>Manage Navigation</button>
                                                    </div>
                                                </div>
                                            }

                                            {NewBlockType !== null && NewBlockType.BlockName !== 'SM_HEAD' &&
                                                <>
                                                    {
                                                        ContentBlockItem !== null && (ContentBlockItem.form === '' || ContentBlockItem.form === null || ContentBlockItem.form === undefined) ?
                                                            <div className="form-group basic mt-2">
                                                                <button type='button' className='btn btn-sm btn-secondary' onClick={() => editForm()}>
                                                                    Form Add
                                                                    <ion-icon name="add-outline" className="ms-1"></ion-icon>
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className="form-group basic mt-2">
                                                                <button type='button' className='btn btn-sm btn-secondary' onClick={() => editForm(ContentBlockItem.form)}>
                                                                    Form Edit
                                                                    <ion-icon name="create-outline" className="ms-1"></ion-icon>
                                                                </button>
                                                            </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </form>
                                }
                            </>
                        )
                    :
                        (
                            <form>
                                <div>
                                    <div className="row">
                                        {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('image') &&
                                            <>
                                                <div className='col-12'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <div className="form-group basic">
                                                                <div className="input-wrapper">
                                                                    <label className="form-label" htmlFor="name1">Media</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {ContentBlockItem !== null && ContentBlockItem.image !== undefined && (ContentBlockItem.image.length === 0 || ContentBlockItem.image.length < blockRules[NewBlockType.BlockName].maxImage) &&
                                                            <div className='col-6 d-flex justify-content-end'>
                                                                <div className="badge bg-primary cursor-pointer" onClick={() =>
                                                                    handleShowImageModal(
                                                                        [],
                                                                        ContentBlockItem.image.length,
                                                                        NewBlockType.BlockName,
                                                                        'Item'
                                                                    )
                                                                }>
                                                                    <ion-icon name="add-outline" className="md hydrated" aria-label="image outline"></ion-icon>
                                                                    <span>Add Media</span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {ContentBlockItem !== null && ContentBlockItem.image !== null && ContentBlockItem.image.length !== 0 &&
                                                    <>
                                                        {
                                                            ContentBlockItem.image.length > 1 ?
                                                                <div className='nestable-grid'>
                                                                    <Nestable
                                                                        items={ImageItemToReorder}
                                                                        renderItem={renderSitemap}
                                                                        maxDepth={1}
                                                                        handler={false}
                                                                        onChange={newItems => {
                                                                            const updatedItems = newItems.items
                                                                            // console.log(updatedItems)
                                                                            changeImageReorder(updatedItems)
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                ContentBlockItem.image.map((imgitm, i) => {
                                                                    const imageStyles = imgitm.style !== undefined ? imgitm.style : ''
                                                                    return <div className="col-4 col-md-4" key={i}>
                                                                        <div className="form-group basic">
                                                                            <div className="input-wrapper">
                                                                                <div className="d-flex justify-content-center align-items-center position-relative">
                                                                                    {imgitm.src !== undefined &&
                                                                                        <>
                                                                                            <img
                                                                                                onClick={() =>
                                                                                                    handleShowImageModal(
                                                                                                        [imgitm],
                                                                                                        i,
                                                                                                        NewBlockType.BlockName,
                                                                                                        'Item'
                                                                                                    )
                                                                                                }
                                                                                                src={imgitm.src}
                                                                                                alt={imgitm.alt}
                                                                                                className={`imaged w-100 border border-primary border-2 cursor-pointer ${imageStyles}`}
                                                                                            />
                                                                                            <div className='badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer' onClick={() => deleteImage(i)}>
                                                                                                <ion-icon name="trash-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                                                            </div>
                                                                                            {
                                                                                                imgitm.videoUrl && imgitm.videoUrl.length > 0 &&
                                                                                                <div className='badge position-absolute s-n1 t-n1 z-index-10 bg-info'>
                                                                                                    <ion-icon name="videocam-outline" className="md trash"></ion-icon>
                                                                                                </div>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                        }
                                                    </>

                                                }
                                            </>
                                        }
                                    </div>

                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('title') &&
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <div className="row">
                                                    <div className="col-6 col-md-6">
                                                        <label className="form-label" htmlFor={'v2title'}>Title</label>
                                                    </div>
                                                    <div className="col-6 col-md-6 text-end text-primary">
                                                        <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                                    </div>
                                                </div>
                                                <input type="text" className="form-control" id={'v2title'} name={'v2title'} placeholder='Enter Title' value={ContentBlockItem !== null && ContentBlockItem.title} onChange={(e) => { onTitleChange(e) }} />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    }

                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('subtitle') &&
                                        <div className="form-group basic">
                                            <div className="input-wrapper custom-subtitle">
                                                <div className="row">
                                                    <div className="col-6 col-md-6">
                                                        <label className="form-label mb-2" htmlFor="contentSubtitle">Subtitle</label>
                                                    </div>
                                                    <div className="col-6 col-md-6 text-end text-primary">
                                                        <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                                    </div>
                                                </div>
                                                <MDEditor height={200} value={SubTitleValue} onChange={(e) => { onSubTitleChange(e) }} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, image, link]} extraCommands={[]} preview='edit' style={{ backgroundColor: 'white', color: '#333333' }} />
                                            </div>
                                        </div>
                                    }

                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('description') &&
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <div className="row">
                                                    <div className="col-6 col-md-6">
                                                        <label className="form-label mb-2" htmlFor="contentDescription">Description</label>
                                                    </div>
                                                    <div className="col-6 col-md-6 text-end text-primary">
                                                        <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                                    </div>
                                                </div>
                                                <MDEditor value={descriptionValue} onChange={(e) => { onDescriptionChange(e) }} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, image, link]} extraCommands={[]} preview='edit' style={{ backgroundColor: 'white', color: '#333333' }} />
                                            </div>
                                        </div>
                                    }

                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('button') &&
                                        <>
                                            <div className='col-12 mt-2'>
                                                <div className='row'>
                                                    <div className='col-6 ps-0'>
                                                        <div className="form-group basic">
                                                            <div className="input-wrapper">
                                                                <label className="form-label" htmlFor="name1">Buttons / Links</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {ContentBlockItem !== null && ContentBlockItem.button !== null && (ContentBlockItem.button.length === 0 || ContentBlockItem.button.length < blockRules[NewBlockType.BlockName].maxButton) && <>
                                                        <div className='col-6 d-flex justify-content-end cursor-pointer'>
                                                            <div className="badge bg-primary cursor-pointer" onClick={(e) => handleShowButtonModal(e, ContentBlockItem.button, 'new')
                                                            }>
                                                                <ion-icon name="add-outline" className="md hydrated" aria-label="image outline"></ion-icon>
                                                                <span>Add Button</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('button') &&
                                        <ul className="listview link-listview mx-1" style={{ marginLeft: '-16px', marginRight: '-16px' }}>
                                            {ContentBlockItem !== null && ContentBlockItem.button !== null && ContentBlockItem.button.length !== 0 &&
                                                <>
                                                    {ContentBlockItem.button.length > 1 ?
                                                        <Nestable
                                                            items={ButtonItemToReorder}
                                                            renderItem={renderButtonSitemap}
                                                            maxDepth={1}
                                                            handler={false}
                                                            onChange={newItems => {
                                                                const updatedItems = newItems.items
                                                                // console.log(updatedItems)
                                                                changeButtonReorder(updatedItems)
                                                            }}
                                                        />
                                                        :
                                                        ContentBlockItem.button.map((btnitem, i) => {
                                                            return (
                                                                <>
                                                                    <li key={i} className="px-0 border-bottom cursor-move">
                                                                        <div className="btnlist item ps-0 py-0">
                                                                            <div className='d-flex w-100 py-2'>
                                                                                <div className='d-flex w-100 justify-content-between align-items-center'>
                                                                                    <div className='position-relative'>
                                                                                        <button className={`btn btn-sm fs-10 ${(btnitem.style !== undefined && btnitem.style.length !== 0) ? btnitem.style : 'btn-bg-muted'}`} onClick={(e) =>
                                                                                            handleShowButtonModal(e, ContentBlockItem.button, i)}>{btnitem.title}</button>

                                                                                        <div className='badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer' onClick={(e) => deleteButton(e, i)}>
                                                                                            <ion-icon name="trash-outline" className="md trash" aria-label="Delete"></ion-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>
                                            }

                                        </ul>
                                    }

                                    <div className="mt-1">
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label
                                                    className="form-label mb-0"
                                                    htmlFor="ineerPage"
                                                >
                                                    <div className="row">
                                                        <div className="col-12 col-md-12">
                                                            Create Inner Page?
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ marginBottom: '3px' }}>
                                            <input type="radio" className="btn-check" name="innerPageRadio" id="btnradioq1" value="true" checked={innerPageValue === 'true'} onChange={handleInnerPageSwitch} />
                                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnradioq1">
                                                <ion-icon name="checkmark-circle-outline"></ion-icon>
                                                Yes
                                            </label>

                                            <input type="radio" className="btn-check" name="innerPageRadio" id="btnradioq2" value="false" checked={innerPageValue === 'false'} onChange={handleInnerPageSwitch} />
                                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnradioq2">
                                                <ion-icon name="close-circle-outline"></ion-icon>
                                                No
                                            </label>
                                        </div><br />
                                        {innerPageValue === 'true' &&
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <h4 className="mb-0 mt-2 text-secondary">https://{selectedDomain}/</h4>
                                                    <input type="text" className="form-control" id={'innerPageUrl'} name={'innerPageUrl'} onChange={(e) => { validateUrl(e.target.value) }} placeholder='Enter URL' />
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                                {innerPageValue === 'true' &&
                                                    <div classNames="row">
                                                        <div className="col-12 col-md-12 text-end">
                                                            {(urlAccepted) &&
                                                                <span className="text-success fs-14">Url Accepted</span>
                                                            }
                                                            {(!urlAccepted) &&
                                                                <span className="text-danger fs-14">Url Not Accepted</span>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>


                                    {NewBlockType !== null && blockRules[NewBlockType.BlockName].fields.includes('additionalDetails') &&
                                        <div className="form-group basic">
                                            <div className="accordion" id="accordionExample1">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed ps-0" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#accordion1">
                                                            Additional Details
                                                        </button>
                                                    </h2>
                                                    <div id="accordion1" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                                        <div className="accordion-body px-0">
                                                            <div className='row mb-1'>
                                                                <div className='col-md-6'>
                                                                    <label className="form-label" htmlFor='startDate'>From Date</label>
                                                                    <input type="date" placeholder="dd-mm-yyyy" className="form-control" value={additionalDetailsValue.Period.startDate} name='startDate' id='startDate' onChange={(e) => onAdditionalDetailChange(e.target.value, 'Period', 'startDate')} />
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label className="form-label" htmlFor='endDate'>End Date</label>
                                                                    <input type="date" placeholder="dd-mm-yyyy" className="form-control" value={additionalDetailsValue.Period.endDate} name='endDate' id='endDate' onChange={(e) => onAdditionalDetailChange(e.target.value, 'Period', 'endDate')} />
                                                                </div>
                                                            </div>
                                                            <div className='row mb-1'>
                                                                <div className='col-md-12'>
                                                                    <label className="form-label" htmlFor='Location'>Location</label>
                                                                    <input type="text" placeholder="Please enter location" value={additionalDetailsValue.Location.title} className="form-control" name='Location' id='Location' onChange={(e) => onAdditionalDetailChange(e.target.value, 'Location', 'title')} />
                                                                    <input type="text" hidden className='form-control' value={JSON.stringify(additionalDetailsValue.Location.latlong)} id="latLong" />
                                                                </div>
                                                            </div>
                                                            <div className='row mb-1'>
                                                                <div className='col-md-12'>
                                                                    <label className="form-label" htmlFor='amount'>Amount</label>
                                                                    <div className='row'>
                                                                        <div className='col-md-3'>
                                                                            <input type="currency" placeholder="Please enter location" className="form-control" readOnly value={additionalDetailsValue.Amount.currency} name='currency' id='currency' onChange={(e) => onAdditionalDetailChange(e.target.value, 'Amount', 'currency')} />
                                                                        </div>
                                                                        <div className='col-md-9'>
                                                                            <input type="number" placeholder="Please enter amount" className="form-control" name='amount' value={additionalDetailsValue.Amount.amount} id='amount' onChange={(e) => onAdditionalDetailChange(e.target.value, 'Amount', 'amount')} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    }
                                </div>
                            </form>
                        )
                    } */}
                </>
            }

        </div>
    )
}

const mapStateToProps = ({ websiteData, offcanvas }) => {
    const {
        blocks: websiteDataBlocks,
        blockRules,
    } = websiteData
    const {
        items,
        itemID,
        properties,
        blockID,
        blockType,
        ContentBlockItem,
        MainBlockProps,
        ContentItems,
        NewBlockType,
        imageData,
        WhereToUpdateImage,
        isImageModalVisible,
        buttonDataObj,
        isButtonModalVisible,
        FlagsToCheckChanges,
        reorderItems,
        isNavigationVisible,
        isEditVisible
    } = offcanvas
    return {
        websiteDataBlocks,
        items,
        itemID,
        properties,
        blockID,
        blockType,
        blockRules,
        ContentBlockItem,
        MainBlockProps,
        ContentItems,
        NewBlockType,
        imageData,
        WhereToUpdateImage,
        isImageModalVisible,
        buttonDataObj,
        isButtonModalVisible,
        FlagsToCheckChanges,
        reorderItems,
        isNavigationVisible,
        isEditVisible
    }
}

const mapDispatchToProps = {
    setContentBlockItem,
    setMainBlockProps,
    setContentItems,
    setContentItems,
    showImageModal,
    newImageData,
    setWhereToUpdateImage,
    showButtonModal,
    newButtonData,
    setFlagsToCheckChanges,
    showNavigationOffcanvas,
    setReorderItems,
    hideEditOffcanvas,
    showFormOffcanvas
}


export default connect(mapStateToProps, mapDispatchToProps)(ContentTab)
