import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'

// import './App.css'
import { BrowserRouter, Routes, Route, Redirect, Navigate, useNavigate } from 'react-router-dom'
import { setToken } from './features/auth/authSlice'
import Authentication from './components/Pages/Authentication'
import Layout from './components/Pages/Layout'
import Build from './components/Pages/Build'
import Builder from './components/Pages/Builder'
import Support from './components/Pages/Support'
import SupportTicketThread from './components/Pages/SupportTickets'
import SearchDomain from './components/Pages/DomainSearch'
import Load from './components/Pages/Load'
import AppInfo from './components/Pages/AppInfo'
import ListEmail from './components/Pages/Email'
import ListChat from './components/Pages/Chat'
import Notifications from './components/Pages/Notifications'
import Story from './components/Elements/Notification/Story'
import Share from './components/Pages/Share'
import ReqPayment from './components/Pages/ReqPayment'
import RecPayment from './components/Pages/RecPayment'
import Analytics from './components/Pages/Analytics'
import Forms from './components/Pages/Forms'
import jwt_decode from 'jwt-decode'
import Add from './components/Pages/add'
import Interact from './components/Pages/Interact'
import MarketPlace from './components/Pages/marketPlace'
import Boost from './components/Pages/Boost'
import DNS from './components/Pages/DNS'
import Home from './components/Pages/Home'
import DetailPage from './components/Pages/DetailPage'
import FormDetail from './components/Pages/FormDetail'
import GetStarted from './components/Pages/GetStarted'
import Overview from './components/Pages/Overview'
import Websites from './components/Pages/Websites'
import Domains from './components/Pages/Domains'
import EmailsControl from './components/Pages/EmailsControl'
import Categories from './components/Pages/Categories'
import DSetting from './components/Pages/DSetting'
import MediaGallery from './components/Pages/MediaGallery'
import ChatBot from './components/Pages/ChatBot'
import SocialMedia from './components/Pages/SocialMedia'
import Webmail from './components/Pages/Webmail'
import LiveChat from './components/Pages/LiveChat'
import AddTemplate from './components/Pages/AddTemplate'
import BlockDetail from './components/Pages/BlockDetail'
import ComingSoon from './components/Pages/ComingSoon'
import YeccAppPage from './components/Pages/YeccAppPage'
import GetStartedLogin from './components/Pages/GetStartedLogin'
import ManageEvent from './components/Pages/ManageEvent'
import AllCategories from './components/Pages/AllCategories'
import OtpValidation from './components/Pages/OtpValidation'
import DomainSearchnew from './components/Pages/DomainSearchnew'
import BuildingWebsite from './components/Pages/BuildingWebsite'
import InitiateWebsite from './components/Pages/InitiateWebsite'
import VBCardEditor from './components/Pages/VBCardEditor'
import AllPages from './components/Pages/AllPages'
import GenerateWebsite from './components/Pages/GenerateWebsite'

const App = ({
  authToken,
  setToken
}) => {

  const [loadingLoaderScreen, setLoadingLoaderScreen] = useState(false);
  const validateToken = () => {
    if (authToken) {
      return true
    } else {
      const token = localStorage.getItem('token')
      const tokenData = (token) ? jwt_decode(token) : false
      if (tokenData && new Date(tokenData.exp * 1000) > new Date()) {
        if (tokenData.userInfo.hasOwnProperty('ID') && tokenData.userInfo.hasOwnProperty('RoleID')) {
          setToken({ authToken: token })
          return true
        } else {
          localStorage.clear()
          return false
        }
      } else {
        localStorage.clear()
        return false
      }
    }
  }
  const userLoggedIn = validateToken()
  // console.log(userLoggedIn)
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        {!userLoggedIn &&
          <Fragment>
            <Route path="*" element={<Auth />} />
            <Route path="getStarted" element={<GetStartedView />} />
            <Route path="build-website" element={<GetStartedLoginView setLoadingLoaderScreen={setLoadingLoaderScreen} />} />
            {/* <Route path="allCategories" element={<AllCategoriesView />} />
            <Route path="otpValidation" element={<OtpValidationView />} />
            <Route path="domainSearchnew" element={<DomainSearchnewView />} />
            <Route path="buildingWebsite" element={<BuildingWebsiteView />} /> */}
          </Fragment>
        }
        {userLoggedIn &&
          <Fragment>
            <Route path="/" element={<SelectWebsiteList />} />
            <Route path="getStarted" element={<GetStartedView />} />
            <Route path="build-website" element={<GetStartedLoginView setLoadingLoaderScreen={setLoadingLoaderScreen} />} />
            <Route path="build" element={<EditContent />} />
            <Route path="builder" element={<EditContent2 />} />
            <Route path="visitorReport" element={<Analytic />} />
            <Route path="support" element={<SupportInfo />} />
            <Route path="ticketsThread" element={<SupportTicketsThread />} />
            <Route path="searchDomain" element={<DomainSearch />} />
            <Route path="load" element={<SelectWebsiteList />} />
            <Route path="appinfo" element={<AboutApp />} />
            <Route path="plugins" element={<ComingSoonApp />} />
            <Route path="manageEmail" element={<ComingSoonApp />} />
            {/* <Route path="manageEmail" element={<ManageEmailPage />} /> */}
            <Route path="chat" element={<ChatView />} />
            <Route path="notifications" element={<NotificationsList />} />
            <Route path="share" element={<ShareLink />} />
            <Route path="reqPayment" element={<RequestPayment />} />
            <Route path="recPayment" element={<ReceivePayment />} />
            <Route path="forms" element={<ManageForms />} />
            <Route path="add" element={<CommonAdd />} />
            <Route path="interact" element={<InteractFeed />} />
            <Route path="marketPlace" element={<MarketCommunity />} />
            <Route path="boost" element={<BoostFeature />} />
            <Route path="dns" element={<DnsManage />} />
            <Route path="EControl" element={<EmailsControlPage />} />
            <Route path="home" element={<AppHome />} />
            <Route path="detailPage" element={<DetailPageInfo />} />
            <Route path="formDetail" element={<DetailPageForm />} />
            <Route path="overview" element={<OverviewPage />} />
            <Route path="websites" element={<WebsitesPage />} />
            <Route path="domains" element={<DomainsPage />} />
            <Route path="categories" element={<CategoriesPage />} />
            <Route path="dsetting" element={<ComingSoonApp />} />
            {/* <Route path="dsetting" element={<DomainSettingPage />} /> */}
            <Route path="mediaGallery" element={<MediaGalleryPage />} />
            <Route path="chatBot" element={<ChatBotPage />} />
            {/* <Route path="socialMedia" element={<SocialMediaPage />} /> */}
            <Route path="socialMedia" element={<ComingSoonApp />} />
            <Route path="webmail" element={<WebmailPage />} />
            <Route path="liveChat" element={<LiveChatPage />} />
            <Route path="addTemplate" element={<AddTemplatePage />} />
            <Route path="blockDetail" element={<BlockDetailPage />} />
            <Route path="eventDetail" element={<EventDetailView />} />
            {/* <Route path="tap-manage" element={<ProfilePageView />} /> */}
            <Route path="card-editor" element={<VBCardEditorView />} />
            <Route path="findBuisness" element={<ComingSoonApp />} />
            <Route path="socialMediaMarketing" element={<ComingSoonApp />} />
            <Route path="emailMarketing" element={<ComingSoonApp />} />
            <Route path="managePages" element={<AllPagesView />} />
            <Route path="yeccApp" element={<YECCApp />} />
            <Route path="*" element={<Auth />} />
          </Fragment>
        }
        </Routes>
      </BrowserRouter>


      
      {
        loadingLoaderScreen &&
        <div className="loaderLoading">
          <div class="spinner-border text-primary col-md-3 me-2" role="status"></div>
        </div>
      }
    </div>
  );
}

function Auth() {
  return (
    <div className="App pt-5">
      <Authentication></Authentication>
    </div>
  )
}

function EditContent() {
  const [functionObject, setFunctionObject] = useState({});
  return (
    <div className="App">
      <Layout functionObject={functionObject} setFunctionObject={setFunctionObject}>
        {/* <GenerateWebsite functionObject={functionObject} setFunctionObject={setFunctionObject} /> */}
        <Build functionObject={functionObject} setFunctionObject={setFunctionObject}></Build>
      </Layout>
    </div>
  )
}

function EditContent2() {
  const [functionObject, setFunctionObject] = useState({});
  return (
    <div className="App">
      <Layout functionObject={functionObject} setFunctionObject={setFunctionObject}>
        <Builder functionObject={functionObject} setFunctionObject={setFunctionObject}></Builder>
      </Layout>
    </div>
  )
}

function Analytic() {
  return (
    <div className="App">
      <Layout>
        <Analytics></Analytics>
      </Layout>
    </div>
  )
}

function SupportInfo() {
  return (
    <div className="App">
    <Layout>
      <Support></Support>
    </Layout>
    </div>
  )
}

function SupportTicketsThread() {
  return (
    <div className="App">
    <Layout>
      <SupportTicketThread />
    </Layout>
    </div>
  )
}

function RequestPayment() {
  return (
    <div className="App">
      <Layout>
        <ReqPayment></ReqPayment>
      </Layout>
    </div>
  )
}

function ReceivePayment() {
  return (
    <div className="App">
      <Layout>
        <RecPayment></RecPayment>
      </Layout>
    </div>
  )
}

function DomainSearch() {
  return (
    <div className="App">
    <Layout>
      <SearchDomain></SearchDomain>
    </Layout>
    </div>
  )
}

function SelectWebsiteList() {
  return (
    <div className="App">
      <Load></Load>
    </div>
  )
}

function AboutApp() {
  return (
    <div className="App">
      <Layout>
        <AppInfo></AppInfo>
      </Layout>
    </div>
  )
}


function ComingSoonApp() {
  return (
    <div className="App">
      <Layout>
        <ComingSoon></ComingSoon>
      </Layout>
    </div>
  )
}
 
function YECCApp() {
  return (
    <div className="App">
      <Layout>
        <YeccAppPage />
      </Layout>
    </div>
  )
}
function ManageEmailPage() {
  return (
    <div className="App">
    <Layout>
      <ListEmail></ListEmail>
    </Layout>
    </div>
  )
}

function ChatView() {
  return (
    <div className="App">
    <Layout>
      <ListChat></ListChat>
    </Layout>
    </div>
  )
}

function NotificationsList() {
  return (
    <div className="App">
      <Layout>
        <Notifications></Notifications>
        <Story></Story>
      </Layout>
    </div>
  )
}

function ShareLink() {
  return (
    <div className="App">
      <Layout>
        <Share></Share>
      </Layout>
    </div>
  )
}

function ManageForms() {
  return (
    <div className="App">
    <Layout>
      <Forms></Forms>
    </Layout>
    </div>
  )
}

function CommonAdd() {
  return (
    <div className="App">
    <Layout>
      <Add></Add>
    </Layout>
    </div>
  )
}

function InteractFeed() {
  return (
    <div className="App">
    <Layout>
      <Interact></Interact>
    </Layout>
    </div>
  )
}

function MarketCommunity() {
  return (
    <div className="App">
    <Layout>
      <MarketPlace></MarketPlace>
    </Layout>
    </div>
  )
}

function BoostFeature() {
  return (
    <div className="App">
    <Layout>
      <Boost></Boost>
    </Layout>
    </div>
  )
}

function DnsManage() {
  return(
    <div className="App">
    <Layout>
      <DNS></DNS>
    </Layout>
    </div>
  )
}

function AppHome() {
  return(
    <div className="App">
    <Layout>
      <Home></Home>
    </Layout>
    </div>
  )
}

function DetailPageInfo() {
  return (
    <div className="App">
    <Layout>
      <DetailPage></DetailPage>
    </Layout>
    </div>
  )
}

function DetailPageForm() {
  return(
    <div className="App">
    <Layout>
      <FormDetail></FormDetail>
    </Layout>
    </div>
  )
}

function GetStartedView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <GetStarted></GetStarted>
    {/* </Layout> */}
    </div>
  )
}

function GetStartedLoginView( { setLoadingLoaderScreen }) {
  return (
    <div className="App">
      {/* <GetStartedLogin></GetStartedLogin> */}
      <InitiateWebsite setLoadingLoaderScreen={setLoadingLoaderScreen}/>
    </div>
  )
}
// function AllCategoriesView() {
//   return (
//     <div className="App">
//       <AllCategories></AllCategories>
//     </div>
//   )
// }
// function OtpValidationView() {
//   return (
//     <div className="App">
//       <OtpValidation></OtpValidation>
//     </div>
//   )
// }
// function DomainSearchnewView() {
//   return (
//     <div className="App">
//       <DomainSearchnew></DomainSearchnew>
//     </div>
//   )
// }
// function BuildingWebsiteView() {
//   return (
//     <div className="App">
//       <BuildingWebsite></BuildingWebsite>
//     </div>
//   )
// }

function OverviewPage() {
  return(
    <div className="App">
    <Layout>
      <Overview></Overview>
    </Layout>
    </div>
  )
}

function AllPagesView() {
  return(
    <div className="App">
    <Layout>
      <AllPages />
    </Layout>
    </div>
  )
}

function WebsitesPage() {
  return(
    <div className="App">
    <Layout>
      <Websites></Websites>
    </Layout>
    </div>
  )
}

function DomainsPage() {
  return(
    <div className="App">
    <Layout>
      <Domains></Domains>
    </Layout>
    </div>
  )
}

function EmailsControlPage() {
  return(
    <div className="App">
    <Layout>
      <EmailsControl></EmailsControl>
    </Layout>
    </div>
  )
}

function CategoriesPage() {
  return(
    <div className="App">
    <Layout>
      <Categories></Categories>
    </Layout>
    </div>
  )
}

function DomainSettingPage() {
  return(
    <div className="App">
    <Layout>
      <DSetting></DSetting>
    </Layout>
    </div>
  )
}

function MediaGalleryPage() {
  return(
    <div className="App">
    <Layout>
      <MediaGallery></MediaGallery>
    </Layout>
    </div>
  )
}

function ChatBotPage() {
  return(
    <div className="App">
    <Layout>
      <ChatBot></ChatBot>
    </Layout>
    </div>
  )
}

function SocialMediaPage() {
  return(
    <div className="App">
    <Layout>
      <SocialMedia></SocialMedia>
    </Layout>
    </div>
  )
}

function WebmailPage() {
  return(
    <div className="App">
    <Layout>
      <Webmail></Webmail>
    </Layout>
    </div>
  )
}

function LiveChatPage() {
  return(
    <div className="App">
    <Layout>
      <LiveChat></LiveChat>
    </Layout>
    </div>
  )
}

function AddTemplatePage() {
  return(
    <div className="App">
    <Layout>
      <AddTemplate></AddTemplate>
    </Layout>
    </div>
  )
}

function BlockDetailPage() {
  return(
    <div className="App">
    <Layout>
      <BlockDetail></BlockDetail>
    </Layout>
    </div>
  )
}

function EventDetailView() {
  return(
    <div className="App">
    <Layout>
      <ManageEvent></ManageEvent>
    </Layout>
    </div>
  )
}

// function ProfilePageView() {
//   return(
//     <div className="App">
//     <Layout>
//       <Manage></Manage>
//     </Layout>
//     </div>
//   )
// }

function VBCardEditorView() {
  return(
    <div className="App">
    <Layout>
      <VBCardEditor></VBCardEditor>
    </Layout>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const {
    authToken
  } = auth

  return {
    authToken
  }
}

const mapDispatchToProps = {
  setToken
}

export default connect(mapStateToProps, mapDispatchToProps)(App)