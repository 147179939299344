import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    setShowJSONModal
} from '../../../features/modal/offcanvasSlice';

const JSONTab = ({
    JSONModal,
    CompleteJSON,
    setShowJSONModal
}) => {
    const [jsonvalue, setJSONValue] = useState(null);
    const showJSONMOdal = () => {
        setShowJSONModal({ JSONModal: true });
    }

    useEffect(() => {
        // setJSONValue(CompleteJSON);
        setJSONValue(JSON.stringify(CompleteJSON));
        console.log("CompleteJSON", CompleteJSON);
    }, [CompleteJSON])

    return (
        <div class="section full mt-2 mb-2">
            <label class="form-label" htmlFor="json5">JSON</label><br />
            <button className='btn btn-primary mb-2' onClick={showJSONMOdal}>View in Fullscreen</button>
            <br />
            {/* <div className='w-100' style={{'overflow': 'auto'}}>
                                <ReactJson src={jsonvalue} theme="google" collapsed="true" indentWidth="8" onEdit="true" onAdd="true" onDelete="true" />
                              </div> */}
            <textarea id="json5" rows="15" class="form-control" value={jsonvalue} onChange={(event) => setJSONValue(event.target.value)}></textarea>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        JSONModal
    } = offcanvas
    const {
        CompleteJSON
    } = websiteData
    return {
        CompleteJSON,
        JSONModal
    }
}

const mapDispatchToProps = {
    setShowJSONModal
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JSONTab)