import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import axios from '../../AxiosConfig'
import { showCategoryOffcanvas } from '../../features/modal/offcanvasSlice'

const Categories = ({
    showCategoryOffcanvas
}) => {
    const [categoryListSection, setCategoryListSection] = useState(false)
    const [pageListSection, setPageListSection] = useState(false)
    const [blockListSection, setBlockListSection] = useState(false)
    const [categoryList, setCategoryList] = useState([])
    const [pageNameList, setPageNameList] = useState([])
    const [blockList, setBlockList] = useState(null)
    const [activeCategory, setActiveCategory] = useState('')
    const [activePage, setActivePage] = useState('')

    useEffect(() => {
        axios.get('/website/getAllCategories').then(res=>{
            if (res.data.message === 'Success') {
                setCategoryList(res.data.data)
                console.log(res.data.data)
                setCategoryListSection(true)
            } else {
              console.log('SOMETHING WENT WRONG')
            }
        })
    }, [])

    const showCategoryPageList = (categoryID) => {
        setPageListSection(true)
        setActiveCategory(categoryID)
        console.log(categoryID)
        axios.get('/website/getAdminCategoryDetails/' + categoryID).then(res=>{
            if (res.data.message === 'Success') {
                let pageDetail = res.data.data
                for (let i = 0; i < pageDetail.length; i++) {
                    Object.keys(pageDetail[i].PageJson['blocks']).map((key, value) => {
                        if (key.includes('CN_')) {
                            pageDetail[i].PageJson['blocks'][key].itemCount = 0
                            let contentID = pageDetail[i].PageJson['blocks'][key].items.contents.split('.')[1]
                            pageDetail[i].PageJson['blocks'][key].itemCount = pageDetail[i].PageJson['contents'][contentID].items.length
                        }
                    })
                }
                setPageNameList(res.data.data)
                console.log(res.data.data)
            } else {
              console.log('SOMETHING WENT WRONG')
            }
        })
    }

    const showBlockList = (pageTitle) => {
        setBlockListSection(true)
        setActivePage(pageTitle)
        {pageNameList.map((value,key) => {
            if (value.PageTitle === pageTitle) {
                setBlockList(value.PageJson['blocks'])
            }
        })}
    }

    const handleCategoryOffcanvas = () => {
        showCategoryOffcanvas({isCategoryVisible: true})
    }
    return (
        <div id="appCapsule">
            <div className="section full">
                <div className="row">
                    <div className="col-md-4">
                        {categoryListSection &&
                            <>
                                {categoryList.length !== 0 &&
                                    <>
                                        <div className="row p-1">
                                            <div className="col-md-6 col-6">
                                                <div className="listview-title p-0">Categories</div>
                                            </div>
                                            <div className="col-md-6 col-6 d-flex justify-content-end align-items-center">
                                                <span className="badge badge-secondary" style={{cursor: 'pointer', marginRight: '2px'}} onClick={handleCategoryOffcanvas}>
                                                    {categoryList.length}
                                                </span>
                                                <span className="badge badge-primary" style={{cursor: 'pointer'}} onClick={handleCategoryOffcanvas}>
                                                    <ion-icon name="add-outline"></ion-icon>
                                                </span>
                                            </div>
                                        </div>
                                        <ul className="listview image-listview">
                                            {categoryList.map((value,key) => {
                                                return <>
                                                {activeCategory !== value.ID &&
                                                    <li>
                                                        <a href="javascript:void(0)" className="item" onClick={() => {showCategoryPageList(value.ID)}}>
                                                            <div className="icon-box bg-secondary">
                                                                {key + 1}
                                                            </div>
                                                            <div className="in">
                                                                {value.CategoryName}
                                                            </div>
                                                            <span className="badge badge-danger">10</span>
                                                        </a>
                                                    </li>
                                                }
                                                {activeCategory === value.ID &&
                                                    <li className="webweb-bg-secondary">
                                                        <a href="javascript:void(0)" className="item" onClick={() => {showCategoryPageList(value.ID)}}>
                                                            <div className="icon-box bg-secondary">
                                                                {key + 1}
                                                            </div>
                                                            <div className="in">
                                                                {value.CategoryName}
                                                            </div>
                                                            <span className="badge badge-danger">10</span>
                                                        </a>
                                                    </li>
                                                }
                                                </>
                                            })}                                           
                                        </ul>
                                    </>
                                }
                            </>
                        }
                    </div>
                    <div className="col-md-4">
                        {pageListSection &&
                            <>
                                {pageNameList.length !== 0 &&
                                    <>
                                        <div className="row p-1">
                                            <div className="col-md-6 col-6">
                                                <div className="listview-title p-0">Pages</div>
                                            </div>
                                            <div className="col-md-6 col-6 d-flex justify-content-end align-items-center">
                                                <span className="badge badge-secondary" style={{marginRight: '2px', cursor: 'pointer'}}>
                                                    {pageNameList.length}
                                                </span>
                                                <span className="badge badge-info" style={{marginRight: '2px', cursor: 'pointer'}}>
                                                    <ion-icon name="menu-outline"></ion-icon>
                                                </span>
                                                <span className="badge badge-primary" style={{marginRight: '2px', cursor: 'pointer'}}>
                                                    <ion-icon name="add-outline"></ion-icon>
                                                </span>
                                            </div>
                                        </div>
                                        <ul className="listview image-listview">
                                            {pageNameList.map((value, key) => {
                                                return <>
                                                {activePage !== value.PageTitle &&
                                                    <li className="custom-line">
                                                        <a href="javascript:void(0)" className="item" onClick={() => {showBlockList(value.PageTitle)}}>
                                                                <ion-icon name="pause-outline" className="fs-3 me-1"></ion-icon>
                                                                <ion-icon name="return-down-forward-outline" className="fs-4"></ion-icon>
                                                            <div className="in ps-2">
                                                                {value.PageTitle}
                                                            </div>
                                                        </a>
                                                    </li>
                                                }
                                                {activePage === value.PageTitle &&
                                                    <li className="custom-line webweb-bg-secondary">
                                                        <a href="javascript:void(0)" className="item" onClick={() => {showBlockList(value.PageTitle)}}>
                                                            <ion-icon name="pause-outline" className="fs-3 me-1"></ion-icon>
                                                            <ion-icon name="return-down-forward-outline" className="fs-4"></ion-icon>
                                                            <div className="in ps-2">
                                                                {value.PageTitle}
                                                            </div>
                                                        </a>
                                                    </li>
                                                }
                                                </>
                                            })}
                                        </ul>
                                    </>
                                }
                            </>
                        }
                    </div>
                    <div className="col-md-4">
                        {blockListSection &&
                            <>
                                {blockList !== null &&
                                    <>
                                        <div className="row p-1">
                                            <div className="col-md-6 col-6">
                                                <div className="listview-title p-0">Blocks</div>
                                            </div>
                                            <div className="col-md-6 col-6 d-flex justify-content-end align-items-center">
                                                <span className="badge badge-secondary" style={{marginRight: '2px', cursor: 'pointer'}}>
                                                    {Object.keys(blockList).length}
                                                </span>
                                                <span className="badge badge-info" style={{marginRight: '2px', cursor: 'pointer'}}>
                                                    <ion-icon name="menu-outline"></ion-icon>
                                                </span>
                                                <span className="badge badge-primary" style={{marginRight: '2px', cursor: 'pointer'}}>
                                                    <ion-icon name="add-outline"></ion-icon>
                                                </span>
                                            </div>
                                        </div>
                                        <ul className="listview image-listview">
                                            
                                            {Object.keys(blockList).map((value, key) => {
                                                return <li>
                                                    <a href="javascript:void(0)" className="item">
                                                        <ion-icon name="pause-outline" className="fs-2 me-1"></ion-icon>
                                                        <div className="icon-box bg-secondary fs-6">
                                                            {value.split('_')[0]}
                                                        </div>
                                                        <div className="in">
                                                            <div>
                                                                {/* <header>Name</header> */}
                                                                {value}
                                                                <footer>Default</footer>
                                                            </div>
                                                            {value.split('_')[0] === 'CN' &&
                                                                <>
                                                                    <span className="badge badge-secondary">
                                                                        {blockList[value].itemCount}
                                                                    </span>
                                                                </>
                                                            }
                                                        </div>
                                                    </a>
                                                </li>
                                            })}
                                        </ul>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            {/* <div className="fab-button text bottom-center" style={{left: '60%'}}>
                <a href="#" className="fab" onClick={handleCategoryOffcanvas}>
                    <ion-icon name="add-outline"></ion-icon>
                    Add Category
                </a>
            </div> */}
        </div>
    )
}

const mapStateToProps = () => {
    return {
        
    }
}
  
const mapDispatchToProps = {
    showCategoryOffcanvas
}

export default connect(mapStateToProps,mapDispatchToProps)(Categories)