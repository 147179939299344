import React, { useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import axios from '../../../AxiosConfig'
import { useLocation } from "react-router-dom"
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { setLoading, setWebsiteData } from '../../../features/websiteData/websiteDataSlice'
import { hideClonePageModal, setADDClonePageProps } from '../../../features/modal/offcanvasSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'

const ClonePage = ({
    isClonePageModalVisible,
    hideClonePageModal,
    ADDClonePageProps,
    setADDClonePageProps,
    showAppToast,
    setWebsiteData,
    websiteDataAppearnace,
    setLoading
}) => {
    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain;

    const search = useLocation().search
    let url = ''
    url = new URLSearchParams(search).get('url')
    if (url === null) {
        url = localStorage.getItem('previousPage')
    } else {
        url = new URLSearchParams(search).get('url')
        localStorage.setItem('previousPage', url)
    }

    const [pageProps, setPageProps] = useState(null);
    const [SinglePageName, setSinglePageName] = useState('');
    const [MultiplePageNames, setMultiplePageNames] = useState([]);
    const [PageCount, setPageCount] = useState(0);
    const [disableBtn, setdisableBtn] = useState(true);

    const closeThisModal = () => {
        hideClonePageModal()
        setSinglePageName('');
        setdisableBtn(false);
        setADDClonePageProps(null);
        setMultiplePageNames([]);
        setPageCount(0);
    }

    const handleAddPage = () => {
        setdisableBtn(true)
        axios.post(`/page/${selectedDomain}/${SinglePageName}`).then(res => {

            if (res.data.message === 'Success') {
                setWebsiteData(res.data.data)
                showAppToast({ toastMessage: 'NEW PAGE ADDED', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                closeThisModal();
            } else {
                showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                closeThisModal();
            }
        })
    }

    const handleCloneAddPage = () => {
        setdisableBtn(true);
        setLoading(true);
        let pageId = ''
        Object.keys(websiteDataAppearnace.pages).map((value, key) => {
            if (websiteDataAppearnace.pages[value].url === url) {
                pageId = value
            }
        })
        if(ADDClonePageProps !== null && ADDClonePageProps.pageID !== undefined) {
            pageId = ADDClonePageProps.pageID
        }
        const pageNames = MultiplePageNames.map((item) => {
            return { 'PageName': item.PageName } 
        })
        if(pageId !== '') {
            axios.put(`/page/clonePage/${selectedDomain}/${pageId}`, pageNames).then(res => {
                if (res.data.message === 'Success') {
                    setWebsiteData(res.data.data)
                    showAppToast({ toastMessage: 'PAGES CLONED', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                    closeThisModal();
                } else {
                    showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                    closeThisModal();
                }
                setLoading(false);
            }).catch(error => {
                showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                closeThisModal();
                setLoading(false);
            })
        }
    }

    useEffect(() => {
        if (SinglePageName.trim().length < 4) {
            setdisableBtn(true);
        } else {
            setdisableBtn(false);
        }
    }, [SinglePageName])


    useEffect(() => {
        if (ADDClonePageProps !== null) {
            setPageProps(ADDClonePageProps);
            if (ADDClonePageProps.CloneOrAdd === 'Clone') {
                if (ADDClonePageProps.Copies === 'Multiple') {
                    setPageCount(3);
                    setdisableBtn(true);
                } else {
                    setPageCount(1);
                    setdisableBtn(true);
                }
            }
        } else {
            setPageProps(null);
        }
    }, [ADDClonePageProps]);

    useEffect(() => {
        if (MultiplePageNames.length === 0) {
            const defaultArray = [...Array(PageCount)].map((_, i) => {
                return { "PageName": ``, "NameLength": 0 }
            })
            setMultiplePageNames(defaultArray);
        } else {
            if (PageCount > MultiplePageNames.length) {
                const defaultArray = [...Array(PageCount)].map((_, i) => {
                    return MultiplePageNames[i] !== undefined ? MultiplePageNames[i] : { "PageName": ``, "NameLength": 0 }
                })
                setMultiplePageNames(defaultArray);
            } else {
                const defaultArray = MultiplePageNames.slice(0, PageCount);
                setMultiplePageNames(defaultArray);
            }
        }
    }, [PageCount])

    const changePageName = (e, position) => {
        // console.log({ e, position });
        const NameArray = JSON.parse(JSON.stringify(MultiplePageNames));
        NameArray[position].PageName = e.target.value;
        NameArray[position].NameLength = e.target.value.length;
        setMultiplePageNames(NameArray);
    }

    useEffect(() => {
        if(MultiplePageNames.length > 0) {
            const lessLength = MultiplePageNames.filter((item) => item.NameLength < 2);
            if(lessLength.length === 0) {
                setdisableBtn(false);
            } else {
                setdisableBtn(true);
            }
        }
    }, [MultiplePageNames])
    

    return (
        <Offcanvas show={isClonePageModalVisible} className={`${PageCount > 8 ? 'offcanvas-bottom-height' : 'offcanvas-bottom-height-mob'}`} placement={'bottom'} backdrop={true} scroll={true}>
            <div className="row p-1" style={{ boxShadow: '0 3px 6px 0 rgb(0 0 0 / 10%), 0 1px 3px 0 rgb(0 0 0 / 8%)', height: '75px' }}>
                <div className="col-4 col-md-6 d-flex align-items-center">
                    {
                        pageProps !== null &&
                        <strong className="text-muted fs-6">{pageProps.CloneOrAdd === 'Clone' ? 'Duplicate Page' : 'Add Page'}</strong>
                    }
                </div>
                <div className="col-8 col-md-6 d-flex align-items-center justify-content-end">
                    
                    {
                        pageProps !== null &&
                        <>
                            {pageProps.CloneOrAdd === 'Clone' ?
                                <>
                                    <div className="form-group basic p-0 border-primary rounded " style={{'maxWidth': '200px'}}>
                                        <div className="input-wrapper d-flex align-items-center ps-2">
                                            <label className="form-label mb-0 w-auto" htmlFor="pageCount"><h5 className='mb-0'>How Many Pages?</h5></label>
                                            <select className="form-control form-select p-0 ms-1" style={{width: '25%', minWidth: '65px'}} id="pageCount" value={PageCount} onChange={(e) => setPageCount(Number(e.target.value))}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>
                                    </div>
                                    <button className="btn btn-sm btn-success me-2 ms-2" disabled={disableBtn} onClick={() => handleCloneAddPage()}>Clone</button>
                                </>
                                :
                                <button className="btn btn-sm btn-success me-2" disabled={disableBtn} onClick={() => handleAddPage()}>Add</button>
                            }
                        </>
                    }
                    <span
                        className="text-muted fs-5"
                        style={{ cursor: 'pointer', width: '50px' }}
                        onClick={() => closeThisModal()}
                    >
                        <ion-icon name="close"></ion-icon>
                    </span>
                </div>
            </div>
            <Offcanvas.Body>
                <div className="section full mb-2">
                    <div className="section full mt-1">
                        {
                            pageProps !== null &&
                            <>
                                {pageProps.Copies === 'Single' && pageProps.CloneOrAdd === 'Add' &&
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="pageName">Page Name</label>
                                            <input type="text" className="form-control form-control-sm" id="pageName" value={SinglePageName} onChange={(e) => setSinglePageName(e.target.value)} placeholder="Enter page name"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                }
                            </>
                        }

                        {
                            pageProps !== null &&
                            <>
                                {pageProps.CloneOrAdd === 'Clone' &&
                                    <>
                                        <div className='row'>
                                            {MultiplePageNames.length !== 0 &&
                                                MultiplePageNames.map((item, i) => {
                                                    return (
                                                        <div className='col-md-3' key={i}>
                                                            <div className="form-group basic">
                                                                <div className="input-wrapper">
                                                                    <label className="form-label" htmlFor={`PageTitle${i}`}>Page Name {i+1}</label>
                                                                    <input type="text" className="form-control form-control-sm" id={`PageTitle${i}`} value={item.PageName} onChange={(e) => changePageName(e, i)} placeholder={`Enter page name`}
                                                                        autoComplete="off" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                }
                            </>
                        }

                        {/* <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="form-label" htmlFor="pageUrl">
                                    <div className="row">
                                        <div className="col-6 col-md-6 d-flex align-items-center">
                                            Page Url
                                        </div>
                                        <div className="col-6 col-md-6 text-end">
                                            <span className="text-danger d-flex align-items-center justify-content-end">
                                                <ion-icon name="close-circle-outline" className="fs-5"></ion-icon>
                                                Url Not Available
                                            </span>
                                        </div>
                                    </div>
                                </label>
                                <h4 className="mb-0 mt-2 text-secondary">https://{selectedDomain}/</h4>
                                <input type="text" className="form-control form-control-sm" id="pageUrl" placeholder="Enter page url"
                                    autoComplete="off"/>
                                <i className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div> */}

                        {/* <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="form-label" htmlFor="cloneTo">Clone To</label>
                                <select className="form-control form-select" id="cloneTo">
                                    <option value="0">Home</option>
                                    <option value="1">About</option>
                                    <option value="2">Service</option>
                                    <option value="3">Contact</option>
                                </select>
                            </div>
                        </div> */}
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        isClonePageModalVisible,
        ADDClonePageProps
    } = offcanvas
    const {
        appearance: websiteDataAppearnace
    } = websiteData
    return {
        isClonePageModalVisible,
        ADDClonePageProps,
        websiteDataAppearnace
    }
}

const mapDispatchToProps = {
    hideClonePageModal,
    setADDClonePageProps,
    showAppToast,
    setWebsiteData,
    setLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(ClonePage)