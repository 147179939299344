import { configureStore } from '@reduxjs/toolkit'
import appToastReducer from '../features/utilities/appToastSlice'
import utilitiesReducer from '../features/utilities/utilitiesSlice'
import manageBlockReducer from '../features/manage/manageBlockSlice'
import offcanvasReducer from '../features/modal/offcanvasSlice'
import toolbarReducer from '../features/manage/toolbarSlice'
import websiteDataReducer from '../features/websiteData/websiteDataSlice'
import authReducer from '../features/auth/authSlice'
import shareReducer from '../features/share/shareSlice'
import updateDataReducer from '../features/updateData/updateDataSlice'
import LinkModalsReducer from '../features/tapsModals/LinksModalSlice'
import FileUploadReducer from '../features/modal/fileUploadSlice'

export const store = configureStore({
  reducer: {
    appToast: appToastReducer,
    auth: authReducer,
    websiteData: websiteDataReducer,
    utilities: utilitiesReducer,
    manageBlock: manageBlockReducer,
    offcanvas: offcanvasReducer,
    toolbar: toolbarReducer,
    share: shareReducer,
    updateData: updateDataReducer,
    TapsModals: LinkModalsReducer,
    FileUploadModals: FileUploadReducer
  },
});