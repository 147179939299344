import React from 'react'
import { connect } from 'react-redux'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'
import { showOffcanvas, showDeleteModal } from '../../features/modal/offcanvasSlice'

const YoutubeVideo = ({
    properties,
    items,
    blockType,
    blockID,
    showOffcanvas,
    showDeleteModal
}) => {
    const handleBlockUpdate = (msg) => {
        console.log('CLICKED')
        showOffcanvas({ isVisible: true, items:items, itemCount:'null', properties:properties, blockType: blockType, blockID:blockID, msg:msg})
    }

    const handleDeleteBlock = (blockName) => {
        showDeleteModal({isDeleteVisible:true, deleteMessage:'Are your sure? You want to delete this block?', triggerMessage:'block', deleteID: blockName})
    }
    return (
        <div>
            <div className="card position-relative">
                <div className="card-body">
                    {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                        <div className="row">
                            {properties.blockHeading !== '' &&
                                <div className="col-12">
                                    <h3 className="text-center" style={cssConverter(properties.blockHeadingCss)} dangerouslySetInnerHTML={{ __html: properties.blockHeading }} />
                                </div>
                            }
                            {properties.blockDescription !== '' &&
                                <div className="col-12">
                                <p className="text-center" style={cssConverter(properties.blockDescriptionCss)} dangerouslySetInnerHTML={{ __html: properties.blockDescription }} />
                                </div>
                            }
                        </div>
                    }
                    <p className="card-text">
                        <iframe width="100%" height="300" frameborder="0" allowFullScreen="" aria-hidden="false" tabindex="0" src={'https://www.youtube.com/embed/'+ items[0].title}></iframe>    
                    </p>
                </div>
                {/* <div className='d-flex pos-absglr'>
                    <div className="col-6 col-md-6 text-end">
                        <span className="badge bg-secondary p-1 cursor-pointer w-55px" onClick={() => {handleBlockUpdate()}}>Edit</span>
                    </div>
                    <div className="col-6 col-md-6">
                        <span className="badge bg-danger p-1 cursor-pointer w-55px" onClick={() => {handleDeleteBlock(blockID)}}>Delete</span>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}
  
const mapDispatchToProps = {
    showOffcanvas,
    showDeleteModal
}

export default connect(mapStateToProps,mapDispatchToProps)(YoutubeVideo)
