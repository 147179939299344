import React from 'react'
import { connect } from 'react-redux'
import { showConfirmationModal } from '../../features/modal/offcanvasSlice'

const DNS = ({
    showConfirmationModal
}) => {
    const handleConfirmationModal = () => {
        showConfirmationModal({isConfirmationVisibleModal: true, confirmationMessage: 'Are you sure? You want to change DNS setting?'})
    }
    return (
        <div id="appCapsule">
            <div className="section full mt-1">
                <div className="border-0 ps-2 pe-2">
                    <form className="search-form">
                        <div className="input-group form-group boxed p-0">
                            <input type="text" className="form-control" placeholder="Search Image" aria-label="Recipient's username" aria-describedby="button-addon2" value="query" style={{height: '34px'}} />
                            <button className="btn h-custom btn-secondary" type="submit" id="button-addon2" style={{height:'auto !important'}}>
                                <ion-icon name="search-sharp" className="fs-6"></ion-icon>
                            </button>
                        </div>
                    </form>
                </div>
                <ul className="listview image-listview mt-1">
                    <li className="custom-line">
                        <a href="javascript:void(0)" className="item" onClick={handleConfirmationModal}>
                            <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    <header>Digital Ocean</header>
                                    kaizenlenguistic.com
                                    <footer><span className="badge badge-secondary" style={{cursor: 'pointer'}}>Ax2</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>NS</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>TXT</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>CNAME</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>MX</span></footer>
                                </div>
                                <span className="text-muted">Manage</span>
                            </div>
                        </a>
                    </li>
                    <li className="custom-line">
                        <a href="javascript:void(0)" className="item" onClick={handleConfirmationModal}>
                            <img src="assets/img/sample/avatar/avatar7.jpg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    <header>Digital Ocean</header>
                                    mgmhospitalcbd.com
                                    <footer><span className="badge badge-secondary" style={{cursor: 'pointer'}}>Ax2</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>NS</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>TXT</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>CNAME</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>MX</span></footer>
                                </div>
                                <span className="text-muted">Manage</span>
                            </div>
                        </a>
                    </li>
                    <li className="custom-line">
                        <a href="javascript:void(0)" className="item" onClick={handleConfirmationModal}>
                            <img src="assets/img/sample/avatar/avatar5.jpg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    <header>Reseller Club</header>
                                    theenglishschoolpanvel.com
                                    <footer><span className="badge badge-secondary" style={{cursor: 'pointer'}}>Ax2</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>NS</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>TXT</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>CNAME</span> <span className="badge badge-secondary" style={{cursor: 'pointer'}}>MX</span></footer>
                                </div>
                                <span className="text-muted">Manage</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {
    }
  }
  
  const mapDispatchToProps = {
    showConfirmationModal
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(DNS)