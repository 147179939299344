import React from 'react'

function SM_TWTO_DFL1(props) {
    return (
        <div>
            Smart Tawk.to Iframe
        </div>
    )
}

export default SM_TWTO_DFL1
