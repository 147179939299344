import React, { useState, useEffect } from 'react';
import axios from '../../AxiosConfig'
import jwt_decode from 'jwt-decode'

function Click(props) {

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const website_ID = tokenData.SelectedDomain.Website_ID

    const today = new Date()
    const numberOfDaysToMinus = 30
    const fdate = today.setDate(today.getDate() - numberOfDaysToMinus);
    const defaultFromDate = new Date(fdate).toISOString().split('T')[0] // yyyy-mm-dd

    const todayDate = new Date();
    const tdate = todayDate.setDate(todayDate.getDate());
    const defaultToDate  = new Date(tdate).toISOString().split('T')[0] // yyyy-mm-dd

    let [fromDate, setFromDate] = useState(defaultFromDate)
    let [toDate, setToDate] = useState(defaultToDate)
    const [callClick, setCallClick] = useState(0)
    const [emailClick, setEmailClick] = useState(0)

    const getData = () => {
        let referral = []
        let count = []
        axios.get('/analytics/clicksReport/'+ website_ID +'/'+ fromDate +'/' + toDate).then(res=>{

            setCallClick(res.data.data.callClicks)
            setEmailClick(res.data.data.emailClicks)
        })
    }

    const handleFromDateChange = ev => setFromDate(ev.target.value);
    const handleToDateChange = ev => setToDate(ev.target.value);

    useEffect(() => {
        getData()
    }, [fromDate, toDate])
    return (
        <div className="section mt-2 card-body">
            <div className="row">
                <div className="col-6 col-md-6">
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="name5">From</label>
                            <input type="date" className="form-control" id="name5" autoComplete="off" defaultValue={fromDate} onChange={handleFromDateChange} />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-6">
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="name5">To</label>
                            <input type="date" className="form-control" id="name5" autoComplete="off" defaultValue={toDate} onChange={handleToDateChange} />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <div className="row">
                    <div className="col-12 col-md-6 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-subtitle">Call Click</h5>
                                <div className="row">
                                    <div className="col-12 card-title">
                                        {callClick}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-subtitle">Email Click</h5>
                                <div className="row">
                                    <div className="col-12 card-title">
                                        {emailClick}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Click
