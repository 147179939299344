import React, { useState, useEffect } from 'react'
import axios from '../../AxiosConfig'
import { Chart as ChartJS, ArcElement, Tooltip, Legend , CategoryScale, LinearScale, BarElement, PointElement, LineElement} from 'chart.js'
import { Line, Bar } from 'react-chartjs-2'
import jwt_decode from 'jwt-decode'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement)

function Hits(props) {
  const token = localStorage.getItem('token')
  const tokenData = token ? jwt_decode(token) : false
  const website_ID = tokenData.SelectedDomain.Website_ID

  const today = new Date()
  const numberOfDaysToMinus = 30
  const fdate = today.setDate(today.getDate() - numberOfDaysToMinus)
  const defaultFromDate = new Date(fdate).toISOString().split('T')[0] // yyyy-mm-dd

  const todayDate = new Date()
  const tdate = todayDate.setDate(todayDate.getDate())
  const defaultToDate = new Date(tdate).toISOString().split('T')[0] // yyyy-mm-dd

  let [fromDate, setFromDate] = useState(defaultFromDate)
  let [toDate, setToDate] = useState(defaultToDate)
  const [totalVisits, setTotalVisits] = useState(0)
  const [totalUniqueVisits, setTotalUniqueVisits] = useState(0)
  const [hourlyVisitors, setHourlyVisitors] = useState(null)
  const [weeklyVisitors, setWeeklyVisitors] = useState(null)
  const [monthlyVisitors, setMonthlyVisitors] = useState(null)
  const [totalVisitors, setTotalVisitors] = useState(null)
  const [callClicks, setCallClicks] = useState(0)
  const [emailClicks, setEmailClicks] = useState(0)

  const getData = () => {
    let hours = []
    let hoursCount = []
    axios
      .get(
        '/analytics/hourlyHitsReport/' +
          website_ID +
          '/' +
          fromDate +
          '/' +
          toDate
      )
      .then(res => {
        Object.keys(res.data.data.hits).forEach(function(key) {
          hours.push(key)
          hoursCount.push(parseInt(res.data.data.hits[key]))
        })

        setHourlyVisitors({
          labels: hours,
          datasets: [
            {
              label: 'Hits',
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: 'rgba(0,0,0,1)',
              borderWidth: 2,
              data: hoursCount
            }
          ]
        })
      })

    axios
      .get(
        '/analytics/clicksReport/' +
          website_ID +
          '/' +
          fromDate +
          '/' +
          toDate
      )
      .then(res => {
        setCallClicks(res.data.data.callClicks)
        setEmailClicks(res.data.data.emailClicks)
      })

    let weeks = []
    let weeksCount = []
    axios
      .get(
        '/analytics/weeklyHitsReport/' +
          website_ID +
          '/' +
          fromDate +
          '/' +
          toDate
      )
      .then(res => {
        Object.keys(res.data.data.hits).forEach(function(key) {
          weeks.push(key)
          weeksCount.push(parseInt(res.data.data.hits[key]))
        })

        setWeeklyVisitors({
          labels: weeks,
          datasets: [
            {
              label: 'Hits',
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: 'rgba(0,0,0,1)',
              borderWidth: 2,
              data: weeksCount
            }
          ]
        })
      })

    let monthDays = []
    let monthDayCount = []
    axios
      .get(
        '/analytics/monthlyHitsReport/' +
          website_ID +
          '/' +
          fromDate +
          '/' +
          toDate
      )
      .then(res => {
        Object.keys(res.data.data.hits).forEach(function(key) {
          monthDays.push(key)
          monthDayCount.push(parseInt(res.data.data.hits[key]))
        })

        setMonthlyVisitors({
          labels: monthDays,
          datasets: [
            {
              label: 'Hits',
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: 'rgba(0,0,0,1)',
              borderWidth: 2,
              data: monthDayCount
            }
          ]
        })
      })

    let visitorsDate = []
    let visitorsCount = []
    axios
      .get(
        '/analytics/visitorsReport/' +
          website_ID +
          '/' +
          fromDate +
          '/' +
          toDate
      )
      .then(res => {
        if (res.data.data.graphReport) {
          console.log("if")
          if(res.data.data.graphReport === false) {
            setTotalVisits(0)
            setTotalUniqueVisits(0)
          } else {
            setTotalVisits(res.data.data.graphReport.total)
            setTotalUniqueVisits(res.data.data.uniqueVisits.total)
            Object.keys(res.data.data.graphReport.dates).forEach(function(key) {
              visitorsDate.push(key)
              visitorsCount.push(parseInt(res.data.data.graphReport.dates[key]))
            })

            setTotalVisitors({
              labels: visitorsDate,
              datasets: [
                {
                  label: 'Visitors',
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: 'rgba(75,192,192,1)',
                  borderColor: 'rgba(0,0,0,1)',
                  borderWidth: 2,
                  data: visitorsCount
                }
              ]
            })
          }
        } else {
          setTotalVisits(0)
          setTotalUniqueVisits(0)
        }
      })
  }

  const handleFromDateChange = ev => setFromDate(ev.target.value)
  const handleToDateChange = ev => setToDate(ev.target.value)

  useEffect(() => {
    getData()
  }, [fromDate, toDate])

  return (
    <>
      <div className="section mt-2">
        <div className="row">
          <div className="col-6 col-md-6">
            <div className="form-group boxed">
              <div className="input-wrapper">
                <label className="form-label" htmlFor="name5">
                  From
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="name5"
                  autoComplete="off"
                  defaultValue={fromDate}
                  onChange={handleFromDateChange}
                />
                <i className="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-6">
            <div className="form-group boxed">
              <div className="input-wrapper">
                <label className="form-label" htmlFor="name5">
                  To
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="name5"
                  autoComplete="off"
                  defaultValue={toDate}
                  onChange={handleToDateChange}
                />
                <i className="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section mt-2">
        <div className="row mb-2">
          <div className="col-6 col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-subtitle">Total/Unique Visits</h5>
                <h2>
                  {totalVisits}/{totalUniqueVisits}
                </h2>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-subtitle">Email/Call Click</h5>
                <h2>{emailClicks}/{callClicks}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12 mb-2">
            <div className="card">
              <div className="card-body">
                <h5 className="card-subtitle">Visitors</h5>
                {totalVisitors && totalVisits > 0 && 
                  <Line data={totalVisitors} />
                }
                {totalVisits === 0 &&
                  <div className="text-center text-danger">No Data Found</div>

                }
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 mb-2">
            <div className="card">
              <div className="card-body">
                <h5 className="card-subtitle">Hourly Visits</h5>
                {hourlyVisitors &&
                  <Bar data={hourlyVisitors} />
                }
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 mb-2">
            <div className="card">
              <div className="card-body">
                <h5 className="card-subtitle">Weekly Visits</h5>
                {weeklyVisitors &&
                  <Bar data={weeklyVisitors} />
                }
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 mb-2">
            <div className="card">
              <div className="card-body">
                <h5 className="card-subtitle">Monthly Visits</h5>
                {monthlyVisitors && 
                  <Bar data={monthlyVisitors} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hits
